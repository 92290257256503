import React, { useState, useEffect, useRef} from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"
import useIsClient from "../../hooks/isClient"
import { useModal } from "../../hooks/modal"
import getUserAgent from "../../utils/get-user-agent"
import trackEvent from "../../utils/track-event"

import ActiveModal from "../modals/ActiveModal"
import CloseIcon from "../icons/Close"

import MeoButtonImage from "./MeoButtonImage"
import MeoDialogue from "./MeoDialogue"
import MeoSpeechBubble from "./MeoSpeechBubble"
import MeoControlsFurnishing from "./MeoControlsFurnishing"
import MeoControlsPersons from "./MeoControlsPersons"
import MeoSelectFurnishing from "./MeoSelectFurnishing"
import MeoSelectPerson from "./MeoSelectPerson"
import AnalogClock from "./AnalogClock"

import MeoItemContainer from "./MeoItemContainer"
import MeoXray from "./MeoXray"
import MeoOperationsbord from "./MeoOperationsbord"

import Tryggwebb from "../Tryggwebb"

const meopink = "rgb(207, 60, 134)"

const roomwidth = {
  default: "300vh",
  defaultMax: "2800px",
  portrait : "250vh",
  portraitMin : "300vw",
}

const Wrapper = styled.div`
`
const Room = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  width: 100vw;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  z-index: 11;
  perspective: 50vw;
  perspective-origin: top;
  transform-style: preserve-3d;

  /* not working on iOS Safari */
  /* @supports not (-webkit-touch-callout: none) {
    perspective: 50vw;
    perspective-origin: top;
    transform-style: preserve-3d;
  } */
`
const Stage = styled.div`
  position: absolute;
  width: ${roomwidth.default};
  max-width: ${roomwidth.defaultMax};
 
  @media (orientation: portrait) {
    width: ${roomwidth.portrait};
    min-width: ${roomwidth.portraitMin};
  }
  height: 100%;  
  z-index: 0;
  transform-style: preserve-3d;

  /* enable on iOS Safari - disable for others */
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    perspective-origin: top;
    padding-bottom: 3rem;

    @media (orientation: portrait) {
      max-height: 95vh;
    }
  }

  @-moz-document url-prefix() {
    & {
      transform: translateZ(-20vw);
    }
  }
  
  &#stage-figures {
    transform: translateZ(-20vw);
  }
`

const Spacer = styled.div`
  position: relative;
  z-index: 50;
  width: 180vw;
  overflow: visible;
  perspective: 50vw;
`

const Wall = styled.div`
  position: absolute;
  text-align: center;
  color: white;
  height: 100vh;
  width: 20vw;
  top: 0;
  z-index: 10;
  background: url(${props => props.bg ? require(`../../images/meo/walls/MEO_${props.bg}`) : null});
  background-size: 100vh 130vh;
  background-position: left;
  background-repeat: no-repeat;
  backface-visibility: visible;

  @supports (-webkit-touch-callout: none) {
    background-size: 200% 130vh;
  }
`

const WallLeft = styled(Wall)`
  background-color: rgba(125,125,125,1);
  filter: brightness(0.4);
  /* transform: rotateY(90deg) translateZ(-10vw); */
  /* for Chrome */
  transform: rotateY(90deg);
  /* transform: rotateY(-90deg); */
  transform-origin: center left;
  left: 0;
  background-position: right;

  @-moz-document url-prefix() {
    & {
      transform: rotateY(-90deg);
    }
  }
`

const WallRightWrap = styled.div`
  transform-style: preserve-3d;
  overflow: visible;
  position: absolute;
  right: 0;
`

const WallRight = styled(Wall)`
  filter: brightness(1);
  /* for iOS */
  transform: rotateY(-90deg);
  /* for Chrome and other desktop */
  // transform: rotateY(90deg);
  transform-origin: center right;
  /* transform: translateZ(-10vw) rotateY(-90deg); */
  right: 0;
  left: initial;
  background-position: center;

  @-moz-document url-prefix() {
    & {
      transform: rotateY(90deg);
    }
  }
`

const WallBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: translateZ(-20vw);
  background: url(${props => props.bg ? require(`../../images/meo/walls/MEO_${props.bg}`) : null});
  /* background-size: cover; */
  background-position: left;

  @-moz-document url-prefix() {
    & {
      transform: translateZ(0);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(90deg, rgba(0,0,0,0.3), transparent 15%, transparent 85%, rgba(0,0,0,0.3));
  }
`

const Floor = styled.div`
  width: 100%;
  height: 20vw;
  position: absolute;
  background: #ccc url(${props => props.bg ? require(`../../images/meo/walls/MEO_${props.bg}`) : null});
  background-size: 100% 100%;
  /* transform: rotateX(90deg) translateZ(-10vw); */
  transform-origin: bottom;
  /* Safari not displaying any background with 90deg */
  -webkit-transform: rotateX(89.999deg);
  transform: rotateX(89.999deg);
  z-index: 2;
  bottom: 0;

  @-moz-document url-prefix() {
    & {
      transform: rotateX(-90deg);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.3), transparent 45%);
  }
`

const Controls = styled.nav`
    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    // background: #afa;
    opacity: 1;
    // z-index: 30;
`

const ControlsFurnishing = styled.div`
`
const ControlsPersons = styled.div`
`

const ClockWrapper = styled.div`
    position: absolute;
    left: 24%;
    top: 19%;
    width: 20vh;
    height: 20vh;
`

const Figures = styled.div`
  transform: translateZ(0);
  @-moz-document url-prefix() {
    & {
      transform: translateZ(0);
    }
  }

  position: absolute;
  width: ${roomwidth.default};
  max-width: ${roomwidth.defaultMax};
  @media (orientation: portrait) {
    width: ${roomwidth.portrait};
    min-width: ${roomwidth.portraitMin};
  }
  height: 100%;
  z-index: 5;

  transform-style: preserve-3d;

  .back {transform: translateZ(-18vw);}
  .middle {transform: translateZ(-10vw);}

  div.clickable, img.clickable,
  div[data-clickable], img[data-clickable] {cursor: pointer;}
  div:hover, img:hover {
  }

`
const Placeholder = styled.div`
  min-height: 250px;
  width: 300px;
  background: rgba(0,0,150,0.2);
  margin: 2rem;
  margin-left: ${props => props.offset};
`

const Figure = styled.img`
  position: absolute;
  bottom: 0;
  max-height: 80vh;
  overflow: visible;
  transition: transform .3s ease-in-out;

  &#lamp {top: 0; left: 50%; transform: translateZ(18vw); max-height: 30vh; max-width: 40vh; z-index: 11;}
  &#chair{height: 40vh; max-height: 45vh; min-height: 40vh; left:41%; transform: translateZ(17vw) translateX(-50%); z-index: 9;}
  &#chair.Stol_5 {transform: translateZ(17vw) translateX(-63%);}
`

const StyledXray = styled(MeoXray)`
  position: absolute;
  bottom: 0;
  width: 15%;
  min-width: 250px;
  transform: translateZ(19vw);
  cursor: pointer;
  height: 50vh;
`

const Dialogue = styled(MeoDialogue)`
  position: relative;
  width: 80vw;
  max-width: 500px;
  /* max-height: 75vh; */
  margin: 2rem auto;

  & > div.inner {
    max-height: 75vh;
    position: relative;
    background: white;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
    padding: 0.4rem 1rem 1rem 1.2rem;
    overflow: scroll;
  }

`

const StyledSpeechBubble = styled(MeoSpeechBubble)`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y * 0.7}px;
  @media (max-width: 550px), (max-height: 550px) {
    top: 6rem;
  }

  @supports not (-webkit-touch-callout: none) {
    /* left: ${props => props.x}px;
    top: ${props => props.y * 0.7}px;
    transform: translateX(${props => props.translations.dz}px); */
    /* transform: translateX(${props => props.translations.dz}px) translateZ(calc(${props => props.z}px - 20vw)) scale(${props => props.scale}); */
  }
`

const ButtonClose = styled.button`
  position: absolute;
  top: -2rem;
  right: 0rem;
  background: none;
  border: none;
  color: white;
  filter: drop-shadow(0px 0px 2px black);
`

const ButtonRound = styled.button`
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 1rem auto;
  background: none;
  color: ${meopink};
  border: 2px solid ${meopink};
  border-radius: 1rem;
  padding: 0.4rem 1rem;
  opacity: 1;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.3s ease-out,
    color .3s ease-in-out,
    background-color .3s ease-in-out;

  :hover, :focus {
    color: white;
    background-color: ${meopink};
  }
`

export default function MeoRoom({ lang, dialogues, welcome, bioData, itemData, ...props }) {
  const intl = useIntl()
  const ua = getUserAgent()
  const { key } = useIsClient()
  const { openModal, closeModal } = useModal()

  const [roomConfig, setRoomConfig] = useState({})
  const [activeItem, setActiveItem] = useState(null)
  const [activeItemHighlight, setActiveItemHighlight] = useState(null)
  const [activeToolbox, setActiveToolbox] = useState(false)
  const [activeBubble, setActiveBubble] = useState(false)

  const [store, updateStore] = useStore()

  const room = useRef(null)
  const figureStage = useRef(null)

  /* Run onboarding once */
  useEffect(() => {
    onboarding()
  }, [])

  const getDialogue = (el) => {
    const pers = dialogues.find(({ _id }) => _id === el)
    const txts = pers.dialogue

    let dialogueArray = new Array()
    if (pers && Array.isArray(txts)) {
      dialogueArray = txts.map((i) => {
        return [i.textblock.html, { f: i.sound_f, m: i.sound_m }]
      })
    }
    return dialogueArray.length ? dialogueArray : null
  }

  const getHeadline = (el) => {
    const pers = dialogues.find(({ _id }) => _id === el)
    return pers.headline
  }

  const handleItemClick = (itm, e) => {
      setActiveToolbox(null)
      setActiveBubble(null)
      let i
      switch (itm) {
        case "xray" :
          i = <MeoXray lang={lang} disabled={false} closeFn={closeFn} />
          break
        case "anamachine" :
          i = (
            <MeoItemContainer lang={lang} itemset={itm} data={itemData}>
              <h2>{intl.formatMessage({ id: "meo_anaesthesiaMachine" })}</h2>
            </MeoItemContainer>
          )
          break
        case "anatable" :
        case "surgeontable" :
          i = <MeoItemContainer lang={lang} itemset={itm} data={itemData} />
          break
        default :
          break
      }

      setActiveItem(itm)
      openModal(e, "meoItem", { closeFn, children: i })
  }

  const handleConfigClick = (list, e) => {
      let i = <MeoSelectFurnishing list={list} closeFn={closeFn} />
      setActiveItem(list)
      openModal(e, "meoItem", { closeFn, children: i })
  }

  const handlePersonConfigClick = (list, e) => {
      const selectperson = "select_" + list
      const headline = getHeadline(selectperson)
      let i = (
        <MeoSelectPerson lang={lang} closeFn={closeBgFn} list={list} bioData={bioData}>
          <h2>{headline}</h2>
        </MeoSelectPerson>
      )
      setActiveItem(list)
      openModal(e, "meoItem", { closeFn, flexBasis: "100%", children: i })
  }

  const closeBgFn = (e) => {
    if(e.currentTarget === e.target) {
      return closeFn()
    }

    if(e.target.closest("[data-children-close-wrapper]")) {
      return closeFn()
    }

    if(e.target.dataset.closesWrapper) {
      return closeFn()
    }
  }

  const closeFn = () => {
    closeModal()
    setActiveBubble(null)
  }

  const closeNoActiveFn = () => {
    closeModal()
    setActiveBubble(null)
    setActiveItem(null)
  }

  const defaultCloseButton = <ButtonClose onClick={ closeFn } aria-label={intl.formatMessage({ id: "close" })}><CloseIcon fill="#fff" /></ButtonClose>
  const resetActiveCloseButton = <ButtonClose onClick={ closeNoActiveFn } aria-label={intl.formatMessage({ id: "close" })}><CloseIcon fill="#fff" /></ButtonClose>


  const handlePersonClick = (person, e) => {

        if (person === activeItem && activeBubble) {
          /* if activeItem is the person being clicked */
          closeNoActiveFn()
        } else if (store.meo.persons[person].id === 0) {
          /* if silhouette is showing and no person selected yet */
          setActiveItem(person)
          setActiveToolbox("person")
          const selectperson = "select_" + person
          const txt = getDialogue(selectperson)

          const confirm = <ButtonRound onClick={(e) => {handlePersonConfigClick(person, e)}}>{intl.formatMessage({ id: "selectNow" })}</ButtonRound>
          const dialogue = <Dialogue dialogue={txt} btnClose={resetActiveCloseButton}>{confirm}</Dialogue>

          openModal(e, "meoItem", { closeFn: closeNoActiveFn, customCloseButton: true, bgBlur: "0", children: dialogue })
        } else {
          setActiveItem(person)
          let clickedItem = e.currentTarget || e.target

          /* use classic offsetWidth */
          let clickedItemSize = {
            width: clickedItem.offsetWidth,
            height: clickedItem.offsetHeight,
          }

          let parentBoundaries = clickedItem.offsetParent.getBoundingClientRect()
          /*
          let scrollPos = {
            x: room.current.scrollLeft,
            y: room.current.scrollTop,
          }
          */
          let offsetPos = {
            x: clickedItem.offsetLeft,
            y: clickedItem.offsetTop,
          }


          /* To do: Move to helper function? */
          const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
          const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

          let translated3dmatrix = window.getComputedStyle(clickedItem).getPropertyValue('transform').match(/matrix.*\((.+)\)/)[1].split(', ')
          let translated3d = {
            x: translated3dmatrix[12],
            y: translated3dmatrix[13],
            z: translated3dmatrix[14],
          }

          /* position around 2/3 of person width - with exception for child on table */
          // const bubbleToFaceOffset = person == "child" ? clickedItemSize.width * 0.9 : clickedItemSize.width * 0.9
          const bubbleToFaceOffset = clickedItemSize.width * 0.9

          let xPos = Math.round(offsetPos.x + bubbleToFaceOffset + parseFloat(translated3d.x))
          let yPos = Math.round(offsetPos.y)

          /* compensate font for z-translation - max-translation to front is 20vw */
          /* scaling factor = -(1 / perspective) * translate in pix + 1 */
          /* 0.5 = perspective on room in vw --> 50vw */
          let maxZ = vw * 0.2
          let realZ = (maxZ - translated3d.z)
          translated3d.dz = realZ
          let dist = 1 / (-(1 / vw * 0.5 ) * (realZ) + 1)
          let scale = dist * dist * dist
          // let fontsize = 1 + 1 - ( - (1 / vw * 0.5 ) * (vw * 0.2 - translated3d.z) + 1)
          // let fontsize = (1 - translated3d.z / (vw * 0.2)) + 100

          /* would bubble be placed outside room boundaries? If yes, invert */
          let bubbleWidth = (vw * 0.7) <= 500 ? (vw * 0.7) : 500  // see and match MeoSpeechBubble CSS styling !
          let figureStageBoundaries = figureStage.current.getBoundingClientRect()
          let inverted = (xPos + bubbleWidth) > figureStageBoundaries.width ? true : false;
          let scrollToX = xPos - vw * 0.3

          if(inverted) {
            xPos = Math.round(offsetPos.x - bubbleWidth + clickedItemSize.width * 0.1)
            scrollToX = xPos
          }

          const txt = getDialogue(person)
          const sex = store.meo.persons[person].sex

          const bubble =
          <StyledSpeechBubble
              className={inverted ? "inverted" : null}
              x={xPos}
              y={yPos}
              z={translated3d.z}
              translations={translated3d}
              fontsize={scale + "em"}
              scale={scale}
              sex={sex}
              dialogue={txt}
              closeFn={closeNoActiveFn}
          />
          setActiveBubble({ id: person, bubble })
          trackEvent({ category: "MEO Dialog", action: "Open", name: person })

          /* Smooth scroll disabled for Safari */
          if(ua.browser.name !== "Safari") {
            room.current.scrollTo({left: scrollToX , behavior: "smooth"}) // vw - bubble width -->  30vw
          }
        }
  }

  const onboarding = () => {
      // let txt = <div><h1>Heeeeeej!</h1><p>Välkommen till operationssalen. Det här är inte vilken operationssal som helst. Det här är DIN operationssal. Här är det DU som bestämmer.</p> <p>Usch vad tråkigt det ser ut här inne. Ska du inte göra om lite? Klicka på verktygslådan för att välja hur du vill att det ska ut här inne. Klicka på XXX för att välja vilka som ska vara med dig på operationen+val barn och förälder. </p></div>
      // let txt = "replace with cms text"
      // <Dialogue dialogue={txt} btnClose={defaultCloseButton} headline={headline}>
      //   {confirm}
      // </Dialogue>

      // <>
      // <Tryggwebb text={txt} audiofile={sound} onClose={closeFn} />
      // {confirm}
      // </>

      const headline = <h1>{welcome.headline}</h1>
      const block = welcome.dialogue
      const txt = block[0].textblock.html
      const sound = block[0].sound_m
      const confirm = <ButtonRound onClick={closeFn}>{intl.formatMessage({ id: "start" })}</ButtonRound>

      if (store.meo.dialogue.intro === 0) {
        const dialogue =
          <Tryggwebb text={txt} headline={headline} audiofile={sound} onClose={closeFn} />
        openModal(null, "meoItem", { closeFn, customCloseButton: true, bgBlur: "0", children: dialogue })
      }
  }

  const cleanClassNameFromStore = (i) => i.substr(0, i.indexOf('.'))

  const xrayImg = () => {
    const language = lang === "sv" ? "sv" : "en"
    if (store.meo.xray.screenOn === false) {
      return require(`../../images/meo/MEO_Rontgenapparat_${language}_1.svg`)
    }
    const num = parseInt(store.meo.xray.sliderPos) + 1
    return require(`../../images/meo/MEO_Rontgenapparat_${language}_${num}.svg`)
  }

  const getMeoButtonImage = (id, type, label, image) => (
    <MeoButtonImage
      id={id}
      label={label}
      type={type}
      image={image}
      onClick={(e) => type === "person" ? handlePersonClick(id, e) : handleItemClick(id, e)}
      meopink={meopink}
      activeItem={activeItem}
      activeBubble={activeBubble}
    />
  )

  const getBubble = (id) => (
    <MeoButtonImage
      id={id}
      activeBubble={activeBubble}
      bubbleOnly
    />
  )

  return(
  <Wrapper key={key}>
    <MeoControlsPersons active={activeToolbox == "person" ? true : null} activeItem={activeItem} clickFn={handlePersonConfigClick} setActiveTb={setActiveToolbox} />
    <MeoControlsFurnishing active={activeToolbox == "furnishing" ? true : null} activeItem={activeItem} clickFn={handleConfigClick} setActiveTb={setActiveToolbox} />
    <Room ref={room} tabIndex={activeBubble ? "-1" : null}>
      <Stage onClick={ closeFn } id="stage-wall">
        <WallLeft bg={store.meo.furniture.wall} />
        <WallRightWrap>
          <WallRight bg={store.meo.furniture.wall} />
        </WallRightWrap>
        <Floor bg={store.meo.furniture.floor} />
        <WallBack bg={store.meo.furniture.wall}>
          <ClockWrapper><AnalogClock /></ClockWrapper>
        </WallBack>
      </Stage>
      <Stage ref={figureStage} id="stage-figures">
        <Figures>
          {
            // <StyledXray
            // className={activeItem !== "xray" ? "is-inactive" : "active"}
            // disabled={true}
            // onClick={() => handleItemClick("xray")} />


            // tpye, id and string id
            /*
            [
              ["item", "xray", null],
              ["person", "surgeon", "meo_surgeon"],
              ["item", "surgeontable", "meo_surgeonTools"],
              ["figure", "chair", "meo_chair"],
              ["person", "parent", "meo_parent"],
              ["comp", "MeoOperationsbord", null],
              ["person", "anadoc", "meo_anaesthesiaDoctor"],
              ["item", "anatable", "meo_anaesthesiaTools"],
              ["item", "anamachine", "meo_anaesthesiaMachine"],
              ["person", "nurse", "meo_nurse"],
              ["figure", "lamp", "meo_lamp"],
            ]
            */
          }
          {getMeoButtonImage("xray", "item", "meo_xray", xrayImg())}
          {getMeoButtonImage("surgeon", "person", "meo_surgeon", require(`../../images/meo/person/MEO_Kirurg_${store.meo.persons.surgeon.id}.svg`))}
          {getMeoButtonImage("surgeontable", "item", "meo_surgeonTools", require("../../images/meo/furniture/MEO_Rullbord_Hel.svg"))}
          <Figure id="chair" className={cleanClassNameFromStore(store.meo.furniture.chair)} src={require(`../../images/meo/furniture/MEO_${store.meo.furniture.chair}`)} alt={intl.formatMessage({ id: "meo_chair" }) } />
          {getMeoButtonImage("parent", "person", "meo_parent", require(`../../images/meo/person/MEO_Foralder_Sittande_${store.meo.persons.parent.id}.svg`))}
          <MeoOperationsbord personClickFn={handlePersonClick} activeItem={activeItem} />
          {getBubble("child")}
          {/*<ButtonImage id="optable" src={require(`../../images/meo/furniture/MEO_${store.meo.furniture.optable}`)} alt="" />*/}
          {getMeoButtonImage("anadoc", "person", "meo_anaesthesiaDoctor", require(`../../images/meo/person/MEO_Narkoslakare_${store.meo.persons.anadoc.id}.svg`))}
          {getMeoButtonImage("anatable", "item", "meo_anaesthesiaTools", require(`../../images/meo/furniture/MEO_Droppstallning_Hel_${lang === "sv" ? "Sve" : "Eng"}.svg`))}
          {getMeoButtonImage("anamachine", "item", "meo_anaesthesiaMachine", require("../../images/meo/furniture/MEO_Narkosapparat.svg"))}
          {getMeoButtonImage("nurse", "person", "meo_nurse", require(`../../images/meo/person/MEO_Skoterska_${store.meo.persons.nurse.id}.svg`))}
          <Figure id="lamp" src={require(`../../images/meo/furniture/MEO_${store.meo.furniture.lamp}`)} alt={intl.formatMessage({ id: "select" }) + " " + intl.formatMessage({ id: "meo_lamp" }) } />
        </Figures>
      </Stage>
    </Room>
    <ActiveModal />
  </Wrapper>
  )
}
