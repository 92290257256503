import React from "react"

export default function MeoOperationsbord1(props) {
  return(
    <svg viewBox="0 0 566.93 311.81">
      <defs>
        <radialGradient id="a" cx="426.04" cy="224.04" r="14.25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </radialGradient>
        <linearGradient id="b" x1="711.55" y1="455.6" x2="711.55" y2="431.63" gradientTransform="matrix(0.79, -0.61, 0.7, 0.91, -454.82, 252.42)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <radialGradient id="c" cx="208.53" cy="233.22" r="15.45" xlinkHref="#a"/>
        <linearGradient id="d" x1="533.88" y1="346.02" x2="533.88" y2="320.03" xlinkHref="#b"/>
        <radialGradient id="e" cx="306.14" cy="287.56" r="17.76" xlinkHref="#a"/>
        <linearGradient id="f" x1="577.4" y1="436.36" x2="577.4" y2="406.47" xlinkHref="#b"/>
        <linearGradient id="g" x1="313.38" y1="254.54" x2="313.38" y2="189.8" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="h" x1="275.06" y1="221.62" x2="275.06" y2="73.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="i" x1="307.23" y1="221.62" x2="307.23" y2="79.31" xlinkHref="#g"/>
        <linearGradient id="j" data-name="linear_gray1" x1="174.64" y1="240.46" x2="265.38" y2="240.46" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="k" x1="307.44" y1="226.11" x2="369.01" y2="226.11" xlinkHref="#j"/>
        <linearGradient id="l" data-name="linear_gray2" x1="308.15" y1="186.59" x2="308.15" y2="168.71" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#999"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="m" x1="308.23" y1="184.75" x2="308.23" y2="170.57" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c0"/>
          <stop offset="1" stopColor="#e2eed8"/>
        </linearGradient>
        <linearGradient id="n" x1="316.12" y1="300.93" x2="316.12" y2="292.87" gradientTransform="translate(-132.53 -218.68) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#3c3"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="o" x1="308.15" y1="208.56" x2="308.15" y2="190.68" xlinkHref="#l"/>
        <linearGradient id="p" x1="308.23" y1="206.71" x2="308.23" y2="192.54" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#fcc"/>
        </linearGradient>
        <linearGradient id="q" x1="316.12" y1="317.39" x2="316.12" y2="309.33" gradientTransform="translate(-132.53 -218.68) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#b10000"/>
        </linearGradient>
        <linearGradient id="r" x1="152.84" y1="156.26" x2="152.07" y2="147.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="s" data-name="linear_gray3" x1="212.44" y1="148.98" x2="212.44" y2="143.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.21" stopColor="#c3c3c3"/>
          <stop offset="0.54" stopColor="#ababab"/>
          <stop offset="0.97" stopColor="#838383"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="t" x1="92.45" y1="159.61" x2="92.45" y2="154.61" xlinkHref="#s"/>
        <linearGradient id="u" x1="287.3" y1="144.37" x2="286.52" y2="135.45" xlinkHref="#r"/>
        <linearGradient id="v" x1="226.86" y1="147.73" x2="226.86" y2="142.73" xlinkHref="#s"/>
        <linearGradient id="w" x1="346.48" y1="136.97" x2="346.48" y2="131.97" xlinkHref="#s"/>
        <linearGradient id="x" x1="410.46" y1="133.38" x2="409.68" y2="124.47" xlinkHref="#r"/>
        <linearGradient id="y" x1="361" y1="135.8" x2="361" y2="130.81" xlinkHref="#s"/>
        <linearGradient id="z" x1="459.08" y1="126.92" x2="459.08" y2="121.92" xlinkHref="#s"/>
        <linearGradient id="aa" x1="509.5" y1="116.79" x2="506.9" y2="107.09" xlinkHref="#r"/>
        <linearGradient id="ab" x1="473.08" y1="124.43" x2="473.08" y2="119.5" xlinkHref="#s"/>
        <linearGradient id="ac" x1="543.74" y1="104.05" x2="543.74" y2="99.25" xlinkHref="#s"/>
        <linearGradient id="ad" x1="515.38" y1="121.27" x2="515.38" y2="42.13" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="ae" data-name="linear_green" x1="230.13" y1="25.16" x2="230.13" y2="124.9" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#c1dfc4"/>
          <stop offset="1" stopColor="#6fbd85"/>
        </linearGradient>
      </defs>
      <g>
        <g>
          <circle cx="423.71" cy="224.25" r="19.25"/>
          <ellipse cx="426.04" cy="224.04" rx="13.64" ry="14.84" fill="url(#a)"/>
          <path d="M430.19,225.89s0.37,1,0,1.24a1,1,0,0,1-1.09,0l-24.95-14.56,18.66-3.15Z" fill="url(#b)"/>
        </g>
        <g>
          <circle cx="206" cy="233.44" r="20.87"/>
          <ellipse cx="208.53" cy="233.22" rx="14.79" ry="16.09" fill="url(#c)"/>
          <path d="M213,235.22s0.4,1,0,1.35a1.08,1.08,0,0,1-1.18,0l-27.05-15.79L205,217.34Z" fill="url(#d)"/>
        </g>
        <g>
          <circle cx="303.24" cy="287.81" r="24"/>
          <ellipse cx="306.14" cy="287.56" rx="17" ry="18.5" fill="url(#e)"/>
          <path d="M311.31,289.86s0.46,1.2,0,1.55a1.24,1.24,0,0,1-1.36,0l-31.11-18.16,23.26-3.92Z" fill="url(#f)"/>
        </g>
        <polygon points="247.3 203.4 337.94 199.06 415.17 189.8 452.11 201.03 369.01 210.81 307.44 230.28 337.73 243.88 265.38 254.54 174.65 202.3 235.23 197.96 247.3 203.4" fill="url(#g)"/>
        <polygon points="288.06 221.62 262.06 209.98 262.06 73.44 288.06 81.67 288.06 221.62" fill="url(#h)"/>
        <polygon points="326.39 216.08 288.06 221.62 288.06 81.67 326.41 79.31 326.39 216.08" fill="url(#i)"/>
        <polygon points="337.73 265.56 265.37 278.63 265.38 254.54 337.72 243.88 337.73 265.56" fill="#ccc"/>
        <polygon points="265.37 278.63 174.65 222.07 174.65 202.3 265.38 254.54 265.37 278.63" fill="url(#j)"/>
        <polygon points="452.11 217.73 369.01 229.91 369.01 210.81 452.11 201.03 452.11 217.73" fill="#ccc"/>
        <polygon points="369.01 229.91 332.23 241.41 307.44 230.28 369.01 210.81 369.01 229.91" fill="url(#k)"/>
        <g>
          <g>
            <path d="M317,177.09a9.61,9.61,0,0,1-8.86,9.48,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,317,177.09Z" fill="url(#l)"/>
            <path d="M315.25,177.21a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.25,177.21Z" fill="url(#m)"/>
            <path d="M313.56,177.32a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,313.56,177.32Z" fill="url(#n)"/>
          </g>
          <path d="M308.36,173.62l3,2.8v1.44l-2.49-2.25v5.83l-1.1.12v-5.89l-2.64,2.64v-1.52Z" fill="#fff"/>
        </g>
        <g>
          <g>
            <path d="M317,199.05a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.48A8.24,8.24,0,0,1,317,199.05Z" fill="url(#o)"/>
            <path d="M315.25,199.18a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.25,199.18Z" fill="url(#p)"/>
            <path d="M313.56,199.28a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,313.56,199.28Z" fill="url(#q)"/>
          </g>
          <path d="M305.15,200.88v-1.52l2.64,2.31v-5.89l1.1-.11v5.85l2.49-2.6v1.45l-3,3.27Z" fill="#fff"/>
        </g>
      </g>
      <g className="bedTop">
        <g>
          <polygon points="11.99 119.84 12 105.3 87.09 149.22 466.55 115.73 551.18 91.74 551.18 107.59 466.61 132.18 87.09 166.63 11.99 119.84" fill="#ccc"/>
          <g>
            <polygon points="217.79 141.69 87.12 153.21 87.12 162.01 217.79 150.5 217.79 141.69" fill="url(#r)"/>
            <ellipse cx="212.44" cy="146.48" rx="2.42" ry="2.5" fill="url(#s)"/>
            <ellipse cx="92.45" cy="157.11" rx="2.4" ry="2.5" fill="url(#t)"/>
          </g>
          <g>
            <polygon points="351.96 129.72 221.86 141.29 221.86 150.09 351.96 138.53 351.96 129.72" fill="url(#u)"/>
            <ellipse cx="226.86" cy="145.23" rx="2.43" ry="2.5" fill="url(#v)"/>
            <ellipse cx="346.48" cy="134.47" rx="2.38" ry="2.5" fill="url(#w)"/>
          </g>
          <g>
            <polygon points="464.33 119.69 355.81 129.35 355.81 138.16 464.33 128.49 464.33 119.69" fill="url(#x)"/>
            <ellipse cx="361" cy="133.3" rx="2.43" ry="2.5" fill="url(#y)"/>
            <ellipse cx="459.08" cy="124.42" rx="2.37" ry="2.5" fill="url(#z)"/>
          </g>
          <g>
            <polygon points="547.98 96.08 468.42 118.98 468.42 127.79 547.98 104.89 547.98 96.08" fill="url(#aa)"/>
            <ellipse cx="473.08" cy="121.97" rx="2.21" ry="2.47" fill="url(#ab)"/>
            <ellipse cx="543.74" cy="101.65" rx="2.18" ry="2.4" fill="url(#ac)"/>
          </g>
          <polygon points="12 105.3 12 83.84 401.48 58.58 455.6 47.35 551.17 70.1 551.18 91.74 466.55 115.73 87.09 149.22 12 105.3" fill="#00aa64"/>
          <polygon points="12 105.3 12 83.84 87.09 124.89 466.67 92.36 551.18 70.1 551.18 91.74 466.55 115.73 87.09 149.22 12 105.3" fill="#0c8953"/>
          <path d="M464.48,49.47l1.83-1.6a22.82,22.82,0,0,1,19.86-5.23c1.72,0.4,57.48,13.51,70.11,16.62,3.27,0.8,10.06,2.47,10,10-0.05,5.57-7.27,9.84-8.09,10.31l-73.35,41.34a2.49,2.49,0,0,1-1.23.33h0a2.5,2.5,0,0,1-1.24-4.67L555.7,75.26c2.23-1.28,5.55-4.09,5.57-6,0-2.49-.86-3.83-6.18-5.13C542.35,61,485.57,47.63,485,47.49l-0.1,0a17.76,17.76,0,0,0-14.37,3.44Z" fill="url(#ad)"/>
        </g>
        {props.children}
        <g>
          <path d="M445.63,94.17s9.41-21.07-7.34-32.57c-11.06-7.59-20-8.1-24.5-11-9-5.75-5.92-10-23.5-19.25-17.18-9-42.42-6.58-75.75-2.58-23.18,2.78-30.17,9.66-52.83,11-32,1.88-18.81.67-40.83,0.67-18.33,0-20.46.34-40.12,4-27.19,5.07-34.89,5.55-52.33,1.33-20.67-5-34.62-5.72-50.33-3C56.92,46.44,21.92,64.4,12,83.84l75.1,41.05Z" fill="url(#ae)"/>
          <path d="M445.63,94.17s9.41-21.07-7.34-32.57c-11.06-7.59-20-8.1-24.5-11-9-5.75-5.92-10-23.5-19.25-12.64-6.65-29.62-7.08-51-5.25,0,0,19.73,2.73,30.23,11.5,9.18,7.66,6.12,9.12,15.37,18.12,8.63,8.4,11.63,4.31,22,12,7.25,5.37,7.43,17.39,6,29.25Z" fill="#fff" opacity="0.5"/>
        </g>
      </g>
    </svg>

  )
}
