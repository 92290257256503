import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

export default function MeoSurgeonTable({ lang, data, handleClick, isThisActive, activeItem, activeRef = null, ...props }) {
  const intl = useIntl()

  const getItem = (item) => get(data.find((x) => x._id === `item_${item}`), "headline", null)

  return(
    <svg className={activeItem ? "clicked" : null} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 493.83 245.41">
      <title>{intl.formatMessage({ id: "meo_surgeonTools" })}</title>
      <defs>
        <filter id="highlight" x="0" y="0">
          <feDropShadow dx="0" dy="1.5" stdDeviation="0.5" floodColor="#000" floodOpacity="0.8" />
        </filter>
        <linearGradient id="surgeontable-a" data-name="Namnlös övertoning 25" x1="222.36" y1="89.63" x2="254.23" y2="270.4" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#00aa64"/>
          <stop offset="0.23" stopColor="#00a15e"/>
          <stop offset="0.6" stopColor="#00894d"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="surgeontable-b" data-name="Ny övertoningsruta 1" x1="-1577.42" y1="332.72" x2="-1555.25" y2="322.38" gradientTransform="matrix(-1, 0, 0, 1, -1242.08, -0.15)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.14" stopColor="#dedede"/>
          <stop offset="0.36" stopColor="#f6f6f6"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.64" stopColor="#f6f6f6"/>
          <stop offset="0.86" stopColor="#dedede"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="surgeontable-c" x1="200.76" y1="306.81" x2="244.88" y2="286.24" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#1c66cc"/>
          <stop offset="1" stopColor="#39f"/>
        </linearGradient>
        <linearGradient id="surgeontable-d" x1="-71.91" y1="-111.54" x2="-64.29" y2="-67.52" gradientTransform="matrix(1.07, 0.2, -0.19, 0.98, 291.77, 401.16)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-e" x1="221.89" y1="331.54" x2="228.67" y2="317" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="0.51" stopColor="#fdfdfd"/>
          <stop offset="0.7" stopColor="#f6f6f6"/>
          <stop offset="0.83" stopColor="#ebebeb"/>
          <stop offset="0.94" stopColor="#dadada"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="surgeontable-f" x1="223.06" y1="309.14" x2="263.92" y2="298.19" xlinkHref="#surgeontable-c"/>
        <linearGradient id="surgeontable-g" x1="84.9" y1="-53.97" x2="97.28" y2="-55.64" gradientTransform="matrix(0, 1, -1.04, 0, 188.49, 209.8)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#39f"/>
          <stop offset="0.15" stopColor="#2d8bf1"/>
          <stop offset="0.44" stopColor="#2377dd"/>
          <stop offset="0.72" stopColor="#1e6ad0"/>
          <stop offset="1" stopColor="#1c66cc"/>
        </linearGradient>
        <linearGradient id="surgeontable-h" x1="523.77" y1="625.17" x2="521.67" y2="615.28" gradientTransform="translate(-120.85 -176.58) rotate(-17.85)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e1e1e1"/>
          <stop offset="0.13" stopColor="#eee"/>
          <stop offset="0.32" stopColor="#fbfbfb"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.78" stopColor="#fbfbfb"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="surgeontable-i" x1="489.35" y1="625.05" x2="494.01" y2="616.99" gradientTransform="translate(-120.85 -176.58) rotate(-17.85)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="0.34" stopColor="#fcfcfc"/>
          <stop offset="0.58" stopColor="#f3f3f3"/>
          <stop offset="0.8" stopColor="#e3e3e3"/>
          <stop offset="1" stopColor="#cdcdcd"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="surgeontable-j" x1="514.61" y1="617.34" x2="512.42" y2="604.9" gradientTransform="translate(-120.85 -176.58) rotate(-17.85)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="0.22" stopColor="#fbfbfb"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.7" stopColor="#fbfbfb"/>
          <stop offset="0.9" stopColor="#eee"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-k" x1="521.64" y1="522.34" x2="519.54" y2="512.45" gradientTransform="matrix(0.97, -0.23, 0.23, 0.97, -82.84, -136.13)" xlinkHref="#surgeontable-h"/>
        <linearGradient id="surgeontable-l" x1="487.22" y1="522.22" x2="491.88" y2="514.17" gradientTransform="matrix(0.97, -0.23, 0.23, 0.97, -82.84, -136.13)" xlinkHref="#surgeontable-i"/>
        <linearGradient id="surgeontable-m" x1="512.48" y1="514.51" x2="510.28" y2="502.08" gradientTransform="matrix(0.97, -0.23, 0.23, 0.97, -82.84, -136.13)" xlinkHref="#surgeontable-j"/>
        <linearGradient id="surgeontable-n" x1="537.01" y1="374.05" x2="534.91" y2="364.16" gradientTransform="matrix(0.99, -0.12, 0.12, 0.99, -39, -64.89)" xlinkHref="#surgeontable-h"/>
        <linearGradient id="surgeontable-o" x1="502.6" y1="373.93" x2="507.25" y2="365.87" gradientTransform="matrix(0.99, -0.12, 0.12, 0.99, -39, -64.89)" xlinkHref="#surgeontable-i"/>
        <linearGradient id="surgeontable-p" x1="527.85" y1="366.22" x2="525.66" y2="353.78" gradientTransform="matrix(0.99, -0.12, 0.12, 0.99, -39, -64.89)" xlinkHref="#surgeontable-j"/>
        <linearGradient id="surgeontable-q" x1="329.78" y1="285.78" x2="338.69" y2="301.2" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.06" stopColor="#d8d8d8"/>
          <stop offset="0.18" stopColor="#e9e9e9"/>
          <stop offset="0.33" stopColor="#f6f6f6"/>
          <stop offset="0.53" stopColor="#fdfdfd"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-r" x1="314.87" y1="297.89" x2="332.54" y2="297.89" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0" stopColor="#b4b4b4"/>
          <stop offset="0.2" stopColor="#cacaca"/>
          <stop offset="0.42" stopColor="#dadada"/>
          <stop offset="0.66" stopColor="#e3e3e3"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-s" x1="318.22" y1="295.52" x2="334.11" y2="295.52" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-t" x1="321.63" y1="293.54" x2="336.86" y2="293.54" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-u" x1="324.58" y1="291.98" x2="340.67" y2="291.98" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-v" x1="332.48" y1="287.08" x2="348.37" y2="287.08" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-w" x1="334.78" y1="285.67" x2="351.78" y2="285.67" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-x" x1="330.04" y1="288.72" x2="345.78" y2="288.72" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-y" x1="327.71" y1="290.44" x2="343.6" y2="290.44" xlinkHref="#surgeontable-r"/>
        <linearGradient id="surgeontable-z" x1="328.1" y1="288.68" x2="315.74" y2="262.17" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.09" stopColor="#eee"/>
          <stop offset="0.29" stopColor="#fbfbfb"/>
          <stop offset="0.48" stopColor="#fff"/>
          <stop offset="0.69" stopColor="#fbfbfb"/>
          <stop offset="0.9" stopColor="#eee"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-aa" data-name="Ny övertoningsruta 15" x1="295.05" y1="283.01" x2="316.21" y2="283.01" gradientTransform="translate(120.43 -90.09) rotate(20.8)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.1" stopColor="#eee"/>
          <stop offset="0.3" stopColor="#fbfbfb"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.7" stopColor="#fbfbfb"/>
          <stop offset="0.9" stopColor="#eee"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-ab" x1="404.54" y1="309.52" x2="407.4" y2="309.52" gradientTransform="matrix(0.83, 0.27, -0.32, 0.72, 65.81, -50.44)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-ac" data-name="Ny övertoningsruta 13" x1="323.9" y1="289.68" x2="310.14" y2="265.84" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.12" stopColor="#cdcdcd"/>
          <stop offset="0.25" stopColor="#e1e1e1"/>
          <stop offset="0.38" stopColor="#eee"/>
          <stop offset="0.5" stopColor="#f2f2f2"/>
          <stop offset="0.62" stopColor="#eee"/>
          <stop offset="0.75" stopColor="#e1e1e1"/>
          <stop offset="0.88" stopColor="#cdcdcd"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <linearGradient id="surgeontable-ad" x1="326.97" y1="288.08" x2="313.47" y2="264.7" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-ae" x1="329.95" y1="286.51" x2="316.7" y2="263.56" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-af" x1="332.73" y1="285.05" x2="319.71" y2="262.5" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-ag" x1="335.33" y1="283.69" x2="322.53" y2="261.53" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-ah" x1="337.78" y1="282.39" x2="325.18" y2="260.55" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-ai" x1="339.77" y1="281.32" x2="327.33" y2="259.78" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-aj" x1="320.76" y1="291.33" x2="306.72" y2="267.02" xlinkHref="#surgeontable-ac"/>
        <linearGradient id="surgeontable-ak" x1="260.91" y1="190.74" x2="332.96" y2="203.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.28" stopColor="#f8f8f8"/>
          <stop offset="0.48" stopColor="#fff"/>
          <stop offset="0.72" stopColor="#fcfcfc"/>
          <stop offset="0.88" stopColor="#f4f4f4"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-al" x1="281.8" y1="223.35" x2="312.44" y2="253.99" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.59" stopColor="#f8f8f8"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-am" x1="294.58" y1="203.54" x2="319.36" y2="228.32" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.23" stopColor="#f1f1f1"/>
          <stop offset="0.58" stopColor="#fcfcfc"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-an" x1="284.49" y1="182.4" x2="329.39" y2="182.4" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="0.44" stopColor="#fbfbfb"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-ao" x1="280.84" y1="167.71" x2="328.84" y2="176.17" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.45" stopColor="#f3f3f3"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-ap" x1="274.2" y1="145.12" x2="331.36" y2="155.2" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f0f0f0"/>
          <stop offset="1" stopColor="#fafafa"/>
        </linearGradient>
        <linearGradient id="surgeontable-aq" x1="318.36" y1="148.25" x2="318.36" y2="130.24" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-ar" x1="306.5" y1="133.22" x2="306.5" y2="121.82" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="surgeontable-as" x1="280.89" y1="151.61" x2="336.17" y2="151.61" xlinkHref="#surgeontable-aq"/>
        <linearGradient id="surgeontable-at" x1="262.73" y1="261.62" x2="269.18" y2="253.93" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <linearGradient id="surgeontable-au" x1="281.6" y1="274.61" x2="281.6" y2="225.9" xlinkHref="#surgeontable-ap"/>
        <linearGradient id="surgeontable-av" x1="269.13" y1="230.92" x2="298.64" y2="260.43" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.23" stopColor="#f2f2f2"/>
          <stop offset="0.57" stopColor="#fcfcfc"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="surgeontable-aw" data-name="Ny övertoningsruta 6" x1="575.54" y1="279.98" x2="618.12" y2="279.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#1c66cc"/>
          <stop offset="0.07" stopColor="#2274da"/>
          <stop offset="0.22" stopColor="#2c88ee"/>
          <stop offset="0.36" stopColor="#3195fb"/>
          <stop offset="0.5" stopColor="#39f"/>
          <stop offset="0.64" stopColor="#3195fb"/>
          <stop offset="0.78" stopColor="#2c88ee"/>
          <stop offset="0.93" stopColor="#2274da"/>
          <stop offset="1" stopColor="#1c66cc"/>
        </linearGradient>
        <linearGradient id="surgeontable-ax" x1="422.8" y1="155.62" x2="422.8" y2="139.98" xlinkHref="#surgeontable-c"/>
        <linearGradient id="surgeontable-ay" data-name="Ny övertoningsruta 5" x1="506.15" y1="319.98" x2="620.2" y2="319.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="0.17" stopColor="#b3b3b3"/>
          <stop offset="0.33" stopColor="#f2f2f2"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="0.67" stopColor="#f2f2f2"/>
          <stop offset="0.83" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="surgeontable-az" x1="507.56" y1="303.51" x2="619.03" y2="303.51" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.17" stopColor="#f2f2f2"/>
          <stop offset="0.33" stopColor="#ccc"/>
          <stop offset="0.5" stopColor="#f2f2f2"/>
          <stop offset="0.67" stopColor="#ccc"/>
          <stop offset="0.83" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <linearGradient id="surgeontable-ba" x1="563.3" y1="322.26" x2="563.3" y2="295.31" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.24" stopColor="#cdcdcd"/>
          <stop offset="0.5" stopColor="#e1e1e1"/>
          <stop offset="0.75" stopColor="#eee"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="surgeontable-bb" data-name="Namnlös övertoning 27" x1="446.31" y1="270.19" x2="488.75" y2="270.19" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#a6a6a6"/>
          <stop offset="0.08" stopColor="#aaa"/>
          <stop offset="0.32" stopColor="#b1b1b1"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <linearGradient id="surgeontable-bc" data-name="Namnlös övertoning 144" x1="457.95" y1="287.35" x2="457.95" y2="230.13" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.05" stopColor="#e0e0e0"/>
          <stop offset="0.2" stopColor="#d5d5d5"/>
          <stop offset="0.41" stopColor="#cecece"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="surgeontable-bd" data-name="Namnlös övertoning 5" x1="221.98" y1="447.65" x2="221.98" y2="446.96" gradientTransform="translate(237.98 -197.25)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <linearGradient id="surgeontable-be" x1="221.98" y1="459.62" x2="221.98" y2="458.93" gradientTransform="translate(233.89 -197.97)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bf" x1="221.98" y1="458.42" x2="221.98" y2="457.73" gradientTransform="translate(234.3 -197.9)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bg" x1="221.98" y1="457.22" x2="221.98" y2="456.54" gradientTransform="translate(234.71 -197.83)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bh" x1="221.98" y1="456.02" x2="221.98" y2="455.34" gradientTransform="translate(235.12 -197.76)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bi" x1="221.98" y1="454.83" x2="221.98" y2="454.14" gradientTransform="translate(235.53 -197.68)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bj" x1="221.98" y1="453.63" x2="221.98" y2="452.95" gradientTransform="translate(235.94 -197.61)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bk" x1="221.98" y1="452.43" x2="221.98" y2="451.75" gradientTransform="translate(236.35 -197.54)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bl" x1="221.98" y1="451.24" x2="221.98" y2="450.55" gradientTransform="translate(236.75 -197.47)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bm" x1="221.98" y1="450.04" x2="221.98" y2="449.36" gradientTransform="translate(237.16 -197.39)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bn" x1="221.98" y1="448.84" x2="221.98" y2="448.16" gradientTransform="translate(237.57 -197.32)" xlinkHref="#surgeontable-bd"/>
        <linearGradient id="surgeontable-bo" x1="398.04" y1="301.7" x2="398.04" y2="225.21" xlinkHref="#surgeontable-bb"/>
        <linearGradient id="surgeontable-bp" data-name="Namnlös övertoning 7" x1="364" y1="306.53" x2="485.02" y2="306.53" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="0.03" stopColor="#e8e8e8"/>
          <stop offset="0.24" stopColor="#f2f2f2"/>
          <stop offset="0.5" stopColor="#f5f5f5"/>
          <stop offset="0.76" stopColor="#f2f2f2"/>
          <stop offset="0.97" stopColor="#e8e8e8"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="surgeontable-bq" x1="370.02" y1="306.53" x2="471.43" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-br" x1="372.9" y1="306.53" x2="466.97" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bs" x1="376.66" y1="306.53" x2="461.79" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bt" x1="377.42" y1="306.53" x2="462.55" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bu" x1="378.18" y1="306.53" x2="463.31" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bv" x1="378.94" y1="306.53" x2="464.07" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bw" x1="379.7" y1="306.53" x2="464.83" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bx" x1="381.23" y1="306.53" x2="466.35" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-by" x1="381.99" y1="306.53" x2="467.11" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-bz" x1="380.46" y1="306.53" x2="465.59" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ca" x1="368.72" y1="306.53" x2="473.15" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cb" x1="367.11" y1="306.53" x2="476.15" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cc" x1="366.2" y1="306.53" x2="477.84" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cd" x1="365.62" y1="306.53" x2="478.93" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ce" x1="365.21" y1="306.53" x2="479.69" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cf" x1="364.92" y1="306.53" x2="480.25" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cg" x1="364.69" y1="306.53" x2="480.68" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ch" x1="364.5" y1="306.53" x2="481.02" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ci" x1="364.36" y1="306.53" x2="481.3" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cj" x1="364.23" y1="306.53" x2="481.53" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ck" x1="364.13" y1="306.53" x2="481.72" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cl" x1="343.76" y1="306.53" x2="493.77" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cm" x1="331.23" y1="306.53" x2="500.8" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cn" x1="387.31" y1="306.53" x2="472.44" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-co" x1="386.55" y1="306.53" x2="471.67" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cp" x1="385.79" y1="306.53" x2="470.91" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cq" x1="385.03" y1="306.53" x2="470.15" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cr" x1="384.27" y1="306.53" x2="469.39" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cs" x1="382.75" y1="306.53" x2="467.87" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ct" x1="383.51" y1="306.53" x2="468.63" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cu" x1="347.53" y1="306.53" x2="491.4" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cv" x1="352.86" y1="306.53" x2="488.82" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cw" x1="355.45" y1="306.53" x2="487.57" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cx" x1="356.98" y1="306.53" x2="486.83" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cy" x1="357.99" y1="306.53" x2="486.34" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-cz" x1="358.71" y1="306.53" x2="485.99" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-da" x1="359.25" y1="306.53" x2="485.74" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-db" x1="359.66" y1="306.53" x2="485.53" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dc" x1="359.99" y1="306.53" x2="485.37" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dd" x1="360.26" y1="306.53" x2="485.24" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-de" x1="360.49" y1="306.53" x2="485.14" y2="306.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-df" x1="363.86" y1="321.78" x2="484.88" y2="321.78" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dg" x1="363.99" y1="292.29" x2="485.01" y2="292.29" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dh" x1="363.98" y1="294.41" x2="485" y2="294.41" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-di" x1="363.97" y1="296.53" x2="484.99" y2="296.53" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dj" x1="363.96" y1="298.66" x2="484.98" y2="298.66" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dk" x1="363.95" y1="300.78" x2="484.97" y2="300.78" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dl" x1="363.94" y1="302.9" x2="484.96" y2="302.9" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dm" x1="363.93" y1="305.02" x2="484.95" y2="305.02" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dn" x1="363.92" y1="307.15" x2="484.94" y2="307.15" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-do" x1="363.92" y1="309.24" x2="484.93" y2="309.24" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dp" x1="363.91" y1="311.36" x2="484.92" y2="311.36" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dq" x1="363.9" y1="313.47" x2="484.91" y2="313.47" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dr" x1="363.89" y1="315.58" x2="484.9" y2="315.58" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-ds" x1="363.88" y1="317.66" x2="484.89" y2="317.66" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-dt" x1="363.87" y1="319.76" x2="484.89" y2="319.76" xlinkHref="#surgeontable-bp"/>
        <linearGradient id="surgeontable-du" data-name="Namnlös övertoning 9" x1="364.74" y1="288.54" x2="484.89" y2="288.54" xlinkHref="#surgeontable-bp"/>
      </defs>
      <g id="table" onClick={ () => handleClick(null)}>
        <path d="M667.14,349.47l-2.81,9.08c-0.92,2.77-3.07,5.1-6.52,5.1H184.08a7,7,0,0,1-6.52-5.1l-2.81-9.08h492.4Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
        <g>
          <polygon points="423.92 116.51 489.58 228.91 4.25 228.91 69.91 116.51 423.92 116.51" fill="url(#surgeontable-a)"/>
          <path d="M598,234.74l65.66,112.4H178.28l65.66-112.4H598m0-3.4h-354a4.25,4.25,0,0,0-3.67,2.11L174.61,345a4.25,4.25,0,0,0,3.67,6.4H663.61a4.25,4.25,0,0,0,3.67-6.4L601.62,233.45a4.25,4.25,0,0,0-3.67-2.11h0Z" transform="translate(-174.03 -118.24)" fill="#ededed"/>
        </g>

      </g>
      <g id="needleyarn"
        data-title="Needle and yarn"
        className={isThisActive("needleyarn")}
        onClick={handleClick}
      >
        <title>{getItem("needleyarn")}</title>
        <a xlinkHref="#needleyarn">
        <polygon points="170.38 213.09 161.02 220.77 120.34 219.99 107.58 206.82 94.49 187.93 102.95 178.11 126.39 181.55 160.32 195.05 170.38 213.09" fill="transparent"/>
        <path d="M305.64,332.12h-0.53c-3.55-.13-6.52-1.3-8.36-3.3a6.46,6.46,0,0,1-1.81-4.65,4.6,4.6,0,0,1,1.62-3.2,10,10,0,0,1,7.21-2.15c5.35,0.26,8,6.43,8.09,6.7l0.16,0.38-0.55.24-0.16-.38c0-.06-2.59-6.08-7.56-6.33a9.42,9.42,0,0,0-6.78,2,4,4,0,0,0-1.42,2.78,5.86,5.86,0,0,0,1.65,4.21c1.73,1.88,4.55,3,7.94,3.1,5.26,0.19,11.48-2.62,14.45-6.55s2.73-7.58-.67-10.31c-5.48-4.38-13.8-2.9-19.87-1.81a47.33,47.33,0,0,1-5.05.74c-5.11.3-11.67,0-13.94-3.39-1.36-2-1.26-4,.29-5.45,2.05-1.87,5.25-2.13,8.4-2.13v0.6c-2.88,0-6.08.23-8,2s-0.76,3.83-.19,4.67c1.65,2.43,6.41,3.54,13.41,3.12a47,47,0,0,0,5-.73c6.19-1.1,14.66-2.61,20.35,1.94,3.65,2.92,3.93,7,.77,11.14-3,3.94-9.1,6.8-14.42,6.8h0Z" transform="translate(-174.03 -118.24)" fill="#1c66cc"/>
        <path d="M338.22,325.79s-2.81,7.39-12.93,7.39H325c-10.12,0-12.9-7.34-12.9-7.34l-0.81.35S314.3,334,325,334h0.24c10.93,0,12.81-7.47,12.81-7.47A4.78,4.78,0,0,0,338.22,325.79Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-b)"/>
        </a>
      </g>
      <g
      id="tape"
      data-title="tape"
      className={isThisActive("tape")}
      onClick={handleClick}>
        <title>{getItem("tape")}</title>
        <a xlinkHref="#tape">
        <polygon points="35.69 207.78 37.7 207.78 37.7 208.57 39.77 208.57 39.77 209.4 41.89 209.4 41.89 210.25 44.09 210.25 44.09 211.14 46.39 211.14 46.39 212.07 48.76 212.07 48.76 213.01 51.2 213.01 51.2 214 53.68 213.99 53.68 214.99 55.72 214.99 55.72 215.82 83.88 198.6 63.86 193.13 35.69 207.78" fill="#1c66cc"/>
        <path d="M237.89,311.36V272.48a3.44,3.44,0,0,0-.58-2.27,2.8,2.8,0,0,0-3-.3c-1.74.7-21.42,7.85-21.42,7.85a8.26,8.26,0,0,0-2.91,2.83,9.25,9.25,0,0,0-1.63,4.76v8.95a5.28,5.28,0,0,0,1.86,3.28c1.63,1.25,7,5.89,7,5.89s2.44,2.17,1.51,6.46a14.65,14.65,0,0,1-3.74,7.23c-1.08,1.12-2.61,2.45-3.78,3.43-0.8.67-1.44,1.16-1.44,2.05V326Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-c)"/>
        <path d="M238.84,321.74c7.62,1.43,15.56-6.73,17.73-18.23s-2.26-22-9.89-23.43L232.45,277c-8.92-1.64-16.73,6.75-18.26,18.35-1.56,11.86,2.82,19.12,9.37,21.49Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-d)"/>
        <ellipse cx="242.76" cy="300.91" rx="21.19" ry="14.05" transform="translate(-271.88 365.59) rotate(-79.34)" fill="#fff"/>
        <path d="M227.71,333.23v-1h-2.48v-1h-2.44v-0.95h-2.37v-0.93h-2.31v-0.89h-2.19v-0.85H213.8v-0.84h-2.06a29.25,29.25,0,0,0,11.83-10l15.27,4.93S236.86,325.95,227.71,333.23Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-e)"/>
        <path d="M257.91,316.83V276.67a3.79,3.79,0,0,0-.58-2.33s-0.74-1.13-2.68-.35c-1.75.7-21.77,10.48-21.77,10.48a9.5,9.5,0,0,0-2.91,2.91,10.24,10.24,0,0,0-1.63,4.89v9.2a5.48,5.48,0,0,0,1.86,3.38c1.63,1.28,7,6.05,7,6.05s2.32,2.16,1.51,6.64a13.25,13.25,0,0,1-3.9,7.25,48.68,48.68,0,0,1-3.91,3.27,2.52,2.52,0,0,0-1.14,2.07v3.94Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-f)"/>
        <ellipse cx="245.67" cy="300.89" rx="10.52" ry="6.15" transform="translate(-269.49 368.43) rotate(-79.34)" fill="url(#surgeontable-g)"/>
        </a>
      </g>
      <g
      id="bandage"
      data-title="bandage"
      className={isThisActive("bandage")}
      onClick={handleClick}>
        <title>{getItem("bandage")}</title>
        <a xlinkHref="#bandage">
        <g>
          <path d="M528.19,258.64s-2.13.91-2.41,1.54-0.13,1.13.77,1.88,4.87,2.07,8.64,2.61c3,0.43,6.14,1.25,7.86,3.31a8.25,8.25,0,0,0,2.66,2.36,2.29,2.29,0,0,0,2,0h0c4.05-2,19.93-12.35,19.93-12.35,7.52-4.54,18-9.44,19.77-10.5s2.55-2.89,1.53-5.39a4.73,4.73,0,0,0-4.47-3.24h0c-7.7,1.61-15-1.28-16.37-1.64s-2.61,0-4.33.74a46.86,46.86,0,0,1-8.49,3.55c-4.19,1.2-11.31,6.82-13.36,7.89a135.51,135.51,0,0,1-14.37,5.93,1.47,1.47,0,0,0-1.15,1.51,1.36,1.36,0,0,0,.53,1Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
          <path d="M584.43,238.86a4.73,4.73,0,0,1,4.47,3.24c1,2.5.25,4.33-1.53,5.39s-12.25,6-19.77,10.5c0,0-15.87,10.39-19.93,12.35a4.65,4.65,0,0,0,1.49-4.89c-0.37-1.38-1.34-4.51-4.77-4.82Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-h)"/>
          <path d="M547.68,270.35a4.65,4.65,0,0,0,1.49-4.89c-0.37-1.38-1.34-4.51-4.77-4.82h0c-1.86-.17-7.62,1.19-10.22.23-1.94-.72-4.42-1.44-6-2.23,0,0-2.13.91-2.41,1.54s-0.13,1.13.77,1.88,4.87,2.07,8.64,2.61c3,0.43,6.14,1.25,7.86,3.31a8.25,8.25,0,0,0,2.66,2.35A2.29,2.29,0,0,0,547.68,270.35Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-i)"/>
          <path d="M544.39,260.64c-1.87-.17-7.62,1.19-10.22.23-1.94-.72-4.42-1.44-6-2.23-0.53-.33-1-0.57-1.32-0.81a1.36,1.36,0,0,1-.53-1,1.47,1.47,0,0,1,1.15-1.51,135.45,135.45,0,0,0,14.37-5.93c2-1.08,9.17-6.69,13.36-7.89a46.86,46.86,0,0,0,8.49-3.55c1.73-.72,2.91-1.1,4.34-0.74s8.67,3.25,16.37,1.64Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-j)"/>
        </g>
        <g>
          <path d="M502.91,251.13s-2.19.73-2.53,1.34-0.22,1.12.61,1.93,4.69,2.46,8.4,3.31c2.91,0.67,6,1.75,7.56,3.94a8.25,8.25,0,0,0,2.46,2.57,2.29,2.29,0,0,0,2,.18h0c4.2-1.62,20.87-10.68,20.87-10.68,7.87-3.91,18.7-7.93,20.56-8.85s2.78-2.67,2-5.25a4.73,4.73,0,0,0-4.19-3.6h0c-7.81,1-14.8-2.5-16.18-3s-2.6-.19-4.38.38a46.83,46.83,0,0,1-8.76,2.84c-4.27.86-11.83,5.87-14,6.77a135.62,135.62,0,0,1-14.81,4.74,1.47,1.47,0,0,0-1.27,1.41,1.36,1.36,0,0,0,.44,1Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
          <path d="M560.58,236a4.73,4.73,0,0,1,4.19,3.6c0.82,2.58-.11,4.34-2,5.25s-12.69,4.94-20.56,8.85c0,0-16.67,9.06-20.87,10.68a4.65,4.65,0,0,0,1.88-4.75c-0.26-1.41-1-4.6-4.35-5.2Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-k)"/>
          <path d="M521.37,264.4a4.65,4.65,0,0,0,1.88-4.75c-0.26-1.41-1-4.6-4.35-5.2h0c-1.85-.32-7.69.56-10.2-0.61-1.87-.87-4.29-1.8-5.78-2.72,0,0-2.19.73-2.53,1.34s-0.22,1.12.61,1.93,4.69,2.46,8.4,3.31c2.91,0.67,6,1.75,7.56,3.94a8.24,8.24,0,0,0,2.46,2.57A2.29,2.29,0,0,0,521.37,264.4Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-l)"/>
          <path d="M518.88,254.45c-1.85-.32-7.69.56-10.2-0.61-1.87-.87-4.29-1.8-5.78-2.72-0.5-.37-0.91-0.65-1.25-0.91a1.36,1.36,0,0,1-.44-1,1.47,1.47,0,0,1,1.27-1.41A135.51,135.51,0,0,0,517.28,243c2.13-.91,9.69-5.92,14-6.77a46.84,46.84,0,0,0,8.76-2.84c1.78-.58,3-0.86,4.38-0.38s8.37,4,16.18,3Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-m)"/>
        </g>
        <g>
          <path d="M497.61,236.4s-2.26.48-2.66,1.05-0.34,1.08.39,2,4.38,3,8,4.23c2.82,1,5.78,2.41,7.07,4.76a8.25,8.25,0,0,0,2.16,2.82,2.29,2.29,0,0,0,1.93.4h0c4.35-1.14,21.93-8.28,21.93-8.28,8.26-3,19.47-5.79,21.42-6.49s3.06-2.34,2.54-5a4.73,4.73,0,0,0-3.76-4h0c-7.87.09-14.42-4.14-15.75-4.77s-2.56-.48-4.4-0.11a46.87,46.87,0,0,1-9,1.85c-4.34.37-12.42,4.51-14.63,5.17A135.63,135.63,0,0,1,497.56,233a1.47,1.47,0,0,0-1.42,1.26,1.36,1.36,0,0,0,.33,1.06S497.09,235.92,497.61,236.4Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
          <path d="M556.61,227.84a4.73,4.73,0,0,1,3.76,4c0.52,2.65-.59,4.3-2.54,5s-13.17,3.48-21.42,6.49c0,0-17.58,7.13-21.93,8.27a4.65,4.65,0,0,0,2.4-4.51c-0.1-1.43-.45-4.68-3.74-5.65Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-n)"/>
          <path d="M514.48,251.65a4.65,4.65,0,0,0,2.4-4.51c-0.1-1.43-.45-4.68-3.75-5.65h0c-1.8-.53-7.71-0.3-10.07-1.75-1.76-1.08-4.06-2.27-5.44-3.35,0,0-2.26.48-2.66,1.05s-0.34,1.08.39,2,4.38,3,8,4.23c2.82,1,5.78,2.41,7.07,4.76a8.24,8.24,0,0,0,2.16,2.82A2.29,2.29,0,0,0,514.48,251.65Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-o)"/>
          <path d="M513.12,241.49c-1.8-.53-7.71-0.3-10.07-1.75-1.76-1.08-4.06-2.27-5.44-3.35-0.46-.42-0.83-0.75-1.14-1a1.36,1.36,0,0,1-.33-1.06,1.47,1.47,0,0,1,1.42-1.26A135.61,135.61,0,0,0,512.8,230c2.21-.66,10.29-4.8,14.63-5.17a46.86,46.86,0,0,0,9-1.85c1.83-.37,3.07-0.52,4.4.11s7.88,4.86,15.75,4.77Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-p)"/>
        </g>
        </a>
      </g>
      <g
      id="cast"
      data-title="cast"
      className={isThisActive("cast")}
      onClick={handleClick}>
        <title>{getItem("cast")}</title>
      <a xlinkHref="#cast">
        <g>
          <g>
            <path d="M332.82,306.16l-0.09,0a9,9,0,0,1-1.36-1.07,0.15,0.15,0,0,1,.22-0.22,8.85,8.85,0,0,0,1.31,1,0.15,0.15,0,0,1-.08.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.91,302.17a0.15,0.15,0,0,1-.09,0c-0.73-.56-1.38-1-1.39-1a0.15,0.15,0,0,1,.18-0.25s0.66,0.47,1.4,1a0.15,0.15,0,0,1-.09.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M335.78,298.26h-0.06c-0.77-.36-1.2-0.61-1.21-0.62a0.15,0.15,0,0,1,.16-0.26s0.44,0.26,1.19.61a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M339.82,296.29a0.15,0.15,0,0,1-.1,0,4.56,4.56,0,0,0-1.23-.81,0.15,0.15,0,1,1,.11-0.29,4.81,4.81,0,0,1,1.33.87,0.15,0.15,0,0,1-.1.27h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M356.57,287.1l-0.08,0a4.14,4.14,0,0,0-2-.73,0.16,0.16,0,0,1-.16-0.14,0.15,0.15,0,0,1,.14-0.17,4.27,4.27,0,0,1,2.23.77,0.15,0.15,0,0,1-.08.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.07,305.34l-0.07,0a6.34,6.34,0,0,1-1.46-1.2,0.15,0.15,0,0,1,.23-0.2,6.15,6.15,0,0,0,1.37,1.14,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.63,304.85a0.15,0.15,0,0,1-.09,0l-0.19-.14c-0.7-.51-0.8-0.59-1.66-1.32a0.15,0.15,0,0,1,.2-0.23,20.71,20.71,0,0,0,1.64,1.31l0.19,0.14a0.15,0.15,0,0,1-.09.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M344.88,294h0a6,6,0,0,1-1.61-.76,0.15,0.15,0,0,1,.18-0.25,5.78,5.78,0,0,0,1.52.72,0.15,0.15,0,0,1,0,.3h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M356,287.56l-0.08,0a8.4,8.4,0,0,0-2.07-1,0.15,0.15,0,0,1,.08-0.3,8.57,8.57,0,0,1,2.15,1,0.15,0.15,0,0,1-.08.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M355,287.69a0.15,0.15,0,0,1-.09,0,5.21,5.21,0,0,0-2-.83,0.15,0.15,0,0,1,0-.3,5.45,5.45,0,0,1,2.09.88,0.15,0.15,0,0,1-.09.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M345.19,293.3h0a3.1,3.1,0,0,1-1.64-.57,0.15,0.15,0,0,1,.2-0.23h0a2.91,2.91,0,0,0,1.48.5,0.15,0.15,0,0,1,0,.31h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M343.81,294.37l-0.07,0a8.63,8.63,0,0,1-1.28-.76,0.15,0.15,0,0,1,.19-0.24,8.48,8.48,0,0,0,1.23.73,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M332.89,303.37l-0.07,0a5.67,5.67,0,0,1-1-.64,0.15,0.15,0,0,1,.2-0.23,5.51,5.51,0,0,0,.9.6,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.25,302.42l-0.08,0c-0.53-.33-1-0.65-1-0.65a0.15,0.15,0,1,1,.18-0.25s0.45,0.32,1,.64a0.15,0.15,0,0,1-.08.28h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M343.07,294.71a0.15,0.15,0,0,1-.09,0L342.1,294a0.15,0.15,0,1,1,.18-0.25l0.89,0.67a0.15,0.15,0,0,1-.09.27h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M340.45,295.84l-0.07,0a7.71,7.71,0,0,1-1.06-.69,0.15,0.15,0,1,1,.19-0.24,7.62,7.62,0,0,0,1,.66,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M336,297.62l-0.07,0a3.81,3.81,0,0,1-.84-0.57,0.15,0.15,0,0,1,.21-0.22,3.61,3.61,0,0,0,.77.52,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M336.92,297.44l-0.07,0a6.51,6.51,0,0,1-1.06-.72,0.15,0.15,0,1,1,.2-0.23,6.35,6.35,0,0,0,1,.68,0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M353.48,287.68a0.15,0.15,0,0,1-.09,0,4.88,4.88,0,0,0-1.12-.6,0.15,0.15,0,1,1,.1-0.29,5.12,5.12,0,0,1,1.21.64,0.15,0.15,0,0,1-.09.27h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M352.94,288a0.15,0.15,0,0,1-.09,0,3.15,3.15,0,0,0-.94-0.55l-0.24-.1a0.15,0.15,0,1,1,.14-0.28l0.21,0.09a3.46,3.46,0,0,1,1,.59,0.15,0.15,0,0,1-.09.27h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M338.66,296.14l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M338.16,296.44l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.67,300.86l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M333.76,300.17l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M334.19,299.79l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M334.6,299.08l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M351.89,288l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.46a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M351.44,288.37l-0.07,0c-0.52-.26-1-0.55-1-0.56a0.15,0.15,0,0,1,.16-0.26s0.49,0.29,1,.54a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M345.29,292.77l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M346.23,291.67l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.46a0.15,0.15,0,1,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M346.75,291.3l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M346.91,290.78l-0.07,0c-0.43-.21-0.67-0.36-0.68-0.37a0.15,0.15,0,0,1,.16-0.26s0.24,0.15.65,0.36a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M335,298.59l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M347.47,290.48l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M349.37,289.26l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.46a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M349.91,289l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,1,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
            <path d="M350.63,288.59l-0.07,0c-0.52-.26-0.82-0.45-0.83-0.45a0.15,0.15,0,0,1,.16-0.26s0.3,0.19.81,0.44a0.15,0.15,0,0,1-.07.29h0Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
          </g>
          <path d="M310.85,296.7s13.25,1.9,20.76,8.58c0,0,.29-5.73,3.53-8.14s7.14-2.15,9.9-5.6c2.6-3.25,8.1-4.88,10.2-5.37,0,0-11.28-2.76-19-2.84Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-q)"/>
          <g>
            <path d="M314.83,297.51l-0.48-.12a39.38,39.38,0,0,0,4.92-3.23,36.69,36.69,0,0,1,6.73-3.87l1.36-.67,0,0a54.74,54.74,0,0,0,6.73-3.69,13.64,13.64,0,0,1,4.79-2.47l0.55,0,0.62,0.05a15.46,15.46,0,0,0-5.61,2.87,55.06,55.06,0,0,1-6.8,3.74l0,0-1.36.67a36.29,36.29,0,0,0-6.61,3.8,35.6,35.6,0,0,1-4.39,3Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M319.26,298.76l-0.4-.13s2.46-1.86,4.49-3.74a32.75,32.75,0,0,1,7.65-4.66c3.89-1.82,5-2.49,6.69-3.85a12.65,12.65,0,0,1,4.84-2.57l0.59,0.08,0.64,0.09a14.57,14.57,0,0,0-5.68,2.88c-1.76,1.39-2.86,2.08-6.81,3.93a30.89,30.89,0,0,0-7.49,4.55c-2,1.9-4.17,3.5-4.2,3.52Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M323.19,300.22l-0.37-.16a36.85,36.85,0,0,0,4.56-3.83,28.87,28.87,0,0,1,7.5-4.92c3.54-1.79,5.5-3.07,7-4.55a9.36,9.36,0,0,1,4.56-2.38l0.64,0.11,0.63,0.11a10.26,10.26,0,0,0-5.39,2.6c-1.53,1.53-3.53,2.84-7.14,4.66a28.34,28.34,0,0,0-7.34,4.8,39.37,39.37,0,0,1-4.25,3.72Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M327.77,302.51l-0.29-.17s1.58-2.49,3.41-4.77a15.94,15.94,0,0,1,6.21-4.65c0.43-.21.84-0.42,1.25-0.63,0.82-.43,1.51-0.79,2.13-1.1,2.06-1,3-1.52,3.91-2.75,1.56-2.12,4.53-3.07,6-3.32l0.48,0.1,0.6,0.12c-2.09.38-5.32,1.72-6.6,3.47-1,1.35-2,1.88-4.13,2.94-0.61.31-1.3,0.66-2.11,1.09-0.41.22-.85,0.43-1.26,0.64a15,15,0,0,0-6.05,4.59,50.77,50.77,0,0,0-3.11,4.72Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
          </g>
          <g>
            <path d="M332.32,301.59c-5.67-3.86-17.34-7-17.45-7l0.78-.41c0.11,0,11.14,2.83,16.9,6.76l-0.12.35C332.38,301.4,332.32,301.59,332.32,301.59Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-r)"/>
            <path d="M333.72,298.63c-4-3.19-15.39-5.78-15.5-5.8l0.81-.43c0.46,0.1,10.92,2.45,15.07,5.73l-0.18.23-0.2.27h0Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-s)"/>
            <path d="M336.21,296.45C332,294,321.73,291.07,321.63,291l0.8-.42c0.42,0.12,10.16,3,14.43,5.48l-0.33.17Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-t)"/>
            <path d="M339.87,294.88c-5.24-2.47-15.18-5.37-15.28-5.39l0.78-.4a144.83,144.83,0,0,1,15.31,5.49l-0.38.15Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-u)"/>
            <path d="M347.75,289.19c-3.9-2-13.74-3.84-15.27-3.86l0.67-.35c1.51,0,11.24,1.79,15.22,3.83L348,289Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-v)"/>
            <path d="M351,287.55c-3.91-1.7-13.61-3.25-16.18-3.43l0.61-.33c3.5,0.26,12.45,1.72,16.39,3.44l-0.41.16Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-w)"/>
            <path d="M345.33,291.21c-4.52-2.21-15.18-4.57-15.28-4.59l0.73-.39c0.43,0.09,10.42,2.27,15,4.51l-0.24.23Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-x)"/>
            <path d="M343,293.39c-5.24-2.37-15.21-5.52-15.3-5.55l0.67-.35c0.1,0,9.94,3.1,15.22,5.48l-0.29.22Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-y)"/>
          </g>
          <path d="M336.27,283.33c4.36-1.66,5.89-8.25,3.43-14.74s-8-10.4-12.35-8.74l-26.93,9.45c5.09-1.93,11.54,2.64,14.42,10.2s1.08,15.27-4,17.2Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-z)"/>
          <ellipse cx="305.63" cy="283.01" rx="9.85" ry="14.66" transform="translate(-254.61 8.75) rotate(-20.8)" fill="url(#surgeontable-aa)"/>
          <g>
            <path d="M307.94,295.62c-4.13,0-8.5-3.91-10.63-9.51-2.56-6.74-1-13.6,3.5-15.31a6.05,6.05,0,0,1,2.16-.39c4.13,0,8.5,3.91,10.63,9.51,2.56,6.74,1,13.6-3.5,15.31a6.05,6.05,0,0,1-2.16.39h0ZM303,271a5.44,5.44,0,0,0-1.94.35c-4.17,1.59-5.59,8.1-3.15,14.52,2,5.37,6.17,9.12,10.05,9.12a5.44,5.44,0,0,0,1.94-.35c4.17-1.59,5.59-8.1,3.15-14.52-2-5.37-6.17-9.12-10.05-9.12h0Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.25"/>
            <path d="M307.71,293.75c-3.32,0-6.94-3.42-8.79-8.31-2.19-5.76-1.1-11.54,2.42-12.88a4.41,4.41,0,0,1,1.57-.28c3.32,0,6.94,3.42,8.79,8.31,2.19,5.76,1.1,11.54-2.42,12.88a4.4,4.4,0,0,1-1.57.28h0Zm-4.8-20.86a3.79,3.79,0,0,0-1.35.24c-3.21,1.22-4.13,6.64-2.06,12.09,1.74,4.58,5.2,7.91,8.22,7.91a3.78,3.78,0,0,0,1.35-.24c3.21-1.22,4.13-6.64,2.06-12.09-1.74-4.58-5.2-7.91-8.22-7.91h0Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.25"/>
            <path d="M307.28,291.73c-2.62,0-5.51-2.8-7-6.82-1.78-4.69-1-9.35,1.76-10.4a3.32,3.32,0,0,1,1.18-.21c2.62,0,5.51,2.8,7,6.82,1.78,4.69,1,9.35-1.76,10.4a3.32,3.32,0,0,1-1.18.21h0Zm-4.09-16.83a2.71,2.71,0,0,0-1,.17c-2.44.93-3.07,5.24-1.41,9.62,1.42,3.73,4.14,6.43,6.47,6.43a2.72,2.72,0,0,0,1-.17c2.44-.93,3.07-5.24,1.41-9.62-1.42-3.73-4.14-6.43-6.47-6.43h0Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.25"/>
            <path d="M306.89,289.86c-1.94,0-4.14-2.25-5.37-5.47-1.43-3.76-1-7.31,1.08-8.08a2.26,2.26,0,0,1,.8-0.15c1.94,0,4.14,2.25,5.37,5.47a11.07,11.07,0,0,1,.76,5.06c-0.18,1.57-.84,2.64-1.84,3a2.25,2.25,0,0,1-.8.15h0Zm-3.48-13.1a1.66,1.66,0,0,0-.59.11c-1.66.63-2,4-.73,7.31,1.12,2.95,3.14,5.09,4.8,5.09a1.66,1.66,0,0,0,.59-0.11c0.78-.3,1.3-1.2,1.46-2.53a10.46,10.46,0,0,0-.73-4.77c-1.12-2.95-3.14-5.09-4.8-5.09h0Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.25"/>
            <path d="M306.42,287.68c-1.17,0-2.59-1.59-3.45-3.86-1-2.62-.83-4.94.39-5.4a1.21,1.21,0,0,1,.43-0.08c1.17,0,2.59,1.59,3.45,3.86a8.82,8.82,0,0,1,.64,3.37,2.07,2.07,0,0,1-1,2,1.21,1.21,0,0,1-.43.08h0ZM303.8,279a0.59,0.59,0,0,0-.21,0c-0.74.28-.93,2.24,0,4.61s2.35,3.7,3.09,3.42a1.57,1.57,0,0,0,.63-1.48,8.2,8.2,0,0,0-.6-3.13c-0.82-2.17-2.11-3.46-2.88-3.46h0Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.25"/>
          </g>
          <ellipse cx="305.07" cy="283.01" rx="0.87" ry="2.37" transform="translate(-254.65 8.56) rotate(-20.8)" fill="url(#surgeontable-ab)"/>
          <g>
            <path d="M316.18,285.74l0-.34a8.83,8.83,0,0,0,4-1.93,29.57,29.57,0,0,1,7.76-4,27.82,27.82,0,0,0,7.59-3.95,11.51,11.51,0,0,1,5.28-2.21s0,0.19,0,.31,0,0.31,0,.31a10.87,10.87,0,0,0-5,2.09,28.41,28.41,0,0,1-7.75,4,28.94,28.94,0,0,0-7.58,3.94,9.46,9.46,0,0,1-4.35,2.08S316.19,285.9,316.18,285.74Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M316.12,288.45l0-.3a8.63,8.63,0,0,0,3.92-1.92,29.56,29.56,0,0,1,7.76-4,27.82,27.82,0,0,0,7.59-3.95,11.63,11.63,0,0,1,5.39-2.19l0,0.31,0,0.33a10.71,10.71,0,0,0-5,2,28.41,28.41,0,0,1-7.75,4,28.94,28.94,0,0,0-7.58,3.94,9.4,9.4,0,0,1-4.37,2.07Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M315.4,291.63l0.11-.29a8.83,8.83,0,0,0,4-1.93,29.55,29.55,0,0,1,7.76-4,31.11,31.11,0,0,0,6.94-3.53l0.67-.43a12.45,12.45,0,0,1,5.08-1.83l-0.16.34-0.16.33a10.43,10.43,0,0,0-4.43,1.69l-0.67.43a31.64,31.64,0,0,1-7.06,3.59,28.93,28.93,0,0,0-7.58,3.94,10.21,10.21,0,0,1-4.62,2.13Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M315.8,282.85l-0.06-.31a8.86,8.86,0,0,0,4-1.93,29.56,29.56,0,0,1,7.76-4,27.82,27.82,0,0,0,7.59-3.95,11.07,11.07,0,0,1,5.17-2.2l0.07,0.29,0.08,0.33a11,11,0,0,0-5,2.07,28.41,28.41,0,0,1-7.75,4,28.94,28.94,0,0,0-7.58,3.94,9.16,9.16,0,0,1-4.28,2.06S315.83,283,315.8,282.85Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M315.14,280.36L315,280a9,9,0,0,0,4-1.94,29.56,29.56,0,0,1,7.76-4,27.82,27.82,0,0,0,7.59-3.95,10.44,10.44,0,0,1,5-2.21l0.13,0.31,0.12,0.3a10.76,10.76,0,0,0-4.87,2.09,28.41,28.41,0,0,1-7.75,4,28.94,28.94,0,0,0-7.58,3.94,9.12,9.12,0,0,1-4.24,2.06Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M314.06,277.71c-0.08-.15-0.15-0.31-0.15-0.31a8.73,8.73,0,0,0,4-1.83,29.55,29.55,0,0,1,7.85-3.86,27.82,27.82,0,0,0,7.68-3.78c1.47-1,3.23-2.25,4.83-2.3l0.17,0.29,0.15,0.26c-1.46,0-3.5,1.34-4.8,2.25a28.39,28.39,0,0,1-7.84,3.86,28.94,28.94,0,0,0-7.67,3.77,8.49,8.49,0,0,1-4.09,1.91Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M312.67,275.28l-0.23-.35a8.84,8.84,0,0,0,4.1-1.82,28.58,28.58,0,0,1,7.7-3.8,26.92,26.92,0,0,0,7.53-3.71,10.41,10.41,0,0,1,5-2.09l0.21,0.25,0.22,0.27a11.59,11.59,0,0,0-5.08,2.06,27.49,27.49,0,0,1-7.69,3.79,28,28,0,0,0-7.52,3.7,8.57,8.57,0,0,1-4.09,1.91Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M310.81,272.93l-0.28-.29a8.66,8.66,0,0,0,4.07-1.69,28.65,28.65,0,0,1,7.9-3.55,27,27,0,0,0,7.73-3.47c1.44-.95,3.34-2,5-2l0.27,0.24,0.25,0.23c-1.19-.12-3.67,1.08-5.14,2a27.53,27.53,0,0,1-7.89,3.54,29.13,29.13,0,0,0-7.72,3.46,8.41,8.41,0,0,1-3.91,1.73Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M308.63,271l-0.35-.25a18.37,18.37,0,0,0,4.36-1.86,35.36,35.36,0,0,1,7.6-3l0.41-.13a34.07,34.07,0,0,0,7.76-3.27,12.84,12.84,0,0,1,4.88-1.91l0.35,0.21L334,261a15.68,15.68,0,0,0-5.28,2,34.62,34.62,0,0,1-7.89,3.33l-0.41.13a35,35,0,0,0-7.46,2.91,15.87,15.87,0,0,1-4.05,1.79Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
            <path d="M305.75,269.4l-0.58-.18a17.81,17.81,0,0,0,4.6-1.7,50.3,50.3,0,0,1,8.25-3.13,44,44,0,0,0,7-2.7l1-.45a17.64,17.64,0,0,1,4.8-1.63l0.51,0.12,0.51,0.16a24.43,24.43,0,0,0-5.56,1.91l-1,.45A44.54,44.54,0,0,1,318.2,265a50.11,50.11,0,0,0-8.14,3.09,16.71,16.71,0,0,1-3.82,1.54Z" transform="translate(-174.03 -118.24)" fill="#ccc" opacity="0.15"/>
          </g>
          <g>
            <path d="M319.68,292.07c3-3.19,3.54-9.33,1.22-15.45s-7-10.71-11-10.63l0.94-.33c3.59,0.13,8.18,4.25,10.65,10.74s1.65,12.7-.8,15.14Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ac)"/>
            <path d="M322.81,290.42c3.11-3.38,3.62-8.86,1.29-15s-6.87-10.67-10.78-10.66l0.91-.32c3.7,0.32,8,4.26,10.45,10.76s1.84,11.49-.68,14.58Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ad)"/>
            <path d="M326,288.75c2.88-3.41,3.23-8.75,1-14.61s-6.56-10.2-10.23-10.45l-0.22,0,0.44-.16,0.47-.16c3.62,0.38,7.77,4.41,10.11,10.58s1.79,11.55-.58,14.3Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ae)"/>
            <path d="M328.84,287.25c3-3.18,3.09-8.66.91-14.4s-6.37-10.05-10.22-10.25l0.84-.3c3.78,0.51,7.65,4.26,10,10.33s1.91,11.39-.55,14.13Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-af)"/>
            <path d="M331.43,285.88c3.1-3.4,3.26-8.38,1-14.21s-6.12-9.89-10-10.11l0.73-.26c3.59,0.12,7.5,3.94,9.86,10.15,2.3,6.06,1.9,10.81-.57,13.87Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ag)"/>
            <path d="M333.89,284.59c3-3.07,3.39-8.28,1.22-14s-6.36-9.91-10.25-9.84l0.75-.27c3.36-.15,7.77,3.84,10.07,9.9s1.56,11.48-.91,13.77Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ah)"/>
            <path d="M335.69,283.64c3.41-2.93,3.77-8.67,1.69-14.15s-6.62-9.57-10.43-9.5l0.58-.22c3.17-.29,8.19,3.59,10.43,9.49s1.73,11.77-1.7,14.06Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-ai)"/>
            <path d="M316.46,293.75c3.26-3.14,3.65-9.5,1.3-15.7s-7.24-10.48-11.34-10.85l1-.33c3.76,0.63,8.47,4.47,11,11s1.67,12.89-.76,15.34Z" transform="translate(-174.03 -118.24)" opacity="0.1" fill="url(#surgeontable-aj)"/>
          </g>
        </g>
        <g>
          <path d="M271.89,274.47s4.42,2.63,8.11.13c3-2,5.68-6.21,9.57-11.89,5.19-7.58,18.68-12.21,25.32-14,8.51-2.33,13.72-12,13-19.87a41.09,41.09,0,0,0-1.69-7.34,76.09,76.09,0,0,1-1-13.19c0.17-6.63,4.08-46.84,6.78-58.6,2.19-9.57,3.15-15,4.1-18.58,1.1-4.09-.12-6-1.71-7.73,0,0-3.89-4.88-26.22-5.12-10.76-.2-22.11.94-29.2,5.77,0,0-3.33,1.4-2.24,6.89,0,0,1.71,6.43,2.93,13.93s9.36,53.82,9,62.71-0.38,11.88-4.92,18.34-13.35,10.5-25.11,15.48c0,0-8.86,3.85-8.07,9.17A10,10,0,0,0,252,254C253.18,255.82,260.9,267.67,271.89,274.47Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ak)"/>
          <path d="M283.19,241.17c8,4.38,15.38,14,15.38,14a75.36,75.36,0,0,1,16.31-6.47,17,17,0,0,0,9.28-6.83,26.84,26.84,0,0,0-14.64-16.25c-9.34-4.24-23.43-3.4-23.43-3.4a34.44,34.44,0,0,1-2.31,3.7c-3.27,4.65-8.76,8-16,11.45A34.57,34.57,0,0,1,283.19,241.17Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-al)"/>
          <path d="M306.31,227.82a52.63,52.63,0,0,0,19.9-6.34,76.08,76.08,0,0,1-1-13.19c0-1.23.19-3.62,0.45-6.77a56.68,56.68,0,0,1-17.39,6.77,68.8,68.8,0,0,1-19.67,1.2c-0.3,7.44-.65,10.44-4.84,16.41C283.79,225.89,295.35,228.81,306.31,227.82Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-am)"/>
          <path d="M309.27,172.82a84.8,84.8,0,0,1-24.78,1c1.39,8.77,2.74,17.8,3.53,24.53,0,0,14.83-5.14,22.88-9.56,11.43-6.28,11.12-6.82,17.2-11.57,0.41-3.67.85-7.34,1.29-10.78A89.32,89.32,0,0,1,309.27,172.82Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-an)"/>
          <path d="M304.61,184a83.24,83.24,0,0,0,22.61,1.34c0.73-7,1.61-14.66,2.47-21.23,0,0-7.66,2.19-20.4,1.22s-27.07-5.5-27.07-5.5c0.77,4.56,1.9,11.56,2.72,16.75A79.21,79.21,0,0,0,304.61,184Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ao)"/>
          <path d="M296.91,163.29c10.45,3.72,30.31,4.6,32.5,2.94,0.9-7,1.82-13.12,2.63-16.66,0,0-9.71-1.63-23.57-3.62-27.37-3.94-31.74-15.19-31.74-15.19s1.71,6.43,2.93,13.93c0.27,1.66.88,5.21,1.66,9.84C281.32,154.53,286.46,159.57,296.91,163.29Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ap)"/>
          <path d="M305,132.85s2.46,2.92,3.07,15.4l23.71-10s0.31-6.75-4.32-8Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-aq)"/>
          <path d="M331.07,127.52c0,3.15-8.94,5.7-23.87,5.7s-25.28-2.55-25.28-5.7,10.35-5.7,25.28-5.7S331.07,124.37,331.07,127.52Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ar)"/>
          <path d="M332.05,149.56c0.65-2.86,1.27-5.69,1.84-8.39,0.54-2.54,1.06-5,1.54-7.2,0.25-1.17.49-2.07,0.73-3,0,0-3.78,12.08-54.39,20.83l-0.88.21c0.9,5.32,2.13,12.65,3.35,20.28,0,0,37.49-8.53,47-21.69Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-as)"/>
          <path d="M272.74,260.68s3.48-9.31,12.07-10.95l-18-8.08s-7.88,1.28-8.8,7.16Z" transform="translate(-174.03 -118.24)" fill="#fff"/>
          <path d="M276.12,268a11.54,11.54,0,0,0-2.74-9.47C266,250.06,261.77,248.07,257,247.8a3.45,3.45,0,0,0-2.49.95,3,3,0,0,0,.3,2.89c1,1.65,11.11,14.35,17.89,17.54C272.68,269.18,275.32,270.62,276.12,268Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-at)"/>
          <path d="M289.55,238.23c-3.76-9.18-5.77-12.33-5.77-12.33-3.29,4.68-8.83,8.09-16.11,11.51,0,0,8.88,13.82,12.15,22.45,4.07,10.74.16,14.75,0.16,14.75,3-2,5.68-6.21,9.57-11.89a22.8,22.8,0,0,1,6-5.68A140.68,140.68,0,0,0,289.55,238.23Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-au)"/>
          <path d="M277.76,247.54a29.8,29.8,0,0,1,11.81,15.16c3.79-5.54,12-9.5,18.74-11.94a42.68,42.68,0,0,0-16-15c-7.37-3.86-16.68-2.56-16.68-2.56-4.54,2.84-10.26,5.36-16.94,8.19A42.44,42.44,0,0,1,277.76,247.54Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-av)"/>
        </g>
        </a>
      </g>
      <g
      id="bluebowl"
      data-title="bluebowl"
      className={isThisActive("bluebowl")}
      onClick={handleClick}>
        <title>{getItem("bluebowl")}</title>
        <a xlinkHref="#bluebowl">
        <path d="M575.54,266l5.15,21.39c0.89,3.64,7.22,6.59,16.13,6.59s14.86-2.91,16.13-6.59L618.12,266H575.54Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-aw)"/>
        <path d="M618.55,266.51c0,5.37-9.72,9.73-21.72,9.73s-21.72-4.35-21.72-9.73l0.2,2.16c0.51,5.42,9.52,9.73,21.52,9.73s21.06-4.27,21.57-9.73Z" transform="translate(-174.03 -118.24)" fill="#1c66cc"/>
        <ellipse cx="422.8" cy="148.27" rx="21.72" ry="9.73" fill="#39f"/>
        <ellipse cx="422.8" cy="147.8" rx="19.14" ry="7.82" fill="url(#surgeontable-ax)"/>
        </a>
      </g>
      <g
      id="kidneydish"
      data-title="kidneydish"
      className={isThisActive("kidneydish")}
      onClick={handleClick}>
        <title>{getItem("kidneydish")}</title>
        <a xlinkHref="#kidneydish">
        <path d="M617.41,316.32c-2.08,8.81-8.94,16-19,17a35.19,35.19,0,0,1-19-3.18s-3.69-2-15-2h-2.5c-11.36,0-15,2-15,2a35.28,35.28,0,0,1-19,3.18,21,21,0,0,1-19-16.94l-2.78-9.87c0,8.35,9.12,15.66,21.75,16.94a35.24,35.24,0,0,0,19-3.18s3.69-2,15-2h2.5c11.36,0,15,2,15,2a35.24,35.24,0,0,0,19,3.18c12.66-1.29,21.74-8.63,21.75-17Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ay)"/>
        <path d="M564.42,318.28c11.36,0,15,2,15,2a35.24,35.24,0,0,0,19,3.18c14-1.42,23.56-10.19,21.46-19.59-1.06-4.74-4.89-8.74-10.22-11.35,0,0-17.75-8.71-46.09-8.71h-0.86c-28.34,0-46.09,8.71-46.09,8.71-5.33,2.62-9.16,6.61-10.22,11.35-2.1,9.4,7.51,18.18,21.46,19.59a35.24,35.24,0,0,0,19-3.18s3.69-2,15-2h2.5Z" transform="translate(-174.03 -118.24)" fill="#f2f2f2"/>
        <path d="M564.52,316.79c11.1,0,15.33,2,15.33,2a33.27,33.27,0,0,0,17.92,3.27c13.64-1.33,23-9.59,21-18.45-1-4.46-4.78-8.22-10-10.69,0,0-17.35-8.2-45-8.2h-0.84c-27.7,0-45,8.2-45,8.2-5.21,2.46-9,6.23-10,10.69-2.05,8.85,7.34,17.11,21,18.45a32.69,32.69,0,0,0,17.76-3.36s4.4-2,15.5-2h2.44Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-az)"/>
        <path d="M616.43,313.27s-4-18-52.92-18H563c-49.11,0-52.88,18-52.88,18l0,0c3.51,4.56,10.27,8,18.63,8.79a32.69,32.69,0,0,0,17.76-3.36s4.4-2,15.5-2h2.44c11.1,0,15.33,2,15.33,2a33.27,33.27,0,0,0,17.92,3.27c8.36-.82,15.12-4.24,18.63-8.8Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ba)"/>
        </a>
      </g>
      <g
      id="instruments"
      data-title="instruments"
      className={isThisActive("instruments")}
      onClick={handleClick}>
        <title>{getItem("instruments")}</title>
      <a xlinkHref="#instruments">
        <polygon points="304.65 205.54 196.93 205.54 206.66 167.83 294.92 167.83 304.65 205.54" fill="#ccc"/>
        <g>
          <path d="M468.95,286.25H380.68a0.18,0.18,0,0,1-.18-0.16l-3.23-33.38a0.18,0.18,0,0,1,.18-0.2h94.73a0.18,0.18,0,0,1,.18.2l-3.23,33.38A0.18,0.18,0,0,1,468.95,286.25Zm-88.1-.36h87.94l3.2-33H377.65Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M405.9,286.25h0a0.18,0.18,0,0,1-.18-0.18l0-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0,33.38A0.18,0.18,0,0,1,405.9,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M408,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.2-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.2,33.38A0.18,0.18,0,0,1,408,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M410.11,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.3-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.3,33.38A0.18,0.18,0,0,1,410.11,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M412.21,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.25-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.25,33.38A0.18,0.18,0,0,1,412.21,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M414.31,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.21-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.21,33.38A0.18,0.18,0,0,1,414.31,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M416.41,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.17-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.17,33.38A0.18,0.18,0,0,1,416.41,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M418.51,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.13-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.13,33.38A0.18,0.18,0,0,1,418.51,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M422.71,286.25h0a0.18,0.18,0,0,1-.18-0.18l0-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0,33.38A0.18,0.18,0,0,1,422.71,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M424.82,286.25a0.18,0.18,0,0,1-.18-0.18V252.69a0.18,0.18,0,0,1,.36,0v33.38A0.18,0.18,0,0,1,424.82,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M420.61,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.08-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.08,33.38A0.18,0.18,0,0,1,420.61,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M403.8,286.25a0.18,0.18,0,0,1-.18-0.18l-0.06-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18L404,286.07a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M401.7,286.25a0.18,0.18,0,0,1-.18-0.18l-0.36-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.36,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M399.6,286.25a0.18,0.18,0,0,1-.18-0.18l-0.66-33.38a0.18,0.18,0,0,1,.18-0.18,0.19,0.19,0,0,1,.18.18l0.66,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M397.5,286.25a0.18,0.18,0,0,1-.18-0.18l-1-33.38a0.18,0.18,0,0,1,.18-0.19,0.19,0.19,0,0,1,.19.18l1,33.38a0.18,0.18,0,0,1-.18.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M395.39,286.25a0.18,0.18,0,0,1-.18-0.17L394,252.7a0.18,0.18,0,0,1,.17-0.19,0.18,0.18,0,0,1,.19.17l1.26,33.38a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M393.29,286.25a0.18,0.18,0,0,1-.18-0.17l-1.56-33.38a0.18,0.18,0,0,1,.17-0.19,0.18,0.18,0,0,1,.19.17l1.56,33.38a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M391.19,286.25a0.18,0.18,0,0,1-.18-0.17l-1.86-33.38a0.18,0.18,0,0,1,.17-0.19,0.18,0.18,0,0,1,.19.17l1.86,33.38a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M389.09,286.25a0.18,0.18,0,0,1-.18-0.17l-2.16-33.38a0.18,0.18,0,1,1,.36,0l2.16,33.38a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M387,286.25a0.18,0.18,0,0,1-.18-0.17l-2.46-33.38a0.18,0.18,0,0,1,.17-0.19,0.19,0.19,0,0,1,.19.17l2.46,33.38a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M384.89,286.25a0.18,0.18,0,0,1-.18-0.17l-2.77-33.38a0.18,0.18,0,0,1,.17-0.2,0.18,0.18,0,0,1,.2.17l2.77,33.38a0.18,0.18,0,0,1-.17.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M382.78,286.25a0.18,0.18,0,0,1-.18-0.16l-3.07-33.38a0.18,0.18,0,1,1,.36,0L383,286.05a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M443.73,286.25a0.18,0.18,0,0,1-.18-0.18l0-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M441.63,286.25a0.18,0.18,0,0,1-.18-0.18l-0.2-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.2,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M439.53,286.25a0.18,0.18,0,0,1-.18-0.18l-0.3-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.3,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M437.43,286.25a0.18,0.18,0,0,1-.18-0.18L437,252.69a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.25,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M435.32,286.25a0.18,0.18,0,0,1-.18-0.18l-0.21-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.21,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M433.22,286.25a0.18,0.18,0,0,1-.18-0.18l-0.17-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.17,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M431.12,286.25a0.18,0.18,0,0,1-.18-0.18l-0.13-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.13,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M426.92,286.25a0.18,0.18,0,0,1-.18-0.18l0-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M429,286.25a0.18,0.18,0,0,1-.18-0.18l-0.08-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.08,33.38a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M445.83,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.06-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18L446,286.07A0.18,0.18,0,0,1,445.83,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M447.93,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.36-33.38a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.36,33.38A0.18,0.18,0,0,1,447.93,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M450,286.25h0a0.18,0.18,0,0,1-.18-0.18l0.66-33.38a0.19,0.19,0,0,1,.18-0.18,0.18,0.18,0,0,1,.18.18l-0.66,33.38A0.18,0.18,0,0,1,450,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M452.14,286.25h0a0.18,0.18,0,0,1-.18-0.19l1-33.38a0.18,0.18,0,0,1,.19-0.18,0.18,0.18,0,0,1,.18.19l-1,33.38A0.18,0.18,0,0,1,452.14,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M454.24,286.25h0a0.18,0.18,0,0,1-.17-0.19l1.26-33.38a0.18,0.18,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-1.26,33.38A0.18,0.18,0,0,1,454.24,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M456.34,286.25h0a0.18,0.18,0,0,1-.17-0.19l1.56-33.38a0.18,0.18,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-1.56,33.38A0.18,0.18,0,0,1,456.34,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M458.44,286.25h0a0.18,0.18,0,0,1-.17-0.19l1.86-33.38a0.18,0.18,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-1.86,33.38A0.18,0.18,0,0,1,458.44,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M460.54,286.25h0a0.18,0.18,0,0,1-.17-0.19l2.16-33.38a0.18,0.18,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-2.16,33.38A0.18,0.18,0,0,1,460.54,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M462.64,286.25h0a0.18,0.18,0,0,1-.17-0.19l2.46-33.38a0.19,0.19,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-2.46,33.38A0.18,0.18,0,0,1,462.64,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M464.75,286.25h0a0.18,0.18,0,0,1-.17-0.2l2.77-33.38a0.17,0.17,0,0,1,.2-0.17,0.18,0.18,0,0,1,.17.2l-2.77,33.38A0.18,0.18,0,0,1,464.75,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M466.85,286.25h0a0.18,0.18,0,0,1-.16-0.2l3.07-33.38a0.18,0.18,0,0,1,.36,0L467,286.08A0.18,0.18,0,0,1,466.85,286.25Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M469.14,284.23H380.49a0.18,0.18,0,1,1,0-.36h88.66A0.18,0.18,0,1,1,469.14,284.23Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M472,254.94H377.65a0.18,0.18,0,0,1,0-.36H472A0.18,0.18,0,0,1,472,254.94Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M471.78,257H377.85a0.18,0.18,0,1,1,0-.36h93.92A0.18,0.18,0,1,1,471.78,257Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M471.58,259.09H378.05a0.18,0.18,0,1,1,0-.36h93.52A0.18,0.18,0,1,1,471.58,259.09Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M471.38,261.17H378.26a0.18,0.18,0,0,1,0-.36h93.12A0.18,0.18,0,1,1,471.38,261.17Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M471.17,263.26H378.46a0.18,0.18,0,0,1,0-.36h92.71A0.18,0.18,0,1,1,471.17,263.26Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M471,265.36H378.66a0.18,0.18,0,1,1,0-.36H471A0.18,0.18,0,1,1,471,265.36Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470.77,267.46h-91.9a0.18,0.18,0,0,1,0-.36h91.9A0.18,0.18,0,1,1,470.77,267.46Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470.56,269.57h-91.5a0.18,0.18,0,1,1,0-.36h91.5A0.18,0.18,0,0,1,470.56,269.57Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470.36,271.65H379.27a0.18,0.18,0,0,1,0-.36h91.09A0.18,0.18,0,1,1,470.36,271.65Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470.16,273.76H379.47a0.18,0.18,0,1,1,0-.36h90.68A0.18,0.18,0,0,1,470.16,273.76Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470,275.86H379.68a0.18,0.18,0,0,1,0-.36H470A0.18,0.18,0,1,1,470,275.86Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M469.75,278H379.88a0.18,0.18,0,0,1,0-.36h89.87A0.18,0.18,0,1,1,469.75,278Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M469.55,280.08H380.09a0.18,0.18,0,0,1,0-.36h89.46A0.18,0.18,0,0,1,469.55,280.08Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M469.34,282.18H380.29a0.18,0.18,0,1,1,0-.36h89.05A0.18,0.18,0,0,1,469.34,282.18Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
        </g>
        <g>
          <path d="M371,324h0a0.18,0.18,0,0,1-.17-0.15l-5.26-34.5a0.18,0.18,0,0,1,0-.08l11.76-36.59a0.18,0.18,0,0,1,.19-0.12,0.18,0.18,0,0,1,.16.16L380.86,286a0.19,0.19,0,0,1,0,.06l-9.72,37.71A0.18,0.18,0,0,1,371,324Zm-5.08-34.66L371,322.87l9.5-36.82-3.14-32.48Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M380.06,288.67a0.18,0.18,0,0,1-.18-0.16l-3.58-32.77a0.18,0.18,0,0,1,.16-0.2,0.18,0.18,0,0,1,.2.16l3.58,32.77a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M368.65,289.46a0.18,0.18,0,0,1-.18-0.16l-0.87-6.49a0.18,0.18,0,1,1,.36,0l0.87,6.49a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M371.64,289.46a0.18,0.18,0,0,1-.18-0.16l-1.64-13.4a0.18,0.18,0,0,1,.16-0.2,0.18,0.18,0,0,1,.2.16l1.64,13.4a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M375.31,307.09a0.18,0.18,0,0,1-.18-0.16l-4.22-34.41a0.18,0.18,0,0,1,.36,0l4.22,34.41a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M374.64,289.46a0.18,0.18,0,0,1-.18-0.16L372,269.25a0.18,0.18,0,0,1,.16-0.2,0.18,0.18,0,0,1,.2.16l2.5,20.05a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M377.29,299.41a0.18,0.18,0,0,1-.18-0.16L373.2,265.4a0.18,0.18,0,0,1,.36,0l3.91,33.85a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M377.63,289.46a0.18,0.18,0,0,1-.18-0.16L374.3,262a0.18,0.18,0,1,1,.36,0l3.15,27.33a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M379.23,291.86a0.18,0.18,0,0,1-.18-0.16l-3.75-32.86a0.18,0.18,0,0,1,.16-0.2,0.18,0.18,0,0,1,.2.16l3.75,32.86a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M366.15,292.47H366.1a0.18,0.18,0,0,1-.12-0.23l11.54-37.1a0.18,0.18,0,1,1,.35.11l-11.54,37.1A0.18,0.18,0,0,1,366.15,292.47Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M366.6,295.38h-0.05a0.18,0.18,0,0,1-.12-0.23l11.46-36.29a0.18,0.18,0,0,1,.35.11l-11.46,36.29A0.18,0.18,0,0,1,366.6,295.38Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M367.13,298.84h-0.06a0.18,0.18,0,0,1-.12-0.23L378.29,263a0.18,0.18,0,1,1,.34.11L367.3,298.71A0.18,0.18,0,0,1,367.13,298.84Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M367.71,302.65h-0.05a0.18,0.18,0,0,1-.12-0.23l11.16-35.19a0.18,0.18,0,0,1,.35.11l-11.16,35.19A0.18,0.18,0,0,1,367.71,302.65Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M368.28,306.4h-0.05a0.18,0.18,0,0,1-.12-0.23l11-34.76a0.18,0.18,0,0,1,.35.11l-11,34.76A0.18,0.18,0,0,1,368.28,306.4Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M368.9,310.44h-0.05a0.18,0.18,0,0,1-.12-0.23l10.78-34.57a0.18,0.18,0,0,1,.35.11l-10.78,34.57A0.18,0.18,0,0,1,368.9,310.44Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M369.51,314.43h-0.05a0.18,0.18,0,0,1-.12-0.23l10.58-34.35a0.18,0.18,0,0,1,.35.11L369.68,314.3A0.18,0.18,0,0,1,369.51,314.43Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M370.19,318.88h-0.05a0.18,0.18,0,0,1-.12-0.22l10.24-35.27a0.18,0.18,0,0,1,.35.1l-10.24,35.27A0.18,0.18,0,0,1,370.19,318.88Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M373.5,314.1a0.18,0.18,0,0,1-.18-0.16l-4.63-34.53a0.18,0.18,0,1,1,.36,0l4.63,34.53a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M371.77,320.81a0.18,0.18,0,0,1-.18-0.15l-5-34.72a0.18,0.18,0,1,1,.36-0.05l5,34.72a0.18,0.18,0,0,1-.15.2h0Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
        </g>
        <g>
          <path d="M478.67,324a0.18,0.18,0,0,1-.18-0.14l-9.72-37.71a0.19,0.19,0,0,1,0-.06L472,252.67a0.18,0.18,0,0,1,.35,0l11.76,36.59a0.18,0.18,0,0,1,0,.08l-5.26,34.5a0.18,0.18,0,0,1-.17.15h0Zm-9.54-37.9,9.5,36.82,5.13-33.58-11.48-35.71Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M469.57,288.67h0a0.18,0.18,0,0,1-.16-0.2L473,255.7a0.18,0.18,0,0,1,.2-0.16,0.18,0.18,0,0,1,.16.2l-3.58,32.77A0.18,0.18,0,0,1,469.57,288.67Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M481,289.46h0a0.18,0.18,0,0,1-.16-0.2l0.87-6.49a0.18,0.18,0,1,1,.36,0l-0.87,6.49A0.18,0.18,0,0,1,481,289.46Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M478,289.46h0a0.18,0.18,0,0,1-.16-0.2l1.64-13.4a0.18,0.18,0,0,1,.36,0l-1.64,13.4A0.18,0.18,0,0,1,478,289.46Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M474.32,307.09h0a0.18,0.18,0,0,1-.16-0.2l4.22-34.41a0.18,0.18,0,0,1,.36,0l-4.22,34.41A0.18,0.18,0,0,1,474.32,307.09Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M475,289.46h0a0.18,0.18,0,0,1-.16-0.2l2.5-20.05a0.18,0.18,0,0,1,.2-0.16,0.18,0.18,0,0,1,.16.2l-2.5,20.05A0.18,0.18,0,0,1,475,289.46Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M472.34,299.41h0a0.18,0.18,0,0,1-.16-0.2l3.91-33.85a0.18,0.18,0,0,1,.36,0l-3.91,33.85A0.18,0.18,0,0,1,472.34,299.41Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M472,289.46h0a0.18,0.18,0,0,1-.16-0.2L475,261.92a0.18,0.18,0,0,1,.36,0l-3.15,27.33A0.18,0.18,0,0,1,472,289.46Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M470.4,291.86h0a0.18,0.18,0,0,1-.16-0.2L474,258.8a0.18,0.18,0,0,1,.36,0l-3.75,32.86A0.18,0.18,0,0,1,470.4,291.86Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M483.48,292.47a0.18,0.18,0,0,1-.17-0.13l-11.54-37.1a0.18,0.18,0,1,1,.35-0.11l11.54,37.1a0.18,0.18,0,0,1-.12.23h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M483,295.38a0.18,0.18,0,0,1-.17-0.13L471.41,259a0.18,0.18,0,1,1,.35-0.11l11.46,36.29a0.18,0.18,0,0,1-.12.23H483Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M482.51,298.84a0.18,0.18,0,0,1-.17-0.13L471,263.14a0.18,0.18,0,0,1,.34-0.11l11.33,35.57a0.18,0.18,0,0,1-.12.23h-0.06Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M481.93,302.65a0.18,0.18,0,0,1-.17-0.13l-11.16-35.19a0.18,0.18,0,1,1,.35-0.11l11.16,35.19a0.18,0.18,0,0,1-.12.23h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M481.35,306.4a0.18,0.18,0,0,1-.17-0.13l-11-34.76a0.18,0.18,0,1,1,.35-0.11l11,34.76a0.18,0.18,0,0,1-.12.23h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M480.74,310.44a0.18,0.18,0,0,1-.17-0.13l-10.78-34.57a0.18,0.18,0,0,1,.35-0.11l10.78,34.57a0.18,0.18,0,0,1-.12.23h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M480.13,314.43a0.18,0.18,0,0,1-.17-0.13l-10.58-34.35a0.18,0.18,0,0,1,.35-0.11L480.3,314.2a0.18,0.18,0,0,1-.12.23h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M479.45,318.88a0.18,0.18,0,0,1-.17-0.13L469,283.49a0.18,0.18,0,0,1,.35-0.1l10.24,35.27a0.18,0.18,0,0,1-.12.22h-0.05Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M476.13,314.1h0a0.18,0.18,0,0,1-.16-0.2l4.63-34.53a0.18,0.18,0,1,1,.36,0l-4.63,34.53A0.18,0.18,0,0,1,476.13,314.1Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
          <path d="M477.86,320.81h0a0.18,0.18,0,0,1-.15-0.2l5-34.72a0.18,0.18,0,0,1,.36.05l-5,34.72A0.18,0.18,0,0,1,477.86,320.81Z" transform="translate(-174.03 -118.24)" fill="#ccc"/>
        </g>
        <path d="M469.8,253.3a2.81,2.81,0,0,1,2.47,1.32h0L483,286.83a1.73,1.73,0,0,1-.19,1.53,1.93,1.93,0,0,1-1.6.65H368.43a1.93,1.93,0,0,1-1.6-.65,1.73,1.73,0,0,1-.19-1.53l10.71-32.19h0a2.81,2.81,0,0,1,2.47-1.32h90m0-1.81h-90c-1.86,0-3.73,1.14-4.19,2.58l-10.71,32.2a3.4,3.4,0,0,0,3.5,4.55H481.2a3.4,3.4,0,0,0,3.51-4.55L474,254.07c-0.46-1.43-2.33-2.58-4.19-2.58h0Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
        <g>
          <path d="M497.89,262.59c-2.27-1.17-5,.92-5,0.92a13,13,0,0,0-3.74,4.6c-2.67,5.48-.1,6.44-0.1,6.44a1.86,1.86,0,0,1-2-1c-0.45-1-.47-3.29.93-6.05a15.21,15.21,0,0,1,4.52-5.29C493.86,261.35,497,260.47,497.89,262.59Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M498.67,260.67a3.68,3.68,0,0,1,1.1,1.3,5.39,5.39,0,0,1,.57,3.78s-0.49,3.18-4.28,7.41c0,0-2.69,3.23-5.63,3.81a4.81,4.81,0,0,1-4.45-1.81,5.57,5.57,0,0,0,3.41.9c2.72-.5,4.61-2.79,5.53-3.78a18.37,18.37,0,0,0,4.25-7.56C499.41,263.78,499.91,261.58,498.67,260.67Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M487.06,242.49a1.63,1.63,0,0,1,.74.6,4.49,4.49,0,0,1,.91,1.72c0.22,1.11-.35,3.66-2.38,7l-19.68,31,16.9-12.52c0.78-.55,1.65-1,1.79,0,0,0-7.46,7.35-12.09,11.35-1.81,1.57-4.67,4.26-8.1,7-5.31,4.3-12.43,8.71-18.8,9.34l0-.17A48.28,48.28,0,0,0,457.87,290l8-10.21c0.36-.49,17.32-25.54,19.58-29.28,1.52-2.51,3.28-7.11,1.33-8.09Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bb)"/>
          <path d="M486.32,244.31c-0.45-1-2.16.37-2.16,0.37a16.52,16.52,0,0,0-4.2,5.71c-1.87,3.85.61,4.44,0.61,4.44s-3.82.81-2.23-3.43a15.92,15.92,0,0,1,5.4-7.6S485.7,242.32,486.32,244.31Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M471.47,278.36c4.31-3.57,13.21-11.24,16.91-14.54,0,0,5.2-4.5,8.16-4.07,0,0,4.65.27,3,5.61a19.32,19.32,0,0,1-4,6.82,18.23,18.23,0,0,1-4.62,3.83s-2.59,1.41-4.52-.37c0,0-1.41-.94-1-4.37a2.81,2.81,0,0,0,0-1s-0.32-.9-1.86.32S465,286.92,465,286.92s0.74-1.42.94-1.86a3.26,3.26,0,0,0,.41-1.66,1.06,1.06,0,0,1,.43-1Zm17.68-3.89c2.9-.25,6.68-5.56,6.68-5.56,3.66-5.36,1.4-7.09,1.4-7.09-1.68-1.37-4.93.86-4.93,0.86a14.8,14.8,0,0,0-4.24,5.08C485.17,274.83,489.15,274.47,489.15,274.47Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
          <path d="M465.6,284.66s-5.66,13.5-18.36,19.38l-0.14-.19s7.25-5.2,10.64-11.75l0.13-2.11s6.24-10.08,9-14.24c2.92-4.42,7.24-10.54,11.1-16.45,0,0,.88-1.32,1.43-2.16a0.56,0.56,0,0,0,.08-0.4,0.27,0.27,0,0,0-.26-0.2c-1-.18-3-1-2.29-4.48s3.75-7.89,6.07-9.09c0,0,3.4-2.15,4.82.12,0,0,1.45,2.08-2.4,8.51l-19.22,28.8a2.63,2.63,0,0,0-.49,1.28c0,0.75.2,1.43,0.19,1.91A3.67,3.67,0,0,1,465.6,284.66ZM484.43,250c2.73-4.76,1.55-6.12,1.55-6.12-0.66-1-2.37.24-2.37,0.24a15.13,15.13,0,0,0-4.34,5.72c-2.38,4.87.53,4.89,0.53,4.89C481.8,254.81,484.43,250,484.43,250Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
          <g>
            <path d="M461.55,288.51a0.49,0.49,0,0,1-.41,0,1.26,1.26,0,0,1-.13-1.66,2.06,2.06,0,0,1,.56-0.72Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
            <path d="M461.94,285.9a0.62,0.62,0,0,1,.46,0,1.26,1.26,0,0,1,.13,1.66,2.08,2.08,0,0,1-.58.73V285.9Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          </g>
        </g>
        <g>
          <path d="M450.16,286.49c0.6-2.8,2.61-10.56,3.34-15.52,0.43-2.89,1.21-8.26,1.21-8.26,1-6.31,1.84-9.4,1.84-9.4,1.43-5.37,3.86-9.52,5-11.87l3.7,1.31c-0.7,2.5-.65,6.68-2.08,12,0,0-.83,3.09-3.08,9.12,0,0-2,5.09-3.08,7.84-2.25,5.77-5.23,16.42-5.23,16.42s-0.05.27-.19,0.23l-0.87-.22L450,288c-0.14,0-.06-0.29-0.06-0.29l0.48-.38Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M448.23,286c0.89-2.12,4.13-9.81,6.51-14.61,1.31-2.63,3.75-7.51,3.75-7.51,2.77-5.8,3.88-8.8,3.88-8.8,1.9-5.23,1.86-8.81,2.56-11.31a41,41,0,0,1,2.11-5.8c1.48-2.95,1.95-4.24,1.95-4.24a2.45,2.45,0,0,0,.18-1.93,3.38,3.38,0,0,0-1.65-1.5,2.08,2.08,0,0,0-1.75.08,3,3,0,0,0-1,1.58,47.63,47.63,0,0,0-1.31,4.71,41,41,0,0,1-2.11,5.8c-1.07,2.37-3.41,5.08-5.31,10.31,0,0-1.08,3-2.68,9.24,0,0-1.27,5.31-2,8.17-1.45,6-4.75,16.55-4.75,16.55s-0.11.25,0,.29l0.43,0.16,0.43,0.16c0.13,0,.21-0.21.21-0.21l-0.14-.61Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bc)"/>
          <path d="M446.52,287l0.43,0.16c0.13,0,.21-0.21.21-0.21s4.25-10.2,7-15.74c1.31-2.63,3.75-7.51,3.75-7.51,2.77-5.8,3.88-8.8,3.88-8.8,1.9-5.23,1.86-8.81,2.56-11.31a41,41,0,0,1,2.11-5.8,47.63,47.63,0,0,0,2-4.45,3,3,0,0,0,.25-1.85,2.95,2.95,0,0,0-3.55-1.29,3,3,0,0,0-1,1.58,47.63,47.63,0,0,0-1.31,4.71,41,41,0,0,1-2.11,5.8c-1.07,2.37-3.41,5.08-5.31,10.31,0,0-1.08,3-2.68,9.24,0,0-1.27,5.31-2,8.17-1.45,6-4.75,16.55-4.75,16.55s-0.11.25,0,.29Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
          <g>
            <rect x="457.74" y="249.71" width="4.45" height="0.68" rx="0.34" ry="0.34" transform="translate(-60.76 -260.47) rotate(20)" fill="url(#surgeontable-bd)"/>
            <rect x="453.81" y="260.96" width="4.1" height="0.68" rx="0.33" ry="0.33" transform="translate(-57.17 -258.4) rotate(20)" fill="url(#surgeontable-be)"/>
            <rect x="454.14" y="259.83" width="4.27" height="0.68" rx="0.33" ry="0.33" transform="translate(-57.53 -258.6) rotate(20)" fill="url(#surgeontable-bf)"/>
            <rect x="454.5" y="258.71" width="4.36" height="0.68" rx="0.34" ry="0.34" transform="translate(-57.89 -258.81) rotate(20)" fill="url(#surgeontable-bg)"/>
            <rect x="454.87" y="257.59" width="4.45" height="0.68" rx="0.34" ry="0.34" transform="translate(-58.25 -259.02) rotate(20)" fill="url(#surgeontable-bh)"/>
            <rect x="455.24" y="256.46" width="4.53" height="0.68" rx="0.34" ry="0.34" transform="translate(-58.61 -259.23) rotate(20)" fill="url(#surgeontable-bi)"/>
            <rect x="455.56" y="255.34" width="4.7" height="0.68" rx="0.34" ry="0.34" transform="translate(-58.97 -259.43) rotate(20)" fill="url(#surgeontable-bj)"/>
            <rect x="455.93" y="254.21" width="4.79" height="0.68" rx="0.34" ry="0.34" transform="translate(-59.33 -259.64) rotate(20)" fill="url(#surgeontable-bk)"/>
            <rect x="456.29" y="253.09" width="4.87" height="0.68" rx="0.34" ry="0.34" transform="translate(-59.69 -259.85) rotate(20)" fill="url(#surgeontable-bl)"/>
            <rect x="456.7" y="251.96" width="4.87" height="0.68" rx="0.34" ry="0.34" transform="matrix(0.94, 0.34, -0.34, 0.94, -60.04, -260.06)" fill="url(#surgeontable-bm)"/>
            <rect x="457.2" y="250.84" width="4.7" height="0.68" rx="0.34" ry="0.34" transform="translate(-60.4 -260.27) rotate(20)" fill="url(#surgeontable-bn)"/>
          </g>
        </g>
        <g>
          <path d="M368.12,249.84a0.64,0.64,0,0,0,.91-0.06l1.6-2.83a0.82,0.82,0,0,1,.22-0.26,0.32,0.32,0,0,1,.16-0.08,1.24,1.24,0,0,0-.33,0,1.11,1.11,0,0,0-.87.49Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M365.19,231.58c3.06,5.84,7.61,6.85,7.61,6.85,2.5,4.13,26.66,37.26,27,37.8s8.89,10,8.89,10a80.84,80.84,0,0,0,11.42,11s4.57,3.9,13.11,2.41l0.2,1a20.31,20.31,0,0,1-15.22-.86c-9.09-4.83-12.08-10-17.6-14.7-3.71-3.14-33.57-31.82-33.57-31.82,0.19-1.71,1.68-1.44,3.6.31,0.78,0.71,27.61,26.79,27.61,26.79L399.4,279l-25-34.67a0.25,0.25,0,0,0-.41.07l-1.18,1.88a0.33,0.33,0,0,1-.49.1s-1.12-1.08-1.21-1.2a0.77,0.77,0,0,1,0-.65l1.35-2.33a1,1,0,0,0,.09-0.84l-0.7-1a2.36,2.36,0,0,0-.75-0.54,15,15,0,0,1-7.41-6.92,9.08,9.08,0,0,1-1.09-3.89,5.37,5.37,0,0,1,1.49-3.79C363.52,228.14,365.19,231.58,365.19,231.58Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bo)"/>
          <path d="M352,242.9c1.62-1.36,4.74.2,4.74,0.2a17.57,17.57,0,0,1,5.51,4.06c3.78,4.71,2.12,7.29,2.12,7.29a2.66,2.66,0,0,0,1.73-1.71c0.42-1.09.31-3.34-1.5-5.69a16.37,16.37,0,0,0-7-5.5C355.47,240.72,352.4,240.47,352,242.9Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M369.32,225.8a15.25,15.25,0,0,1,7.07,7.27c2.12,4.56-1.8,4.51-1.8,4.51s2-1-.41-4.94c-1.57-2.55-2.23-3.46-4.68-5a6.46,6.46,0,0,0-4.37-.91C366.28,224.23,369.32,225.8,369.32,225.8Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M350.35,246.2c0.86,3.24,3.75,6.17,5.65,7.81a9.22,9.22,0,0,0,6.24,2.39,7,7,0,0,0,5-2,6.23,6.23,0,0,1-5.65,4.3c-3.59.17-6.89-2.48-6.89-2.48a14.73,14.73,0,0,1-5.74-8.38,9,9,0,0,1,.39-5.36,5.43,5.43,0,0,1,2-2.5C350.19,241.09,349.69,243.74,350.35,246.2Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          <path d="M398.64,275.31l-16.89-23.37-9.09-12.53a1.49,1.49,0,0,0-.75-0.56,16.18,16.18,0,0,1-6.79-6,11.73,11.73,0,0,1-1.6-3.83c-0.61-2.86.62-3.84,0.62-3.84,1.79-1.83,5.06-.35,5.06-0.35,4.64,2,7.94,6.69,8.62,10.08,0.6,3-1.39,4.08-2.37,4.24-0.49.08-.31,0.4-0.31,0.4,7,9.81,21.59,30.06,25.85,36.17,3.15,4.51,7.26,9.81,7.26,9.81a3.69,3.69,0,0,1,.65,1.3,15.37,15.37,0,0,1,.14,2.61,87.65,87.65,0,0,0,10.46,9.74,15.09,15.09,0,0,0,12.85,2.7l0.27,1.11c-8.81,3.44-14.63-1.47-14.63-1.47A73.18,73.18,0,0,1,407.89,292l-6.29-7a4.76,4.76,0,0,1-1.08-3.33c0-1.19.45-2.64-.28-3.93Zm-24.58-38s4.17,0.64,1.07-5.29a12.33,12.33,0,0,0-5.64-5.67s-2.79-1.34-4,0c0,0-1.52,1.43,1.65,6.09C367.08,232.49,370.45,237,374.05,237.32Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
          <path d="M367.2,254.72a5.73,5.73,0,0,1-3.2,2.66c-3.61,1-7.36-1.68-7.36-1.68a15.28,15.28,0,0,1-4.54-4.54,13.6,13.6,0,0,1-2.41-6c-0.82-6.39,4.45-6,4.45-6,7.09,0.48,11.41,6.6,11.41,6.6,1.43,1.88,1.38,2.76,2.62,3.89,0,0,.44.45,0.86-0.21l1.52-2.63a0.48,0.48,0,0,1,.73-0.14,7,7,0,0,1,.7.71,0.67,0.67,0,0,1,0,.78c-0.24.53-.83,1.61-1.24,2.55a0.78,0.78,0,0,0,.07.83c4.62,4.38,15.64,15,22.39,21.58l6.49,6.08a1.48,1.48,0,0,1,.33,1.3,5.78,5.78,0,0,0,0,2L371.15,255.2a21.94,21.94,0,0,0-2.6-2.26,0.69,0.69,0,0,0-.9.65A4.88,4.88,0,0,1,367.2,254.72Zm-2.27-5.5s-1-3-4.93-5.81c0,0-4.59-3.24-7-1.57,0,0-3.07,2.24,1.4,8.59,0,0,3.51,4.8,7.92,4.67C362.3,255.11,367.32,255,364.93,249.22Z" transform="translate(-174.03 -118.24)" fill="#e6e6e6"/>
          <g>
            <path d="M405.22,286.35a0.57,0.57,0,0,0,.48,0c0.5-.25.56-1.12,0.15-1.95a2.42,2.42,0,0,0-.66-0.85Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
            <path d="M404.75,283.29a0.72,0.72,0,0,0-.54,0c-0.5.25-.56,1.12-0.15,1.95a2.43,2.43,0,0,0,.69.85v-2.83Z" transform="translate(-174.03 -118.24)" fill="#b3b3b3"/>
          </g>
        </g>
        <g>
          <path d="M478.67,324H371a0.18,0.18,0,0,1-.18-0.15l-5.27-34.5a0.18,0.18,0,0,1,.18-0.21H483.94a0.18,0.18,0,0,1,.18.21l-5.26,34.5A0.18,0.18,0,0,1,478.67,324Zm-107.56-.36h107.4l5.21-34.14H365.91Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bp)"/>
          <path d="M401.74,324a0.18,0.18,0,0,1-.18-0.18l-0.43-34.5a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.43,34.5a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bq)"/>
          <path d="M404.3,324a0.18,0.18,0,0,1-.18-0.18l-0.17-34.5a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.17,34.5a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-br)"/>
          <path d="M406.86,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,406.86,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bs)"/>
          <path d="M409.43,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,409.43,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bt)"/>
          <path d="M412,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,412,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bu)"/>
          <path d="M414.56,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,414.56,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bv)"/>
          <path d="M417.12,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,417.12,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bw)"/>
          <path d="M422.25,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,422.25,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bx)"/>
          <path d="M424.82,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,424.82,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-by)"/>
          <path d="M419.69,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,419.69,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-bz)"/>
          <path d="M399.17,324a0.18,0.18,0,0,1-.18-0.18l-0.6-34.5a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l0.6,34.5a0.18,0.18,0,0,1-.18.18h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ca)"/>
          <path d="M396.61,324a0.18,0.18,0,0,1-.18-0.18l-1-34.5a0.18,0.18,0,0,1,.18-0.19,0.19,0.19,0,0,1,.19.18l1,34.5a0.18,0.18,0,0,1-.18.19h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cb)"/>
          <path d="M394,324a0.18,0.18,0,0,1-.18-0.17l-1.45-34.5a0.18,0.18,0,0,1,.17-0.19h0a0.18,0.18,0,0,1,.18.17l1.45,34.5a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cc)"/>
          <path d="M391.48,324a0.18,0.18,0,0,1-.18-0.17l-1.88-34.5a0.18,0.18,0,0,1,.17-0.19,0.18,0.18,0,0,1,.19.17l1.88,34.5a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cd)"/>
          <path d="M388.91,324a0.18,0.18,0,0,1-.18-0.17l-2.31-34.5a0.18,0.18,0,0,1,.36,0l2.31,34.5a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ce)"/>
          <path d="M386.35,324a0.18,0.18,0,0,1-.18-0.17l-2.74-34.5a0.18,0.18,0,0,1,.36,0l2.74,34.5a0.18,0.18,0,0,1-.17.19h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cf)"/>
          <path d="M383.78,324a0.18,0.18,0,0,1-.18-0.16l-3.16-34.5a0.18,0.18,0,1,1,.36,0l3.16,34.5a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cg)"/>
          <path d="M381.22,324a0.18,0.18,0,0,1-.18-0.16l-3.59-34.5a0.18,0.18,0,0,1,.36,0l3.59,34.5a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ch)"/>
          <path d="M378.65,324a0.18,0.18,0,0,1-.18-0.16l-4-34.5a0.18,0.18,0,0,1,.36,0l4,34.5a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ci)"/>
          <path d="M376.09,324a0.18,0.18,0,0,1-.18-0.16l-4.45-34.5a0.18,0.18,0,0,1,.16-0.2,0.18,0.18,0,0,1,.2.16l4.45,34.5a0.18,0.18,0,0,1-.16.2h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cj)"/>
          <path d="M373.52,324a0.18,0.18,0,0,1-.18-0.16l-4.87-34.5a0.18,0.18,0,1,1,.36-0.05l4.87,34.5a0.18,0.18,0,0,1-.15.2h0Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ck)"/>
          <path d="M447.9,324h0a0.18,0.18,0,0,1-.18-0.18l0.43-34.5a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.43,34.5A0.18,0.18,0,0,1,447.9,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cl)"/>
          <path d="M445.33,324h0a0.18,0.18,0,0,1-.18-0.18l0.17-34.5a0.18,0.18,0,0,1,.18-0.18,0.18,0.18,0,0,1,.18.18l-0.17,34.5A0.18,0.18,0,0,1,445.33,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cm)"/>
          <path d="M442.77,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,442.77,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cn)"/>
          <path d="M440.2,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,440.2,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-co)"/>
          <path d="M437.64,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,437.64,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cp)"/>
          <path d="M435.08,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,435.08,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cq)"/>
          <path d="M432.51,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,432.51,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cr)"/>
          <path d="M427.38,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,427.38,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cs)"/>
          <path d="M429.95,324a0.18,0.18,0,0,1-.18-0.18v-34.5a0.18,0.18,0,1,1,.36,0v34.5A0.18,0.18,0,0,1,429.95,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ct)"/>
          <path d="M450.46,324h0a0.18,0.18,0,0,1-.18-0.18l0.6-34.5a0.18,0.18,0,0,1,.18-0.18h0a0.18,0.18,0,0,1,.18.18l-0.6,34.5A0.18,0.18,0,0,1,450.46,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cu)"/>
          <path d="M453,324h0a0.18,0.18,0,0,1-.18-0.19l1-34.5a0.19,0.19,0,0,1,.19-0.18,0.18,0.18,0,0,1,.18.19l-1,34.5A0.18,0.18,0,0,1,453,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cv)"/>
          <path d="M455.59,324h0a0.18,0.18,0,0,1-.17-0.19l1.45-34.5a0.18,0.18,0,0,1,.18-0.17h0a0.18,0.18,0,0,1,.17.19l-1.45,34.5A0.18,0.18,0,0,1,455.59,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cw)"/>
          <path d="M458.16,324h0a0.18,0.18,0,0,1-.17-0.19l1.88-34.5a0.18,0.18,0,0,1,.19-0.17,0.18,0.18,0,0,1,.17.19l-1.88,34.5A0.18,0.18,0,0,1,458.16,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cx)"/>
          <path d="M460.72,324h0a0.18,0.18,0,0,1-.17-0.19l2.31-34.5a0.18,0.18,0,0,1,.36,0l-2.31,34.5A0.18,0.18,0,0,1,460.72,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cy)"/>
          <path d="M463.29,324h0a0.18,0.18,0,0,1-.17-0.19l2.74-34.5a0.18,0.18,0,0,1,.36,0l-2.74,34.5A0.18,0.18,0,0,1,463.29,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-cz)"/>
          <path d="M465.85,324h0a0.18,0.18,0,0,1-.16-0.2l3.16-34.5a0.18,0.18,0,0,1,.2-0.16,0.18,0.18,0,0,1,.16.2L466,323.79A0.18,0.18,0,0,1,465.85,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-da)"/>
          <path d="M468.42,324h0a0.18,0.18,0,0,1-.16-0.2l3.59-34.5a0.18,0.18,0,0,1,.36,0l-3.59,34.5A0.18,0.18,0,0,1,468.42,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-db)"/>
          <path d="M471,324h0a0.18,0.18,0,0,1-.16-0.2l4-34.5a0.18,0.18,0,0,1,.36,0l-4,34.5A0.18,0.18,0,0,1,471,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dc)"/>
          <path d="M473.54,324h0a0.18,0.18,0,0,1-.16-0.2l4.45-34.5a0.18,0.18,0,0,1,.2-0.16,0.18,0.18,0,0,1,.16.2l-4.45,34.5A0.18,0.18,0,0,1,473.54,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dd)"/>
          <path d="M476.11,324h0a0.18,0.18,0,0,1-.15-0.2l4.87-34.5a0.18,0.18,0,0,1,.2-0.15,0.18,0.18,0,0,1,.15.2l-4.87,34.5A0.18,0.18,0,0,1,476.11,324Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-de)"/>
          <path d="M479,322H370.66a0.18,0.18,0,1,1,0-.36H479A0.18,0.18,0,1,1,479,322Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-df)"/>
          <path d="M483.48,292.47H366.15a0.18,0.18,0,1,1,0-.36H483.48A0.18,0.18,0,0,1,483.48,292.47Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dg)"/>
          <path d="M483.15,294.59H366.48a0.18,0.18,0,1,1,0-.36H483.15A0.18,0.18,0,0,1,483.15,294.59Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dh)"/>
          <path d="M482.83,296.71h-116a0.18,0.18,0,0,1,0-.36h116A0.18,0.18,0,1,1,482.83,296.71Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-di)"/>
          <path d="M482.51,298.84H367.13a0.18,0.18,0,0,1,0-.36H482.51A0.18,0.18,0,1,1,482.51,298.84Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dj)"/>
          <path d="M482.18,301H367.45a0.18,0.18,0,1,1,0-.36H482.18A0.18,0.18,0,0,1,482.18,301Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dk)"/>
          <path d="M481.86,303.08H367.77a0.18,0.18,0,1,1,0-.36H481.86A0.18,0.18,0,0,1,481.86,303.08Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dl)"/>
          <path d="M481.54,305.21H368.1a0.18,0.18,0,0,1,0-.36H481.54A0.18,0.18,0,0,1,481.54,305.21Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dm)"/>
          <path d="M481.21,307.34H368.42a0.18,0.18,0,1,1,0-.36H481.21A0.18,0.18,0,0,1,481.21,307.34Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dn)"/>
          <path d="M480.89,309.42H368.74a0.18,0.18,0,0,1,0-.36H480.89A0.18,0.18,0,0,1,480.89,309.42Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-do)"/>
          <path d="M480.57,311.54H369.06a0.18,0.18,0,1,1,0-.36h111.5A0.18,0.18,0,0,1,480.57,311.54Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dp)"/>
          <path d="M480.25,313.65H369.39a0.18,0.18,0,0,1,0-.36H480.25A0.18,0.18,0,0,1,480.25,313.65Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dq)"/>
          <path d="M479.92,315.76H369.71a0.18,0.18,0,0,1,0-.36H479.92A0.18,0.18,0,0,1,479.92,315.76Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dr)"/>
          <path d="M479.61,317.84H370a0.18,0.18,0,1,1,0-.36H479.61A0.18,0.18,0,1,1,479.61,317.84Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-ds)"/>
          <path d="M479.29,319.94H370.35a0.18,0.18,0,0,1,0-.36H479.29A0.18,0.18,0,0,1,479.29,319.94Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-dt)"/>
        </g>
        <path d="M483,286.83a1.73,1.73,0,0,1-.19,1.53,1.93,1.93,0,0,1-1.6.65H368.43a1.93,1.93,0,0,1-1.6-.65,1.73,1.73,0,0,1-.19-1.53l-1.72-.56a3.4,3.4,0,0,0,3.5,4.55H481.2a3.4,3.4,0,0,0,3.51-4.55Z" transform="translate(-174.03 -118.24)" fill="url(#surgeontable-du)"/>
      </a>
      </g>
    </svg>
  )
}
