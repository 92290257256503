import React, { useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"

import IconTools from "../../images/meo/MEO_Verktygsknapp_opt.svg"
import ActionButton from "./../ActionButtonSvg"

const meopink = "rgb(207, 60, 134)"
const meoblue = "rgba(0, 153, 175)"

const Wrapper = styled.div`
  width: auto;
  position: fixed;
  right: 0.8rem;
  bottom: 0.4rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 5rem;
  z-index: 15;

  &.active {
    background: rgba(0,0,0,0.3);
    z-index: 1001;
    max-height: 5rem;

    @media (max-height: 550px), (orientation: portrait) {
      z-index: 30;
    }
  }

  @media (orientation: portrait) {
    flex-flow: column-reverse;

    &.active {
      max-height: calc(100% - 5rem);
    }
  }
  @media (orientation: portrait) and (max-height: 580px) {
    &.active {max-height: calc(100% - 4.5rem);}
  }
`

const ToggleTools = styled.button`
  position:relative;
  width: 5rem;
  height: 5rem;
  display: block;
  padding: 0;
  border: 0;
  background: transparent;
  z-index:5;

  img {width:100%}

  @media (max-width: 375px) {
    width: 4rem;
    height: 4rem;
  }

  @media (max-height: 580px) {
    width: 4rem;
    height: 4rem;
  }
`

const ToolsWrapper = styled.div`
  bottom: 0;
  display: none;
  opacity: 0;
  max-height: 1px;
  max-width: 1px;
  border-radius: 5rem;
  align-self: flex-end;
  align-items: flex-end;
  flex-flow: row-reverse;

  &.show {
    display: flex;
    opacity: 1;
    max-height: 90vh;
    max-width: calc(85vw - 6rem);
    overflow: auto;

    @media (orientation: portrait) {
      overflow-x: hidden;
    }

    @media (orientation: landscape) {
      overflow-y: hidden;
    }
  }

  @media (orientation: portrait) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  :last-child::after {
    content: "";
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    flex-shrink: 0;
  }
`
const Group = styled.button`
  position: relative;
  width: 4rem;
  height: 4rem;
  overflow: visible;
  border-radius: 999px;
  border: none;
  background: rgba(0,0,0,0.5);
  padding: 0;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media (orientation: landscape) {
    min-width: 4rem;
  }

  @media (orientation: portrait){
    width: 4rem;
    height: 4rem;
  }

  @media (orientation: portrait) and (max-height: 580px) {
    width: 3rem;
    height: 3rem;
  }

  ${props => props.isActive && `
    & { background: ${meopink};}
  `}

  span.caption {
    position: absolute;
    width: 105%;
    left: 0;
    right: 0;
    text-align: center;
    font-family: ${props => props.theme.secondaryFont};
    font-weight: regular;
    text-shadow: 0 0 5px black;
    line-height: 1em;
    color: white;
    bottom: 0rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
    padding: 2px;
  }

  img {
    max-height: 110%;
    max-width: 110%
  }

  &#menu-floor,
  &#menu-wall {
    background: #ccc url(${props => props.bg});
    background-size: cover;

    ${props => props.isActive && `
      border: 2px solid ${meopink};
    `}
  }

  &:first-child {margin-left: 1rem;}
  &:last-child {margin-right: 0;}

  @media (orientation: portrait) {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    &:first-child {margin-bottom: 1rem; margin-left: 0;}
    &:last-child {margin-top: 0.5rem;}
  }
`

const Reset = styled(ActionButton)`
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  font-size: 0.8em;
  color: white;
  margin-top: 0.5rem;

  span.label {
    line-height: 1em;
    white-space: initial;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  svg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (orientation: landscape) {
    margin: auto 1rem auto 1rem;
  }
`

const btnColors1 = [
  ["rgb(245, 110, 178)", 0],
  ["rgb(207, 60, 134)", 0.5],
  ["rgb(148, 38, 93)", 1],
]


export default function MeoControlsFurnishing({active, activeItem, clickFn, setActiveTb, ...props}) {
  const intl = useIntl()
  const [store, updateStore] = useStore()

  const reset = () => {
    let r = {
      lamp: "Lampa_1.svg",
      optable: 1,
      chair: "Stol_1.svg",
      floor: null,
      wall: null,
    }
    updateStore(store => {
      store.meo.furniture = r
    })
  }

  const ariaLabel = item => {
    return intl.formatMessage({ id: "select" }) + " " + intl.formatMessage({ id: item })
  }

  return (
    <Wrapper className={active ? "active" : null} >
      <ToggleTools onClick={()=> !active ? setActiveTb("furnishing") : setActiveTb(null) } aria-expanded={!!active}>
        <span className="structural">{intl.formatMessage({ id: "meo_toolFurnishing_alt" })}</span>
        <img src={IconTools} alt="" />
      </ToggleTools>
      <ToolsWrapper className={active ? "show" : null} show={active}>
        <Group id="menu-optable" onClick={(e) => clickFn("optable", e)} isActive={activeItem == "operatingTable" ? "active" : null} aria-label={ariaLabel("meo_operatingTable")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_operatingTable" }) } } />
          <img src={require(`../../images/meo/furniture/MEO_Operationsbord_${store.meo.furniture.optable}.svg`)} alt="" />
        </Group>
        <Group id="menu-lamp" onClick={(e) => clickFn("lamp", e)} isActive={activeItem == "lamp" ? "active" : null} aria-label={ariaLabel("meo_lamp")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_lamp" }) } } />
          <img src={require(`../../images/meo/furniture/MEO_${store.meo.furniture.lamp}`)} alt="" />
        </Group>
        <Group id="menu-chair" onClick={(e) => clickFn("chair", e)} isActive={activeItem == "chair" ? "active" : null} aria-label={ariaLabel("meo_chair")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_chair" }) } } />
          <img src={require(`../../images/meo/furniture/MEO_${store.meo.furniture.chair}`)} alt="" />
        </Group>
        <Group id="menu-floor" onClick={(e) => clickFn("floor", e)} isActive={activeItem == "floor" ? "active" : null} aria-label={ariaLabel("meo_floor")} bg={ store.meo.furniture.floor ? require(`../../images/meo/walls/MEO_${store.meo.furniture.floor}`) : null}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_floor" }) } } />
        </Group>
        <Group id="menu-wall" onClick={(e) => clickFn("wall", e)} isActive={activeItem == "wallpaper" ? "active" : null} aria-label={ariaLabel("meo_wallpaper")} bg={ store.meo.furniture.wall ? require(`../../images/meo/walls/MEO_${store.meo.furniture.wall}`) : null}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_wallpaper" }) } } />
        </Group>
        <Reset basecolor={meopink} gradient={btnColors1} onClick={reset} className="reset"><span className="label">{intl.formatMessage({ id: "reset" })}</span></Reset>
      </ToolsWrapper>
    </Wrapper>
  )
}
