import React from "react"

export default function MeoOperationsbord3(props) {
  return(
    <svg viewBox="0 0 568.29 311.81">
      <defs>
        <radialGradient id="a" cx="428.06" cy="224.04" r="14.25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b8a266"/>
          <stop offset="0.29" stopColor="#bca668"/>
          <stop offset="0.59" stopColor="#c9b26d"/>
          <stop offset="0.9" stopColor="#ddc676"/>
          <stop offset="1" stopColor="#e6ce7a"/>
        </radialGradient>
        <linearGradient id="b" data-name="linear_brown2" x1="165.36" y1="177.94" x2="170.47" y2="158.31" gradientTransform="matrix(0.79, -0.61, 0.7, 0.91, 168.72, 170.29)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="0.7" stopColor="#d6bc76"/>
          <stop offset="1" stopColor="#b8a266"/>
        </linearGradient>
        <radialGradient id="c" cx="210.55" cy="233.22" r="15.45" xlinkHref="#a"/>
        <linearGradient id="d" x1="-12.71" y1="68.19" x2="-7.16" y2="46.9" xlinkHref="#b"/>
        <radialGradient id="e" cx="308.16" cy="287.56" r="17.76" xlinkHref="#a"/>
        <linearGradient id="f" x1="30.05" y1="158.19" x2="36.42" y2="133.72" xlinkHref="#b"/>
        <linearGradient id="g" x1="319.62" y1="249.97" x2="314.4" y2="190.25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="0.21" stopColor="#fdf2c8"/>
          <stop offset="0.43" stopColor="#f7eabb"/>
          <stop offset="0.65" stopColor="#eddca7"/>
          <stop offset="0.87" stopColor="#dfc98a"/>
          <stop offset="1" stopColor="#d6bc76"/>
        </linearGradient>
        <linearGradient id="h" x1="277.08" y1="221.62" x2="277.08" y2="73.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#d6bc76"/>
          <stop offset="1" stopColor="#b8a266"/>
        </linearGradient>
        <linearGradient id="i" x1="309.25" y1="221.62" x2="309.25" y2="79.31" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="1" stopColor="#d6bc76"/>
        </linearGradient>
        <linearGradient id="j" x1="176.66" y1="240.47" x2="267.4" y2="240.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b8a266"/>
          <stop offset="1" stopColor="#f6e3ab"/>
        </linearGradient>
        <linearGradient id="k" x1="309.46" y1="226.12" x2="371.02" y2="226.12" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#d6bc76"/>
          <stop offset="1" stopColor="#fff5cc"/>
        </linearGradient>
        <linearGradient id="l" x1="310.17" y1="186.59" x2="310.17" y2="168.72" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b8a266"/>
          <stop offset="1" stopColor="#ebd9a4"/>
        </linearGradient>
        <linearGradient id="m" x1="310.25" y1="184.75" x2="310.25" y2="170.57" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c0"/>
          <stop offset="1" stopColor="#e2eed8"/>
        </linearGradient>
        <linearGradient id="n" x1="318.14" y1="78.43" x2="318.14" y2="70.37" gradientTransform="translate(-133.32 78.34) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#3c3"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="o" x1="310.17" y1="208.56" x2="310.17" y2="190.68" xlinkHref="#l"/>
        <linearGradient id="p" x1="310.25" y1="206.71" x2="310.25" y2="192.54" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#fcc"/>
        </linearGradient>
        <linearGradient id="q" x1="318.14" y1="94.89" x2="318.14" y2="86.83" gradientTransform="translate(-133.32 78.34) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#b10000"/>
        </linearGradient>
        <radialGradient id="r" cx="-54.07" cy="235.88" r="12.16" gradientTransform="matrix(-1, 0, 0, 1, 216.96, 0)" xlinkHref="#i"/>
        <radialGradient id="s" cx="26.6" cy="194.36" r="9.57" gradientTransform="matrix(-1, 0, 0, 1, 216.96, 0)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="0.75" stopColor="#d6bc76"/>
          <stop offset="1" stopColor="#b8a266"/>
        </radialGradient>
        <radialGradient id="t" cx="-212.72" cy="187.63" r="10.27" gradientTransform="matrix(-1, 0, 0, 1, 216.96, 0)" xlinkHref="#i"/>
        <radialGradient id="u" data-name="radial_red" cx="467.64" cy="149.59" r="5.34" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#c00"/>
          <stop offset="1" stopColor="#7b0000"/>
        </radialGradient>
        <radialGradient id="v" cx="14.9" cy="135.54" r="4.86" xlinkHref="#u"/>
        <radialGradient id="w" cx="90.18" cy="185.32" r="5.79" xlinkHref="#u"/>
        <radialGradient id="x" cx="552.21" cy="125.2" r="5.38" xlinkHref="#u"/>
        <linearGradient id="y" x1="154.86" y1="156.26" x2="154.09" y2="147.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f6e3ab"/>
          <stop offset="1" stopColor="#fff5cc"/>
        </linearGradient>
        <linearGradient id="z" x1="214.46" y1="148.98" x2="214.46" y2="143.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="0.19" stopColor="#fcf2c8"/>
          <stop offset="0.39" stopColor="#f3e7bb"/>
          <stop offset="0.6" stopColor="#e5d7a7"/>
          <stop offset="0.8" stopColor="#d1bf8a"/>
          <stop offset="1" stopColor="#b8a266"/>
        </linearGradient>
        <linearGradient id="aa" x1="94.47" y1="159.61" x2="94.47" y2="154.61" xlinkHref="#z"/>
        <linearGradient id="ab" x1="289.32" y1="144.37" x2="288.54" y2="135.45" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f6e3ab"/>
          <stop offset="1" stopColor="#fff3d7"/>
        </linearGradient>
        <linearGradient id="ac" x1="228.88" y1="147.73" x2="228.88" y2="142.73" xlinkHref="#z"/>
        <linearGradient id="ad" x1="348.5" y1="136.97" x2="348.5" y2="131.97" xlinkHref="#z"/>
        <linearGradient id="ae" x1="412.48" y1="133.38" x2="411.7" y2="124.47" xlinkHref="#ab"/>
        <linearGradient id="af" x1="363.02" y1="135.8" x2="363.02" y2="130.81" xlinkHref="#z"/>
        <linearGradient id="ag" data-name="linear_brown" x1="460.25" y1="126.76" x2="461.95" y2="122.08" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff5cc"/>
          <stop offset="1" stopColor="#b8a266"/>
        </linearGradient>
        <linearGradient id="ah" x1="511.51" y1="116.79" x2="508.92" y2="107.09" xlinkHref="#ab"/>
        <linearGradient id="ai" x1="475.1" y1="124.44" x2="475.1" y2="119.5" xlinkHref="#z"/>
        <linearGradient id="aj" x1="545.76" y1="104.05" x2="545.76" y2="99.25" xlinkHref="#z"/>
        <linearGradient id="ak" x1="517.4" y1="121.27" x2="517.4" y2="42.13" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e2c466"/>
          <stop offset="0.5" stopColor="#fff5cc"/>
          <stop offset="1" stopColor="#e2c466"/>
        </linearGradient>
        <linearGradient id="al" data-name="linear_red" x1="503.96" y1="70.67" x2="518.03" y2="18.15" gradientTransform="translate(-3.94 -4.34) rotate(0.93)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#7b0000"/>
          <stop offset="1" stopColor="#c00"/>
        </linearGradient>
        <linearGradient id="am" x1="546.47" y1="59.37" x2="537.32" y2="46.73" gradientTransform="translate(-3.94 -4.34) rotate(0.93)" xlinkHref="#u"/>
        <linearGradient id="an" data-name="linear_blue" x1="383.68" y1="76.64" x2="383.68" y2="7.6" gradientTransform="matrix(1.08, 0.02, -0.02, 1, -52.88, -8.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#1b1464"/>
          <stop offset="1" stopColor="#29abe2"/>
        </linearGradient>
        <linearGradient id="ao" x1="512.47" y1="98.78" x2="527.81" y2="41.56" gradientTransform="matrix(-1, -0.08, -0.09, 1.03, 607.63, 44.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#008787"/>
          <stop offset="0.42" stopColor="#1aabab"/>
          <stop offset="0.78" stopColor="#2cc3c3"/>
          <stop offset="1" stopColor="#3cc"/>
        </linearGradient>
        <linearGradient id="ap" data-name="linear_turqoise" x1="557.62" y1="86.17" x2="547.88" y2="72.72" gradientTransform="matrix(-1, -0.08, -0.09, 1.03, 607.63, 44.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4aafb4"/>
          <stop offset="1" stopColor="#008787"/>
        </linearGradient>
        <linearGradient id="aq" data-name="linear_pink" x1="367.16" y1="97.16" x2="379.36" y2="51.61" gradientTransform="translate(46.35 72.24) rotate(-11.96)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#a22d65"/>
          <stop offset="0.3" stopColor="#c8448f"/>
          <stop offset="0.59" stopColor="#e657b0"/>
          <stop offset="0.84" stopColor="#f862c5"/>
          <stop offset="1" stopColor="#f6c"/>
        </linearGradient>
        <linearGradient id="ar" x1="397.28" y1="80.15" x2="406.5" y2="86.53" gradientTransform="translate(46.35 72.24) rotate(-11.96)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b02a69"/>
          <stop offset="0.05" stopColor="#b32f6d"/>
          <stop offset="0.45" stopColor="#c75088"/>
          <stop offset="0.78" stopColor="#d46598"/>
          <stop offset="1" stopColor="#d86c9e"/>
        </linearGradient>
        <linearGradient id="as" x1="560.62" y1="127.65" x2="578.51" y2="60.87" gradientTransform="matrix(-1.03, -0.36, -0.39, 0.98, 847.16, 162.69)" xlinkHref="#aq"/>
        <linearGradient id="at" x1="602.6" y1="103.94" x2="615.46" y2="112.83" gradientTransform="matrix(-1.03, -0.36, -0.39, 0.98, 847.16, 162.69)" xlinkHref="#ar"/>
        <linearGradient id="au" x1="374.99" y1="97.03" x2="379.47" y2="45.77" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#al"/>
        <linearGradient id="av" x1="403.25" y1="70.72" x2="412.58" y2="84.06" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#al"/>
        <linearGradient id="aw" x1="307.83" y1="97.9" x2="314.76" y2="18.7" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#ao"/>
        <linearGradient id="ax" x1="346.3" y1="60.03" x2="360.04" y2="66.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#008787"/>
          <stop offset="1" stopColor="#4aafb4"/>
        </linearGradient>
        <linearGradient id="ay" x1="555.9" y1="153.42" x2="570.24" y2="99.9" gradientTransform="matrix(-1.07, -0.27, -0.35, 0.92, 890.65, 111.57)" xlinkHref="#an"/>
        <linearGradient id="az" x1="590.8" y1="133.29" x2="601.64" y2="140.79" gradientTransform="matrix(-1.07, -0.27, -0.35, 0.92, 890.65, 111.57)" xlinkHref="#ar"/>
        <linearGradient id="ba" x1="170.79" y1="114.16" x2="170.79" y2="38.12" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#al"/>
        <radialGradient id="bb" cx="202.94" cy="86.45" r="16.63" xlinkHref="#s"/>
        <radialGradient id="bc" cx="125.87" cy="127.25" r="19.98" xlinkHref="#s"/>
        <radialGradient id="bd" cx="-249.93" cy="94.76" r="19.27" xlinkHref="#s"/>
      </defs>
      <g>
        <g>
          <g>
            <circle cx="425.73" cy="224.25" r="19.25"/>
            <ellipse cx="428.06" cy="224.04" rx="13.64" ry="14.84" fill="url(#a)"/>
            <path d="M432.21,225.89s0.37,1,0,1.24a1,1,0,0,1-1.09,0l-24.95-14.56,18.66-3.15Z" fill="url(#b)"/>
          </g>
          <g>
            <circle cx="208.02" cy="233.44" r="20.87"/>
            <ellipse cx="210.55" cy="233.22" rx="14.79" ry="16.09" fill="url(#c)"/>
            <path d="M215,235.22s0.4,1,0,1.35a1.08,1.08,0,0,1-1.18,0l-27.05-15.79L207,217.34Z" fill="url(#d)"/>
          </g>
          <g>
            <circle cx="305.25" cy="287.81" r="24"/>
            <ellipse cx="308.16" cy="287.56" rx="17" ry="18.5" fill="url(#e)"/>
            <path d="M313.33,289.86s0.46,1.2,0,1.55a1.24,1.24,0,0,1-1.36,0l-31.1-18.16,23.26-3.92Z" fill="url(#f)"/>
          </g>
          <polygon points="249.32 203.4 339.95 199.06 417.19 189.8 454.12 201.01 371.02 210.81 309.46 230.28 339.75 243.89 267.4 254.54 176.67 202.3 237.25 197.96 249.32 203.4" fill="url(#g)"/>
          <polygon points="290.08 221.62 264.08 209.98 264.08 73.44 290.08 81.67 290.08 221.62" fill="url(#h)"/>
          <polygon points="328.4 216.08 290.08 221.62 290.08 81.67 328.42 79.31 328.4 216.08" fill="url(#i)"/>
          <polygon points="339.74 265.56 267.39 278.63 267.4 254.54 339.74 243.89 339.74 265.56" fill="#e6ce7a"/>
          <polygon points="267.39 278.63 176.66 222.07 176.67 202.3 267.4 254.54 267.39 278.63" fill="url(#j)"/>
          <polygon points="454.12 217.73 371.02 229.91 371.02 210.81 454.12 201.01 454.12 217.73" fill="#e6ce7a"/>
          <polygon points="371.02 229.91 334.25 241.42 309.46 230.28 371.02 210.81 371.02 229.91" fill="url(#k)"/>
          <g>
            <g>
              <path d="M319,177.09a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,319,177.09Z" fill="url(#l)"/>
              <path d="M317.27,177.21a7.61,7.61,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,317.27,177.21Z" fill="url(#m)"/>
              <path d="M315.58,177.32a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,315.58,177.32Z" fill="url(#n)"/>
            </g>
            <path d="M310.37,173.62l3,2.8v1.44l-2.49-2.25v5.83l-1.1.12v-5.89l-2.64,2.64v-1.52Z" fill="#fff"/>
          </g>
          <g>
            <g>
              <path d="M319,199.05a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,319,199.05Z" fill="url(#o)"/>
              <path d="M317.27,199.18a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,317.27,199.18Z" fill="url(#p)"/>
              <path d="M315.58,199.28a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,315.58,199.28Z" fill="url(#q)"/>
            </g>
            <path d="M307.17,200.88v-1.52l2.64,2.31v-5.89l1.1-.11v5.85l2.49-2.6v1.45l-3,3.27Z" fill="#fff"/>
          </g>
        </g>
        <path d="M271.15,249.69a10.14,10.14,0,0,0,5-19A16.68,16.68,0,0,1,274,229.3a4.35,4.35,0,0,1-1.42-1.89c-1.3-3.59-.76-5.34-1.42-5.34h-0.25c-0.66,0-.12,1.75-1.42,5.34a4.35,4.35,0,0,1-1.42,1.89,16.64,16.64,0,0,1-2.14,1.42,10.14,10.14,0,0,0,5,19h0.25Z" fill="url(#r)"/>
        <path d="M190.45,205.22a8,8,0,0,0,3.92-14.92,13.09,13.09,0,0,1-1.69-1.11,3.43,3.43,0,0,1-1.12-1.49c-1-2.83-.6-4.2-1.12-4.2h-0.19c-0.52,0-.09,1.38-1.12,4.2a3.44,3.44,0,0,1-1.12,1.49,13,13,0,0,1-1.69,1.11,8,8,0,0,0,3.92,14.92h0.19Z" fill="url(#s)"/>
        <path d="M429.79,199.29a8.56,8.56,0,0,0,4.21-16,14.24,14.24,0,0,1-1.81-1.2,3.67,3.67,0,0,1-1.2-1.6c-1.1-3-.64-4.51-1.2-4.51h-0.21c-0.56,0-.1,1.48-1.2,4.51a3.65,3.65,0,0,1-1.2,1.6,14.17,14.17,0,0,1-1.81,1.2,8.56,8.56,0,0,0,4.21,16h0.21Z" fill="url(#t)"/>
      </g>
      <g className="bedTop">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M468.62,132.18v17.49a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V132.36Z" fill="#900"/>
                  <path d="M460.28,176a0.92,0.92,0,0,1-1,.77h0a0.92,0.92,0,0,1-.76-1.06l3-18.64c0.53-3.31,3.94-4.25,5.67-4.29h0a0.92,0.92,0,0,1,0,1.84h0c-0.14,0-3.48.13-3.9,2.74Z" fill="#900"/>
                  <path d="M462.95,177.1a0.92,0.92,0,0,1-1,.82h0a0.92,0.92,0,0,1-.82-1l2.11-19.67a5.4,5.4,0,0,1,3.92-4.45,0.92,0.92,0,0,1,.47,1.78,3.56,3.56,0,0,0-2.56,2.87Z" fill="#900"/>
                  <path d="M464.77,178.66h0a0.92,0.92,0,0,1-.87-1c0-.16.85-16.58,1.13-20.46,0.27-3.61,2-4.34,2.24-4.41a0.92,0.92,0,0,1,.65,1.72c-0.07,0-.89.56-1.06,2.82-0.28,3.86-1.12,20.25-1.13,20.42a0.92,0.92,0,0,1-.92.88h0Z" fill="#900"/>
                  <path d="M474.78,176a0.92,0.92,0,0,0,1,.77h0a0.92,0.92,0,0,0,.76-1.06l-3-18.64c-0.53-3.31-3.94-4.25-5.67-4.29h0a0.92,0.92,0,0,0,0,1.84h0c0.14,0,3.48.13,3.9,2.74Z" fill="#900"/>
                  <path d="M472.11,177.1a0.92,0.92,0,0,0,1,.82h0a0.92,0.92,0,0,0,.82-1l-2.11-19.67a5.4,5.4,0,0,0-3.92-4.45,0.92,0.92,0,0,0-.47,1.78,3.56,3.56,0,0,1,2.56,2.87Z" fill="#900"/>
                  <path d="M470.29,178.66h0a0.92,0.92,0,0,0,.87-1c0-.16-0.85-16.58-1.13-20.46-0.27-3.61-2-4.34-2.24-4.41a0.92,0.92,0,0,0-.65,1.72c0.07,0,.89.56,1.06,2.82,0.28,3.86,1.12,20.25,1.13,20.42a0.92,0.92,0,0,0,.92.88h0Z" fill="#900"/>
                  <path d="M467.53,179.25a0.92,0.92,0,0,1-.92-0.92V153.67a0.92,0.92,0,1,1,1.84,0v24.65a0.92,0.92,0,0,1-.92.92h0Z" fill="#900"/>
                  <circle cx="467.64" cy="149.59" r="5.34" fill="url(#u)"/>
                </g>
                <g>
                  <path d="M15.79,120.94v14.67a0.89,0.89,0,0,1-.89.89h0a0.89,0.89,0,0,1-.89-0.89V119.84Z" fill="#900"/>
                  <path d="M8.2,159.54a0.84,0.84,0,0,1-.92.7h0a0.84,0.84,0,0,1-.69-1l2.76-17c0.48-3,3.59-3.87,5.16-3.91h0a0.84,0.84,0,0,1,0,1.68h0c-0.13,0-3.16.11-3.54,2.49Z" fill="#900"/>
                  <path d="M10.63,160.57a0.84,0.84,0,0,1-.88.75h0A0.84,0.84,0,0,1,9,160.39l1.92-17.9a4.91,4.91,0,0,1,3.57-4,0.84,0.84,0,0,1,.43,1.62,3.24,3.24,0,0,0-2.33,2.61Z" fill="#900"/>
                  <path d="M12.29,162h0a0.84,0.84,0,0,1-.79-0.88c0-.15.77-15.08,1-18.62,0.24-3.29,1.86-3.95,2-4a0.84,0.84,0,0,1,.6,1.57c-0.06,0-.81.51-1,2.57-0.26,3.52-1,18.43-1,18.58a0.84,0.84,0,0,1-.84.8h0Z" fill="#900"/>
                  <path d="M21.4,159.54a0.84,0.84,0,0,0,.92.7h0a0.84,0.84,0,0,0,.7-1l-2.76-17c-0.48-3-3.59-3.87-5.16-3.91h0a0.84,0.84,0,0,0,0,1.68h0c0.13,0,3.16.11,3.54,2.49Z" fill="#900"/>
                  <path d="M19,160.57a0.84,0.84,0,0,0,.88.75h0a0.84,0.84,0,0,0,.75-0.92l-1.92-17.9a4.91,4.91,0,0,0-3.57-4,0.84,0.84,0,0,0-.43,1.62,3.24,3.24,0,0,1,2.33,2.61Z" fill="#900"/>
                  <path d="M17.31,162h0a0.84,0.84,0,0,0,.79-0.88c0-.15-0.77-15.08-1-18.62-0.24-3.29-1.86-3.95-2-4a0.84,0.84,0,0,0-.6,1.57c0.06,0,.81.51,1,2.57,0.26,3.52,1,18.43,1,18.58a0.84,0.84,0,0,0,.84.8h0Z" fill="#900"/>
                  <path d="M14.8,162.53a0.84,0.84,0,0,1-.84-0.84V139.26a0.84,0.84,0,0,1,1.68,0v22.44a0.84,0.84,0,0,1-.84.84h0Z" fill="#900"/>
                  <circle cx="14.9" cy="135.54" r="4.86" fill="url(#v)"/>
                </g>
                <g>
                  <path d="M91.24,166.44v19a1.06,1.06,0,0,1-1.06,1.06h0a1.06,1.06,0,0,1-1.06-1.06V166.63Z" fill="#900"/>
                  <path d="M82.19,213.92a1,1,0,0,1-1.09.84H81a1,1,0,0,1-.83-1.15l3.29-20.21c0.57-3.59,4.28-4.61,6.15-4.66h0a1,1,0,0,1,0,2h0c-0.15,0-3.77.14-4.22,3Z" fill="#900"/>
                  <path d="M85.09,215.15a1,1,0,0,1-1,.89H84a1,1,0,0,1-.89-1.1l2.28-21.33a5.86,5.86,0,0,1,4.25-4.83,1,1,0,0,1,.51,1.93,3.86,3.86,0,0,0-2.77,3.11Z" fill="#900"/>
                  <path d="M87.06,216.84H87a1,1,0,0,1-.95-1c0-.18.92-18,1.23-22.19,0.29-3.92,2.21-4.71,2.43-4.78a1,1,0,0,1,.71,1.87c-0.08,0-1,.61-1.15,3.06-0.31,4.19-1.22,22-1.23,22.14a1,1,0,0,1-1,.95h0Z" fill="#900"/>
                  <path d="M97.92,213.92a1,1,0,0,0,1.09.84h0.05a1,1,0,0,0,.83-1.15L96.6,193.39c-0.57-3.59-4.28-4.61-6.15-4.66h0a1,1,0,0,0,0,2h0c0.15,0,3.77.14,4.22,3Z" fill="#900"/>
                  <path d="M95,215.15a1,1,0,0,0,1,.89h0.05a1,1,0,0,0,.89-1.1l-2.28-21.33a5.86,5.86,0,0,0-4.25-4.83,1,1,0,0,0-.51,1.93,3.86,3.86,0,0,1,2.77,3.11Z" fill="#900"/>
                  <path d="M93,216.84H93.1a1,1,0,0,0,.95-1c0-.18-0.92-18-1.23-22.19-0.29-3.92-2.21-4.71-2.43-4.78a1,1,0,0,0-.71,1.87c0.08,0,1,.61,1.15,3.06,0.31,4.19,1.22,22,1.23,22.14a1,1,0,0,0,1,.95h0Z" fill="#900"/>
                  <path d="M90.06,217.48a1,1,0,0,1-1-1V189.75a1,1,0,1,1,2,0v26.74a1,1,0,0,1-1,1h0Z" fill="#900"/>
                  <circle cx="90.18" cy="185.32" r="5.79" fill="url(#w)"/>
                </g>
              </g>
              <g>
                <path d="M553.19,107.59v17.68a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V108.17Z" fill="#900"/>
                <path d="M544.78,151.79a0.93,0.93,0,0,1-1,.78h0a0.93,0.93,0,0,1-.77-1.07L546,132.7c0.53-3.34,4-4.29,5.72-4.33h0a0.93,0.93,0,0,1,0,1.86h0c-0.14,0-3.51.13-3.93,2.76Z" fill="#900"/>
                <path d="M547.47,152.93a0.93,0.93,0,0,1-1,.83h0a0.93,0.93,0,0,1-.83-1l2.13-19.83a5.44,5.44,0,0,1,4-4.49,0.93,0.93,0,0,1,.47,1.8,3.59,3.59,0,0,0-2.58,2.89Z" fill="#900"/>
                <path d="M549.31,154.51h0a0.93,0.93,0,0,1-.88-1c0-.17.85-16.72,1.14-20.63,0.27-3.65,2.06-4.38,2.26-4.45a0.93,0.93,0,0,1,.66,1.74c-0.07,0-.9.56-1.07,2.84-0.29,3.9-1.13,20.43-1.14,20.59a0.93,0.93,0,0,1-.93.88h0Z" fill="#900"/>
                <path d="M559.4,151.79a0.93,0.93,0,0,0,1,.78h0a0.93,0.93,0,0,0,.77-1.07l-3.06-18.8c-0.53-3.34-4-4.29-5.72-4.33h0a0.93,0.93,0,0,0,0,1.86h0c0.14,0,3.51.13,3.93,2.76Z" fill="#900"/>
                <path d="M556.71,152.93a0.93,0.93,0,0,0,1,.83h0a0.93,0.93,0,0,0,.83-1l-2.12-19.83a5.44,5.44,0,0,0-4-4.49,0.93,0.93,0,0,0-.47,1.8,3.59,3.59,0,0,1,2.58,2.89Z" fill="#900"/>
                <path d="M554.87,154.51h0a0.93,0.93,0,0,0,.88-1c0-.17-0.85-16.72-1.14-20.63-0.27-3.65-2.06-4.38-2.26-4.45a0.93,0.93,0,0,0-.66,1.74c0.07,0,.9.56,1.07,2.84,0.29,3.9,1.13,20.43,1.14,20.59a0.93,0.93,0,0,0,.93.88h0Z" fill="#900"/>
                <path d="M552.09,155.11a0.93,0.93,0,0,1-.93-0.93V129.31a0.93,0.93,0,0,1,1.86,0v24.86a0.93,0.93,0,0,1-.93.93h0Z" fill="#900"/>
                <circle cx="552.21" cy="125.2" r="5.38" fill="url(#x)"/>
              </g>
            </g>
            <g>
              <polygon points="14.02 119.84 14.01 105.3 89.12 149.22 468.56 115.74 553.19 91.75 553.19 107.59 468.62 132.18 89.12 166.63 14.02 119.84" fill="#e6ce7a"/>
              <g>
                <polygon points="219.81 141.69 89.14 153.21 89.14 162.01 219.81 150.5 219.81 141.69" fill="url(#y)"/>
                <ellipse cx="214.46" cy="146.48" rx="2.42" ry="2.5" fill="url(#z)"/>
                <ellipse cx="94.47" cy="157.11" rx="2.4" ry="2.5" fill="url(#aa)"/>
              </g>
              <g>
                <polygon points="353.98 129.72 223.88 141.29 223.88 150.09 353.98 138.53 353.98 129.72" fill="url(#ab)"/>
                <ellipse cx="228.88" cy="145.23" rx="2.43" ry="2.5" fill="url(#ac)"/>
                <ellipse cx="348.5" cy="134.47" rx="2.38" ry="2.5" fill="url(#ad)"/>
              </g>
              <g>
                <polygon points="466.35 119.69 357.83 129.35 357.83 138.16 466.35 128.49 466.35 119.69" fill="url(#ae)"/>
                <ellipse cx="363.02" cy="133.3" rx="2.43" ry="2.5" fill="url(#af)"/>
                <ellipse cx="461.1" cy="124.42" rx="2.37" ry="2.5" fill="url(#ag)"/>
              </g>
              <g>
                <polygon points="550 96.08 470.43 118.99 470.43 127.79 550 104.89 550 96.08" fill="url(#ah)"/>
                <ellipse cx="475.1" cy="121.97" rx="2.21" ry="2.47" fill="url(#ai)"/>
                <ellipse cx="545.76" cy="101.65" rx="2.18" ry="2.4" fill="url(#aj)"/>
              </g>
              <polygon points="14.01 105.3 14.01 83.84 403.5 58.58 457.62 47.35 553.14 70.1 553.19 91.75 468.56 115.74 89.12 149.22 14.01 105.3" fill="#00aa64"/>
              <polygon points="14.01 105.3 14.01 83.84 89.11 124.9 468.69 92.36 553.19 70.1 553.19 91.75 468.56 115.74 89.12 149.22 14.01 105.3" fill="#0c8953"/>
              <path d="M466.5,49.47l1.83-1.6a22.82,22.82,0,0,1,19.86-5.23c1.72,0.41,57.48,13.51,70.11,16.62,3.27,0.8,10.06,2.47,10,10-0.05,5.57-7.27,9.84-8.09,10.31l-73.35,41.34a2.49,2.49,0,0,1-1.23.33h0a2.5,2.5,0,0,1-1.24-4.67l73.35-41.34c2.23-1.28,5.55-4.08,5.57-6,0-2.49-.86-3.83-6.18-5.13C544.37,61,487.58,47.63,487,47.49l-0.1,0a17.74,17.74,0,0,0-14.36,3.44Z" fill="url(#ak)"/>
            </g>
          </g>
          <g>
            <path d="M514.37,27.44l-14.17-5.83s-15.33,1.08-26.83,8.08c-9.69,5.9-14.48,13.35-20.25,27.58,0,0,40.3,16.09,60.18,21.31,0,0,18.87-6.2,29.74-16.31,7.06-6.57,16.15-15.55,17.08-26.83Z" fill="url(#al)"/>
            <path d="M560.12,35.44c-0.94,11.28-10,20.26-17.08,26.83C532.17,72.39,513.3,78.59,513.3,78.59s9.49-16.48,20.49-27S549.62,39.61,560.12,35.44Z" fill="url(#am)"/>
          </g>
        </g>
        {props.children}
        <g>
          <g>
            <path d="M383.35,12.45c-18.28,2.34-45-7.59-71.09-5.71,0,0-20.09,24.21-20.83,63.36,0,0,52,8.48,98.69,5.83A278.67,278.67,0,0,0,427,71.59s-0.47-34.9-8.4-61.9C418.63,9.69,406.79,9.44,383.35,12.45Z" fill="url(#an)"/>
            <g>
              <path d="M82.15,52.13l20.3-6s7,1.79,16.88,10.6c9,8,14.45,17,18.93,33,0,0-43.87,13.2-65.21,16.77,0,0-19-8.66-29.3-20.72C37.09,78,27.37,65.28,27.62,53Z" fill="url(#ao)"/>
              <path d="M27.62,53c-0.25,12.29,9.47,25,16.14,32.79,10.25,12.06,29.3,20.73,29.3,20.73s-8.12-18.8-18.47-31.29S38.14,58.58,27.62,53Z" fill="url(#ap)"/>
            </g>
            <g>
              <path d="M413,43.28s-9.56-.09-16.79,10.17c-6.33,9-7.56,12.4-9.83,26.53,0,0,37.79,11.51,56.93,12.12a38.26,38.26,0,0,0,17.26-17c4.5-8.92,8.28-17.07,6.79-27.52Z" fill="url(#aq)"/>
              <path d="M467.32,47.55C468.81,58,465,66.14,460.53,75.06a38.26,38.26,0,0,1-17.26,17s3.46-13.61,8.11-22.81C455.61,60.92,460.51,54.67,467.32,47.55Z" fill="url(#ar)"/>
            </g>
            <g>
              <path d="M230.07,16.85L251.45,15a48.57,48.57,0,0,1,24,21.81C283,51.06,284,56.24,284.22,77c0,0-59.78,8.59-88.86,5.44a55.82,55.82,0,0,1-22.15-28.11c-4.74-13.77-8.58-26.27-3.91-41Z" fill="url(#as)"/>
              <path d="M169.31,13.38c-4.68,14.69-.83,27.19,3.91,41a55.82,55.82,0,0,0,22.15,28.11s-2.08-20.29-7-34.49C183.91,35.06,177.95,25.05,169.31,13.38Z" fill="url(#at)"/>
            </g>
            <g>
              <path d="M317.85,69.43s17.61,10.3,28.3,15.27c10.1,4.7,33.65,12.75,33.65,12.75s17.39-3.86,30.44-11.91c8-4.93,21.83-16.28,27.54-25.67l-49.05-9L366,44.59S344.78,42.58,317.85,69.43Z" fill="url(#au)"/>
              <path d="M437.79,59.86c-5.71,9.4-19.54,20.75-27.53,25.68-13.05,8.05-30.44,11.9-30.44,11.9S394.5,75,407.35,68.09,430.5,63,437.79,59.86Z" fill="url(#av)"/>
            </g>
            <g>
              <path d="M252.88,85.28s0.3-28.55,7.39-41.94,16-19.74,23-24.67l25.22,4.93h62.25s-4.31,33.09-13.89,49c-6.8,11.28-19.8,22.91-28.07,27.14l-23-6.7S278.29,85.28,252.88,85.28Z" fill="url(#aw)"/>
              <path d="M370.78,23.6s-4.31,33.09-13.89,49c-6.8,11.28-19.8,22.91-28.07,27.14,0,0,13-32.42,20.09-43.7S360.74,39.11,370.78,23.6Z" fill="url(#ax)"/>
            </g>
            <g>
              <path d="M266.43,51.94s9.19,5,17.2,17.13c7,10.62,10.16,13.47,11.09,29.54,0,0-50,6.63-75.25,5.74,0,0-13.82-6.17-20.2-20.58-4.62-10.41-8.4-19.88-4.91-31.5Z" fill="url(#ay)"/>
              <path d="M194.35,52.28c-3.49,11.63.29,21.1,4.91,31.5,6.39,14.4,20.2,20.58,20.2,20.58S216.9,88.76,212.13,78C207.78,68.28,202.26,60.85,194.35,52.28Z" fill="url(#az)"/>
            </g>
            <path d="M124.27,39.87s-13.53,27-13.53,71.38c0,0,39.64,5.45,82.67,1.45,11.61-1.08,26.69-2,37.44-4.87,0,0-.56-30.84-8.34-61.29C222.5,46.54,181,33.52,124.27,39.87Z" fill="url(#ba)"/>
          </g>
          <g>
            <path d="M14.19,105.32A13.86,13.86,0,0,0,21,79.4a22.92,22.92,0,0,1-2.93-1.94,5.94,5.94,0,0,1-1.94-2.59c-1.78-4.91-1-7.3-1.94-7.3H13.85c-0.9,0-.16,2.39-1.94,7.3A5.93,5.93,0,0,1,10,77.46,23,23,0,0,1,7,79.4a13.86,13.86,0,0,0,6.81,25.93h0.34Z" fill="url(#bb)"/>
            <path d="M91.29,149.94a16.66,16.66,0,0,0,8.19-31.16A27.5,27.5,0,0,1,96,116.45a7.13,7.13,0,0,1-2.33-3.11c-2.14-5.9-1.25-8.77-2.33-8.77H90.88c-1.09,0-.19,2.87-2.33,8.77a7.13,7.13,0,0,1-2.33,3.11,27.5,27.5,0,0,1-3.52,2.33,16.66,16.66,0,0,0,8.19,31.16h0.41Z" fill="url(#bc)"/>
            <path d="M467.08,116.64a16.06,16.06,0,0,0,7.89-30,26.57,26.57,0,0,1-3.39-2.25,6.88,6.88,0,0,1-2.25-3c-2.06-5.69-1.2-8.46-2.25-8.46h-0.39c-1,0-.19,2.77-2.25,8.46a6.88,6.88,0,0,1-2.25,3,26.57,26.57,0,0,1-3.39,2.25,16.06,16.06,0,0,0,7.89,30h0.39Z" fill="url(#bd)"/>
          </g>
        </g>
      </g>
    </svg>

)}
