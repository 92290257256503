import React from "react"

export default function MeoOperationsbord2(props) {
  return(
    <svg viewBox="0 0 566.93 311.81">
      <defs>
        <radialGradient id="a" cx="210.98" cy="230" r="20.11" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </radialGradient>
        <linearGradient id="b" data-name="linear_mirror_whitegray" x1="202.2" y1="243.91" x2="202.2" y2="204.31" gradientTransform="matrix(1, 0, 0, 1, 8.82, 5.86)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.25" stopColor="#fff"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="0.75" stopColor="#fff"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="c" x1="156.43" y1="-38.86" x2="156.43" y2="-74.53" gradientTransform="matrix(0, -1, 1.02, 0, 268.65, 386.42)" xlinkHref="#b"/>
        <linearGradient id="d" x1="121.39" y1="123.51" x2="121.39" y2="85.61" gradientTransform="matrix(0.69, -0.73, 0.73, 0.69, 51.36, 246)" xlinkHref="#b"/>
        <linearGradient id="e" x1="357.74" y1="271.89" x2="357.74" y2="233.37" gradientTransform="matrix(0.71, 0.7, -0.7, 0.71, 133.23, -200.36)" xlinkHref="#b"/>
        <radialGradient id="f" cx="426.22" cy="228.53" r="18.05" xlinkHref="#a"/>
        <linearGradient id="g" x1="334.29" y1="230.14" x2="334.29" y2="194.59" gradientTransform="translate(91.98 16.13) rotate(0.01)" xlinkHref="#b"/>
        <linearGradient id="h" x1="164.8" y1="89.17" x2="164.8" y2="57.15" gradientTransform="matrix(0, -1, 1.02, 0, 352.1, 393.46)" xlinkHref="#b"/>
        <linearGradient id="i" x1="225.2" y1="203.59" x2="225.2" y2="169.58" gradientTransform="matrix(0.37, -0.93, 0.93, 0.37, 168.66, 367.68)" xlinkHref="#b"/>
        <linearGradient id="j" x1="437.96" y1="163.5" x2="437.96" y2="128.92" gradientTransform="matrix(0.92, 0.39, -0.39, 0.92, 80.57, -77.25)" xlinkHref="#b"/>
        <radialGradient id="k" cx="306.48" cy="280.44" r="22.98" xlinkHref="#a"/>
        <linearGradient id="l" x1="294.82" y1="302.3" x2="294.82" y2="257.04" gradientTransform="translate(11.66 0.77)" xlinkHref="#b"/>
        <linearGradient id="m" x1="98.27" y1="53.28" x2="98.27" y2="12.52" gradientTransform="matrix(0, -1, 1.02, 0, 273.13, 378.75)" xlinkHref="#b"/>
        <linearGradient id="n" x1="150.45" y1="230.03" x2="150.45" y2="186.74" gradientTransform="matrix(0.69, -0.73, 0.73, 0.69, 51.36, 246)" xlinkHref="#b"/>
        <linearGradient id="o" x1="461.13" y1="243.28" x2="461.13" y2="199.27" gradientTransform="matrix(0.71, 0.7, -0.7, 0.71, 133.23, -200.36)" xlinkHref="#b"/>
        <linearGradient id="p" x1="313.37" y1="254.18" x2="313.37" y2="189.45" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="q" x1="275.06" y1="221.26" x2="275.06" y2="73.08" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="r" x1="307.25" y1="221.26" x2="307.25" y2="78.96" xlinkHref="#p"/>
        <linearGradient id="s" data-name="linear_gray1" x1="174.63" y1="240.11" x2="265.36" y2="240.11" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="t" x1="307.46" y1="225.76" x2="368.97" y2="225.76" xlinkHref="#s"/>
        <linearGradient id="u" data-name="linear_gray2" x1="308.17" y1="186.23" x2="308.17" y2="168.36" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#999"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="v" x1="308.25" y1="184.39" x2="308.25" y2="170.22" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c0"/>
          <stop offset="1" stopColor="#e2eed8"/>
        </linearGradient>
        <linearGradient id="w" x1="316.13" y1="185.07" x2="316.13" y2="177.02" gradientTransform="translate(-132.53 -64.37) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#3c3"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="x" x1="308.17" y1="208.2" x2="308.17" y2="190.32" xlinkHref="#u"/>
        <linearGradient id="y" x1="308.25" y1="206.35" x2="308.25" y2="192.18" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#fcc"/>
        </linearGradient>
        <linearGradient id="z" x1="316.13" y1="201.53" x2="316.13" y2="193.47" gradientTransform="translate(-132.53 -64.37) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#b10000"/>
        </linearGradient>
        <linearGradient id="aa" x1="515.39" y1="120.91" x2="515.39" y2="41.77" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="ab" x1="202.69" y1="98.64" x2="200.84" y2="47.01" gradientTransform="matrix(0.85, 0.53, -0.53, 0.85, -59.21, -71.12)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#be0000"/>
          <stop offset="1" stopColor="red"/>
        </linearGradient>
        <linearGradient id="ac" x1="62.8" y1="114.51" x2="82.86" y2="79.77" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.2" stopColor="#e1e1e1"/>
          <stop offset="0.43" stopColor="#f2f2f2"/>
          <stop offset="0.68" stopColor="#fcfcfc"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="ad" x1="341.45" y1="26.57" x2="428.13" y2="26.57" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.02" stopColor="#b6b6b6"/>
          <stop offset="0.21" stopColor="#dedede"/>
          <stop offset="0.37" stopColor="#f6f6f6"/>
          <stop offset="0.47" stopColor="#fff"/>
          <stop offset="0.56" stopColor="#f6f6f6"/>
          <stop offset="0.7" stopColor="#dedede"/>
          <stop offset="0.88" stopColor="#b6b6b6"/>
          <stop offset="1" stopColor="#999"/>
        </linearGradient>
        <linearGradient id="ae" x1="359.42" y1="31.55" x2="403.62" y2="31.55" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#754c24"/>
          <stop offset="0.5" stopColor="#c69c6d"/>
          <stop offset="1" stopColor="#754c24"/>
        </linearGradient>
        <radialGradient id="af" cx="116.94" cy="80.3" r="11.82" gradientTransform="translate(5.94 2.7)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="1" stopColor="#ffe199"/>
        </radialGradient>
        <linearGradient id="ag" data-name="linear_mirror_gray" x1="128.73" y1="93.64" x2="129.87" y2="68.04" gradientTransform="matrix(1, -0.04, 0.04, 1, 2.49, 7.97)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.02" stopColor="#b6b6b6"/>
          <stop offset="0.23" stopColor="#dedede"/>
          <stop offset="0.4" stopColor="#f6f6f6"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.64" stopColor="#f6f6f6"/>
          <stop offset="0.86" stopColor="#dedede"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="ah" x1="118.59" y1="94.78" x2="127.17" y2="71.22" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <radialGradient id="ai" cx="59.43" cy="51.06" r="9.91" gradientTransform="translate(4.7 5.29)" xlinkHref="#af"/>
        <linearGradient id="aj" x1="69.32" y1="62.25" x2="70.27" y2="40.78" xlinkHref="#ag"/>
        <linearGradient id="ak" x1="60.54" y1="66.22" x2="67.73" y2="46.46" xlinkHref="#ah"/>
      </defs>
      <g>
        <g>
          <g>
            <circle cx="207.69" cy="230.29" r="27.17"/>
            <ellipse cx="210.98" cy="230" rx="19.25" ry="20.94" fill="url(#a)"/>
          </g>
          <rect x="209.62" y="210.2" width="2.7" height="39.6" transform="translate(-12.24 11.86) rotate(-3.13)" fill="url(#b)"/>
          <rect x="192.86" y="228.7" width="36.23" height="2.63" transform="translate(-18.19 18.12) rotate(-4.71)" fill="url(#c)"/>
          <polygon points="226.03 241.05 224.44 243.17 195.71 218.76 197.3 216.64 226.03 241.05" fill="url(#d)"/>
          <polygon points="199.16 245.29 197.29 243.45 222.78 214.72 224.65 216.57 199.16 245.29" fill="url(#e)"/>
          <path d="M211,211.69c9.16,0,16.62,8.22,16.62,18.31s-7.46,18.32-16.62,18.32-16.62-8.22-16.62-18.32,7.46-18.31,16.62-18.31m0-2.63c-10.63,0-19.25,9.38-19.25,20.94s8.62,20.94,19.25,20.94,19.25-9.37,19.25-20.94-8.62-20.94-19.25-20.94h0Z" fill="#e6e6e6"/>
          <circle cx="210.99" cy="230.01" r="4.3"/>
        </g>
        <g>
          <g>
            <circle cx="423.26" cy="228.79" r="24.39"/>
            <ellipse cx="426.22" cy="228.53" rx="17.28" ry="18.8" fill="url(#f)"/>
          </g>
          <rect x="424.99" y="210.78" width="2.43" height="35.55" transform="translate(-56.49 198.72) rotate(-24.67)" fill="url(#g)"/>
          <rect x="409.95" y="227.39" width="32.52" height="2.36" transform="translate(-57.12 211.7) rotate(-26.21)" fill="url(#h)"/>
          <polygon points="442.42 232.81 441.8 235.11 409.76 224.2 410.39 221.9 442.42 232.81" fill="url(#i)"/>
          <polygon points="421.38 245.22 419.21 244.29 431.04 211.91 433.21 212.83 421.38 245.22" fill="url(#j)"/>
          <path d="M426.21,212.09c8.23,0,14.92,7.38,14.92,16.44S434.44,245,426.21,245s-14.92-7.38-14.92-16.44,6.69-16.44,14.92-16.44m0-2.36c-9.54,0-17.28,8.42-17.28,18.8s7.74,18.8,17.28,18.8,17.28-8.41,17.28-18.8-7.73-18.8-17.28-18.8h0Z" fill="#e6e6e6"/>
          <circle cx="426.22" cy="228.55" r="3.86" transform="translate(-54.14 172.39) rotate(-21.53)"/>
        </g>
        <g>
          <g>
            <path d="M271.64,280.77a31.06,31.06,0,1,1,31.08,31A31.08,31.08,0,0,1,271.64,280.77Z"/>
            <ellipse cx="306.48" cy="280.44" rx="21.99" ry="23.93" fill="url(#k)"/>
          </g>
          <rect x="304.93" y="257.82" width="3.09" height="45.25" transform="translate(-14.81 17.1) rotate(-3.12)" fill="url(#l)"/>
          <rect x="285.78" y="278.95" width="41.4" height="3" transform="translate(-21.97 26.08) rotate(-4.7)" fill="url(#m)"/>
          <polygon points="323.68 293.06 321.87 295.49 289.04 267.6 290.85 265.17 323.68 293.06" fill="url(#n)"/>
          <polygon points="292.98 297.92 290.84 295.81 319.97 262.99 322.11 265.09 292.98 297.92" fill="url(#o)"/>
          <path d="M306.48,259.52c10.47,0,19,9.39,19,20.93s-8.52,20.93-19,20.93-19-9.39-19-20.93,8.52-20.93,19-20.93m0-3c-12.15,0-22,10.71-22,23.93s9.85,23.93,22,23.93,22-10.71,22-23.93-9.84-23.93-22-23.93h0Z" fill="#e6e6e6"/>
          <circle cx="306.49" cy="280.45" r="4.92"/>
        </g>
        <polygon points="247.28 203.04 337.95 198.71 415.18 189.45 452.12 200.66 368.97 210.46 307.46 229.93 337.76 243.52 265.36 254.18 174.63 201.95 235.21 197.6 247.28 203.04" fill="url(#p)"/>
        <polygon points="288.07 221.26 262.04 209.63 262.04 73.08 288.07 81.32 288.07 221.26" fill="url(#q)"/>
        <polygon points="326.4 215.72 288.07 221.26 288.08 81.32 326.42 78.96 326.4 215.72" fill="url(#r)"/>
        <polygon points="337.74 265.21 265.36 278.27 265.36 254.18 337.74 243.53 337.74 265.21" fill="#ccc"/>
        <polygon points="265.36 278.27 174.63 221.72 174.63 201.95 265.36 254.18 265.36 278.27" fill="url(#s)"/>
        <polygon points="452.12 217.38 368.97 229.57 368.97 210.46 452.12 200.66 452.12 217.38" fill="#ccc"/>
        <polygon points="368.97 229.57 332.26 241.06 307.46 229.93 368.97 210.46 368.97 229.57" fill="url(#t)"/>
        <g>
          <g>
            <path d="M317,176.73a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,317,176.73Z" fill="url(#u)"/>
            <path d="M315.26,176.86a7.61,7.61,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.26,176.86Z" fill="url(#v)"/>
            <path d="M313.58,177a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,313.58,177Z" fill="url(#w)"/>
          </g>
          <path d="M308.37,173.26l3,2.8v1.44l-2.49-2.25v5.83l-1.1.12V175.3l-2.64,2.64v-1.52Z" fill="#fff"/>
        </g>
        <g>
          <g>
            <path d="M317,198.69a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,317,198.69Z" fill="url(#x)"/>
            <path d="M315.26,198.82a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.26,198.82Z" fill="url(#y)"/>
            <path d="M313.58,198.93a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,313.58,198.93Z" fill="url(#z)"/>
          </g>
          <path d="M305.16,200.53V199l2.64,2.31v-5.89l1.1-.11v5.85l2.49-2.6V200l-3,3.27Z" fill="#fff"/>
        </g>
      </g>
      <g className="bedTop">
        <g>
          <polygon points="11.98 119.48 11.98 104.94 87.08 148.88 466.58 115.35 551.19 91.4 551.19 107.24 466.58 131.82 87.08 166.28 11.98 119.48" fill="#ccc"/>
          <polygon points="219.92 141.76 87.06 153.67 87.06 161.26 219.92 149.35 219.92 141.76" fill="#fff"/>
          <polygon points="354 129.75 219.92 141.74 219.92 149.34 354 137.36 354 129.75"/>
          <polygon points="466.58 119.7 354 129.74 354 137.36 466.58 127.31 466.58 119.7" fill="#fff"/>
          <polygon points="551.19 95.74 466.58 119.7 466.58 127.31 551.19 102.89 551.19 95.74"/>
          <polygon points="429.95 52.7 466.58 115.35 551.19 91.4 551.19 69.74 455.62 47 429.95 52.7" fill="#00aa64"/>
          <path d="M466.58,92l84.61-22.27V91.4l-84.61,24L87.05,148.85s30.36-58.48,105-65.15c0,0,35.5-5.67,102.2,4,0,0,50.33,4.67,97.67-9.33,0,0,27.33-11,38-25.67Z" fill="#0c8953"/>
          <path d="M464.5,49.11l1.83-1.6a22.82,22.82,0,0,1,19.86-5.23c1.72,0.41,57.48,13.51,70.11,16.62,3.27,0.8,10.06,2.47,10,10-0.05,5.57-7.27,9.84-8.09,10.31l-73.35,41.34a2.49,2.49,0,0,1-1.23.33h0a2.5,2.5,0,0,1-1.24-4.67L555.72,74.9c2.23-1.28,5.55-4.08,5.57-6,0-2.49-.86-3.83-6.18-5.13-12.74-3.13-69.53-16.48-70.1-16.61l-0.1,0a17.72,17.72,0,0,0-14.34,3.44Z" fill="url(#aa)"/>
          <polygon points="87.06 153.67 11.98 109.28 11.98 115.02 87.06 161.28 87.06 153.67"/>
        </g>
        {props.children}
        <g>
          <path d="M430,52.7L338,27.37C327,36.37,296.29,46,296.29,46c-37.37,11.33-88,.67-88,0.67-45-9.67-95.33-3-95.33-3C38.42,52.7,12,104.94,12,104.94l75.1,43.93,379.5-33.52Z" fill="#00aa64"/>
          <path d="M466.58,92v23.35L87.08,148.87s30.33-58.51,105-65.18c0,0,35.5-5.67,102.2,4,0,0,50.33,4.67,97.67-9.33,0,0,27.33-11,38-25.67Z" fill="#0c8953"/>
          <g>
            <path d="M50.77,86.05c8.65-11.46,25.7-15.56,38.07-9.15s15.38,20.9,6.73,32.36-25.7,15.56-38.07,9.15S42.11,97.52,50.77,86.05Z" fill="url(#ab)"/>
            <path d="M55.44,88.13C62.15,79.23,75.39,76,85,81s11.94,16.23,5.23,25.13-20,12.08-29.56,7.1S48.72,97,55.44,88.13Z" fill="url(#ac)"/>
            <path d="M80.77,85.45L84.5,87.2,70.14,105.05l4.45,2.23-3.14,3.86-13.29-6.89,2.58-3.41,4.44,2L75,90.39l-6.73-.06,0.33-3.83Z"/>
          </g>
          <path d="M338,27.37S340.5-1.7,363.62,2.07C388.12,6.07,409,9.7,409,9.7s29.63,5,21,43Z" fill="#ccc"/>
          <path d="M341.45,25.29S344.86,1.62,363,4.88c23.1,4.15,44.85,7.78,44.85,7.78s24.92,4.37,19.49,35.9Z" fill="url(#ad)"/>
          <path d="M397.64,40.51c-2.55-8.83-9.76-14.46-19-14.46h0a19.9,19.9,0,0,0-13.94,5.53l-5.23-1.42A24.69,24.69,0,0,1,378.59,21a25.21,25.21,0,0,1,25,21.16Z" opacity="0.85" fill="url(#ae)"/>

          <g>
            <ellipse cx="122.88" cy="83" rx="10.73" ry="12.82" transform="translate(-3.56 5.54) rotate(-2.55)" fill="url(#af)"/>
            <path d="M123.45,95.8c5.92-.26,10.47-6.21,10.15-13.28s-5.37-12.59-11.29-12.33c0,0,24.17-.29,25.81,11.13S123.45,95.8,123.45,95.8Z" fill="url(#ag)"/>
            <path d="M122.72,72.18c4.64,0,8.49,4.68,8.75,10.43,0.26,6-3.29,11-8.1,11.2H123c-4.64,0-8.69-4.68-9-10.43a12.13,12.13,0,0,1,2.39-7.89,7.9,7.9,0,0,1,5.91-3.3h0.32m0-2h-0.41c-5.92.26-10.47,6.21-10.15,13.28,0.31,6.91,5.14,12.34,10.88,12.34h0.41c5.92-.26,10.47-6.21,10.15-13.28-0.31-6.91-5.14-12.34-10.89-12.34h0Z" fill="url(#ah)"/>
          </g>
          <g>
            <g>
              <ellipse cx="64.13" cy="56.34" rx="9" ry="10.75" transform="translate(-2.44 2.9) rotate(-2.55)" fill="url(#ai)"/>
              <path d="M64.61,67.08c5-.22,8.78-5.21,8.51-11.14s-4.5-10.56-9.47-10.34c0,0,20.27-.24,21.64,9.33S64.61,67.08,64.61,67.08Z" fill="url(#aj)"/>
            </g>
            <path d="M64,47.27c3.89,0,7.14,3.92,7.36,8.74,0.22,5-2.79,9.21-6.82,9.39H64.27c-3.89,0-7.31-3.92-7.53-8.74a10.19,10.19,0,0,1,2-6.62,6.63,6.63,0,0,1,5-2.77H64m0-1.68H63.66c-5,.22-8.77,5.21-8.51,11.14s4.31,10.34,9.13,10.34h0.34c5-.22,8.78-5.21,8.51-11.14S68.81,45.6,64,45.6h0Z" fill="url(#ak)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
