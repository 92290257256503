import React from "react"
import { graphql } from 'gatsby'
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import meoLogoSv from "../images/meo/MEO_Logo_Sve.svg"
import meoLogoEn from "../images/meo/MEO_Logo_Eng.svg"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import CloseIcon from "../components/icons/Close"
import ActionButtonSvg from "../components/ActionButtonSvg"
import MeoRoom from "../components/meo/MeoRoom"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TopBar = styled.div`
  position:absolute;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 3rem;
  background: linear-gradient(to bottom, #00899D 0%, #33A7BF 50%, #54B2C7 100%) #54B2C7;
`

const StyledActionButtonSvg = styled(ActionButtonSvg)`
  position: absolute;
  top: 0.5rem;
  right: 0.8rem;
  max-height: 2rem;
  max-width: 2rem;

  .close-icon {
    max-width: 50%;
  }
`

const MeoLogo = styled.img`
  position:relative;
  height: 60px;
  left: 1rem;
  top: 0.4rem;
`

export default function MeoTemplate({
  data: { allMeoYaml: { nodes: meoData } },
  pageContext: { lang, pagePath, embed },
}) {
  const intl = useIntl()
  const welcome = meoData.find(({ _id }) => _id === "intro")
  const selectPerson = meoData.filter(({ _id }) => _id.match(/select_/))
  const bios = meoData.filter(({ _id }) => _id.match(/bios_/))
  const items = meoData.filter(({ _id }) => _id.match(/item_/))

  const btnColors = [
    ["#3698a0", 0],
    ["#007d84", 0.5],
    ["#006c72", 1],
  ]

  const MeoLogo = (
    <img
      src={lang === "sv" ? meoLogoSv : meoLogoEn}
      alt={`${intl.formatMessage({ id: "logotype" })} ${intl.formatMessage({ id: "roomHeading5" })}`}
    />
  )

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={intl.formatMessage({ id: "roomHeading5" })}
        image={{
          url: `/assets/share-images/NW_Delning7_MEO_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
        noIndex={embed}
      />
      {!embed && (
        <Header
          room={5}
          roomSymbol={MeoLogo}
          roomSymbolBigger
          lang={lang}
        />
      )}
      <MeoRoom lang={lang} dialogues={meoData} welcome={welcome} bioData={bios} itemData={items} />
      {!embed && (
        <>
          <BrowserUpdateMessage />
          <CookieBanner lang={lang} />
        </>
      )}
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allMeoYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        _id
        headline
        dialogue {
          textblock {
            html
          }
          sound_f
          sound_m
        }
      }
    }
  }
`
