import React, { useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"
import trackEvent from "../../utils/track-event"

import MeoStyledAudioPlayer from "./MeoStyledAudioPlayer"

const meopink = "rgb(207, 60, 134)"
const meopinkdarker = "rgb(197, 56, 122)"

const Wrapper = styled.div`
  height: calc(100% - 5rem);
  padding: 1rem 0;

  @media (max-height: 550px) {
    margin-top: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 90%;
  }

  .header {
    font-family: ${props => props.theme.headingFont};
    text-align: center;
    color: white;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
    flex-basis: 3rem;
    padding: 0 1em .4em 1em;
    line-height: 100%;

    h2,h3,h4 {
      font-size: 1.4em;
      margin-top: 0.4em;
      margin-bottom: auto;
    }

    @media (max-height: 550px) { text-align: initial}
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    padding-bottom: 1em;
    position: relative;


    @media (max-height: 550px), (orientation: portrait) {
      /* padding-bottom: 1rem; */
    }

    &:before,
    &:after {
      content:"";
      width: 2rem;
      height: 1px;
      flex-shrink: 0;
    }
  }

  li {
    min-width: 35vw;
    @media (max-width: 650px) {
      min-width: 40vw;
    }
    @media (max-width: 500px) {
      min-width: 70vw;
    }
    width: 40vw;
    @media (min-width: 1024px) {
        min-width: unset;
        flex-basis: 350px;
    }
    max-height: 80%;
    position: relative;
    flex-basis: 35%;
    flex-grow: 1;
    flex-shrink: 0;
    background: white;
    margin: 1%;
    cursor: pointer;
    border-radius: 3px;
    display: inline-flex;
    vertical-align: middle;
    flex-flow: column nowrap;
    /* transition: max-height .3s ease-in-out; */

    div.selected {
      position: absolute;
      text-align: center;
      width: calc(100% + .8em);
      left: -.4em;
      z-index: 2;
      top: -50px;
      opacity: 0;
      max-height: 0;
      transition: all 0.5s ease-in;
      overflow: hidden;

      :before, :after {
        content: "";
        position: absolute;
        display: block;
        bottom: -.25em;
        border: 1em solid ${meopinkdarker};
        z-index: 1;
      }

      :before {
        right: calc(100% - .25em);
        border-right-width: 1em;
        border-left-color: transparent;
      }

      :after {
        left: calc(100% - .25em);
        border-left-width: 1em;
        border-right-color: transparent;
      }
      .selected-label {
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.2em;
        position: relative;
        z-index: 2;
        color: white;
        background: ${meopink};

        :before, :after {
          content: "";
          position: absolute;
          display: block;
          border-style: solid;
          border-color: #804f7c transparent transparent transparent;
          top: 100%;
        }

        :before {
          left: 0;
          border-width: .25em 0 0 .25em;
        }

        :after {
          right: 0;
          border-width: .25em .25em 0 0;
        }
      }
    }

    &.active {
      box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
      border: 2px solid ${meopink};

      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4rem;
        background-image: linear-gradient(transparent, white 30%);
      }
      /* max-height: 80%; */

      div.selected {
        top: 0.4rem;
        opacity: 1;
        max-height: 3rem;
        overflow: visible;
      }
    }

    @keyframes fadeMoveIn {
      from {top: -100px; opacity: 0}
      to {top: 0.4rem; opacity: 1}
    }
  }
`

const ItemWrapper = styled.div`
  position: relative;
  padding: 1.4rem 1rem 0.8rem;
  background: none;
  border: none;
  overflow-y: auto;
  outline: 0;

  img {
    height: 25vh;
    max-width: 50%;
    /* object-fit: scale-down; */
    display: block;
    margin: 1.2em auto;
  }

  h3 {
    text-align: center;
    margin: 0;
    margin-bottom: 0.4rem;
  }

  .bio {
    display: block;
    overflow: hidden;
    perspective: 500px;
    font-size: 1rem;
    max-height: 0;
    opacity: 0;
    transform: translateY(100%);
    transform-origin: top;
    transition: all .5s ease-in-out;
    outline: 0;

    p {margin: 0 auto 0.6rem;}

  }

  li.active & {
    .bio {
      opacity: 1;
      transform: translateY(0);
      max-height: 100vh;
      overflow: initial;
      margin-bottom: 2rem;
    }
  }
`

const Button = styled.button`
  display: flex;
  position: sticky;
  margin: 0.4rem auto 0.1rem;
  bottom: 0;
  max-height: 5rem;

  font-size: 1rem;
  background: none;
  border-radius: 3rem;
  padding: 0.4rem 1rem;
  border: 1px solid;
  opacity: 1;
  z-index: 2;

  transition:
    transform .3s ease-in-out,
    opacity .3s ease-in-out;

  :hover, :focus {
    color: white;
    background-color: ${meopink};
  }
`

const ButtonSelect = styled(Button)`
  border-color: ${meopink};

  li.active & {
    display: none;
  }
`

const ButtonClose = styled(Button)`
  background: white;
  border-color: #333;
  display: none;

  :hover {
    background-color: #333;
  }

  li.active & {
    display: flex;
  }
`

const MeoAudio = styled(MeoStyledAudioPlayer)`
  position: relative;
  top: initial;
  margin-top: -2em;

  ${props => !props.active ? "display: none;" : "display: block;"}
`

export default function MeoSelectPerson({ lang, closeFn, list, bioData, ...props }) {
  const [store, updateStore] = useStore()
  const intl = useIntl()
  const biographiesRef = useRef([])

  const activateItem = (itm, img, sex, name, e) => {
    if (e) {
      e.stopPropagation()
    }
    console.log(biographiesRef.current[itm])
    updateStore( store => {
      store.meo.persons[list] = { id: itm, img: img , sex: sex }
    })
    biographiesRef.current[itm].focus()
    trackEvent({ category: "MEO Config", action: "Select Person", name })
  }

  const prsns = ((l) => {
    switch(l) {
      case "child":
        return ([
          [require(`../../images/meo/person/MEO_Barn_0.svg`),null],
          [require(`../../images/meo/person/MEO_Barn_1.svg`),"m"],
          [require(`../../images/meo/person/MEO_Barn_2.svg`),"f"],
          [require(`../../images/meo/person/MEO_Barn_3.svg`),"m"],
          [require(`../../images/meo/person/MEO_Barn_4.svg`),"f"],
          [require(`../../images/meo/person/MEO_Barn_5.svg`),"m"],
        ])
        break
      case "parent":
        return ([
          [require(`../../images/meo/person/MEO_Foralder_Sittande_0.svg`),null],
          [require(`../../images/meo/person/MEO_Foralder_1.svg`),"f"],
          [require(`../../images/meo/person/MEO_Foralder_2.svg`),"m"],
          [require(`../../images/meo/person/MEO_Foralder_3.svg`),"m"],
          [require(`../../images/meo/person/MEO_Foralder_4.svg`),"f"],
          [require(`../../images/meo/person/MEO_Foralder_5.svg`),"m"],
        ])
        break
      case "anadoc":
        return ([
          [require(`../../images/meo/person/MEO_Narkoslakare_0.svg`),null],
          [require(`../../images/meo/person/MEO_Narkoslakare_1.svg`),"m"],
          [require(`../../images/meo/person/MEO_Narkoslakare_2.svg`),"f"],
          [require(`../../images/meo/person/MEO_Narkoslakare_3.svg`),"f"],
          [require(`../../images/meo/person/MEO_Narkoslakare_4.svg`),"m"],
          [require(`../../images/meo/person/MEO_Narkoslakare_5.svg`),"m"],
        ])
        break
      case "surgeon":
        return ([
          [require(`../../images/meo/person/MEO_Kirurg_0.svg`),null],
          [require(`../../images/meo/person/MEO_Kirurg_1.svg`),"f"],
          [require(`../../images/meo/person/MEO_Kirurg_2.svg`),"f"],
          [require(`../../images/meo/person/MEO_Kirurg_3.svg`),"m"],
          [require(`../../images/meo/person/MEO_Kirurg_4.svg`),"f"],
          [require(`../../images/meo/person/MEO_Kirurg_5.svg`),"m"],
        ])
        break
      case "nurse":
        return ([
          [require(`../../images/meo/person/MEO_Skoterska_0.svg`),null],
          [require(`../../images/meo/person/MEO_Skoterska_1.svg`),"f"],
          [require(`../../images/meo/person/MEO_Skoterska_2.svg`),"m"],
          [require(`../../images/meo/person/MEO_Skoterska_3.svg`),"f"],
          [require(`../../images/meo/person/MEO_Skoterska_4.svg`),"m"],
          [require(`../../images/meo/person/MEO_Skoterska_5.svg`),"f"],
        ])
        break
      default:
        break
    }
  })(list)

  const createItems = () => {

    const regex = new RegExp(`${list}`)
    const currentBios = bioData.find(({ _id }) => _id.match(regex))

    let lis = new Array()
    prsns.forEach((i,idx) => {
      // let ext = (i.substr(i.lastIndexOf(".") + 1))
      const dialogue = currentBios.dialogue[idx]
      const bio = dialogue ? dialogue.textblock.html.split(/\r?\n/) : null
      const mf = i[1] ? `sound_${i[1]}` : null
      const audio = mf && dialogue[mf] ? dialogue[mf] : null

      let currname = null
      let currbio = null
      if (bio) {
        currname = bio[0]
        bio.shift()
        currbio = bio.join('')
      }
      let currnameNoHtml = currname.replace(/<[^>]*>?/gm, '')
      let altTextAudioPlayer = intl.formatMessage({ id: "play" }) + " " + currnameNoHtml + (lang === "sv" ? "s " : "\'s ") + intl.formatMessage({ id: "meo_biography_alt" })
      const isActive = store.meo.persons[list].id == idx ? "active" : null
      // dangerouslySetInnerHTML={buttonTemplate(i[0],currname,currbio)}
      let a =
        <li
          className={isActive}
          onClick={() => activateItem(idx, i[0], i[1], currnameNoHtml)}
          key={idx}>

          <div className="selected"><div className="selected-label">{intl.formatMessage({ id: "meo_selected" })}</div></div>
          <ItemWrapper className="wrapper" >
            <img src={i[0]} alt="" />
            {!isActive &&
              <div className="personName" dangerouslySetInnerHTML={ {__html: currname }} />
            }
            <div
              className="bio"
              tabIndex={"-1"}
              ref={ bio => (biographiesRef.current[idx] = bio) }
            >
              {audio &&
                <MeoAudio src={audio} labelText={altTextAudioPlayer} active={store.meo.persons[list].id == idx ? "active" : null} />
              }
              <div dangerouslySetInnerHTML={ {__html: currname }}></div>
              <div dangerouslySetInnerHTML={ {__html: currbio }} />
            </div>
            <ButtonSelect
              onClick={(e) => activateItem(idx, i[0], i[1], currnameNoHtml, e)}
              aria-expanded={isActive ? "true" : "false"}
              >
              {intl.formatMessage({ id: "select" })}
              <span className="structural">{currnameNoHtml}</span>
            </ButtonSelect>
            <ButtonClose
              onClick={closeFn}>{intl.formatMessage({ id: "finish" })}
            </ButtonClose>
          </ItemWrapper>
        </li>
      lis.push(a)
    })
    lis.shift()
    return lis
  }

  // const buttonTemplate = (i, name, bio) => {
  //     const mkup = `
  //       <img src=${i} />
  //       ${name}
  //       <section class="bio">
  //         ${bio}
  //       </section>
  //       <div class="select">
  //         <span class="fake-button">${intl.formatMessage({ id: "select" })}</span>
  //       </div>
  //     `
  //     return  { __html: mkup }
  // }

  return(
    <Wrapper onClick={closeFn}>
      <div className="header" data-children-close-wrapper data-closes-wrapper>{props.children}</div>
      <ul data-closes-wrapper>
        {createItems()}
      </ul>
    </Wrapper>
  )
}
