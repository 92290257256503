import React from "react"
import styled from "styled-components"

import AudioPlayer from "../AudioPlayer"

const StyledAudioPlayer = styled(AudioPlayer)`
  position: absolute;
  background: #000;
  width: 100px;
  top: -1rem;
  left: 0;
  right: 0;
  margin: auto auto 1rem;
  border-radius: 0.1875rem;

  :focus-within {
    box-shadow:
      inset 0 0 1px 1px rgba(255,255,255,0.4),
      0 0 1rem rgba(255, 102, 179, 1);
  }
`

export default function MeoStyledAudioPlayer({
  src = null,
  progressBar = false,
  keyid = null,
  ...props
}) {
  return (
    <StyledAudioPlayer src={src} key={keyid} progressBar={progressBar} className={props.className} />
  )
}
