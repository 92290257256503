import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useIntl } from "gatsby-plugin-intl"

import AudioPlayer from "./../AudioPlayer"
import CloseIcon from "../../components/icons/Close"

const meopink = "rgb(207, 60, 134)"
const meoblue = "#33A7BF"

const Wrapper = styled.div`
  position: relative;

  .inner {
    height: 100%;
    overflow: scroll;
  }
`

const Inner = styled.div`
`

const Pagination = styled.nav`
  position: absolute;
  right: 1rem;
`

const BtnPrevNext = styled.button`
  background: white;
  border-width: 2px;
  border-radius: 5rem;
  padding: 0.4rem;
  &[disabled] {visibility: hidden;}
`

const StyledAudioPlayer = styled(AudioPlayer)`
  position: absolute;
  background: #000;
  width: 100px;
  top: -1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
`

export default function MeoDialogue({
    dialogue = null,
    sound = null,
    sex = "f",
    btnClose,
    headline = null,
    btnPrevNext,
    ...props
  }) {
  const intl = useIntl()
  const [page, setPage] = useState(0)

  useEffect(()=>{
    setPage(0)
  },[dialogue])

  let txt
  let snd
  let showPagination = false
  let maxPages = 1

  if (dialogue) {

    if( typeof(dialogue === "string") ) {
      txt = <div dangerouslySetInnerHTML={{ __html: dialogue }} />
    }

    if( Array.isArray(dialogue) && dialogue.length ) {
      showPagination = dialogue.length > 1 ? true : false
      maxPages = showPagination ? dialogue.length - 1 : 1
      txt = typeof(dialogue[page][0]) !== "undefined" ? dialogue[page][0] : null
      txt = <div dangerouslySetInnerHTML={{ __html: txt }} />

      if (sound) {
        snd = sound
      } else {
        snd = typeof(dialogue[page][1][sex] !== "undefined") ? dialogue[page][1][sex] : null
      }
    }

  } else {
    txt = txt ? <div dangerouslySetInnerHTML={{ __html: txt }} /> : null
    snd = sound ? sound : null
  }

  const PrevButton = (show) => <BtnPrevNext disabled={!show ? "disabled" : null} onClick={()=>setPage(page-1)}>&lt; {intl.formatMessage({ id: "previous" })}</BtnPrevNext>
  const NextButton = (show) => <BtnPrevNext disabled={!show ? "disabled" : null} onClick={()=>setPage(page+1)}>{intl.formatMessage({ id: "nextNasta" })} &gt; </BtnPrevNext>

  const pagination = () => {
    if( (page > 0) && (page < maxPages) ) {
      return <Pagination className="prev-next">{PrevButton(true)}{NextButton(true)}</Pagination>
    } else if( (page === 0) && (page < maxPages) ) {
      return <Pagination className="next">{PrevButton(false)}{NextButton(true)}</Pagination>
    } else if( (page === maxPages) && (page > 0))
      return <Pagination className="prev">{PrevButton(true)}{NextButton(false)}</Pagination>
  }

  return (
    <Wrapper className={props.className}>
      {/* <ButtonClose onClick={closeFn}><CloseIcon fill="#000" /></ButtonClose> */}
      {btnClose}
      {snd ?
        <StyledAudioPlayer src={`/${snd}`} key={page} progressBar={false} />
        : null
      }
      <div className="inner">
        {headline ? headline : null}
        {txt}
        {props.children}
      </div>
      {showPagination ? pagination() : null}
    </Wrapper>
  )
}
