import React from "react"

export default function MeoOperationsbord4(props) {
  return(
    <svg viewBox="0 0 566.93 311.81">
      <defs>
        <radialGradient id="a" cx="426.7" cy="224.04" r="14.25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </radialGradient>
        <linearGradient id="b" x1="-107.64" y1="39.68" x2="-107.64" y2="15.71" gradientTransform="matrix(0.79, -0.61, 0.7, 0.91, 485.38, 127.25)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </linearGradient>
        <radialGradient id="c" cx="209.18" cy="233.22" r="15.45" xlinkHref="#a"/>
        <linearGradient id="d" x1="-285.32" y1="-69.89" x2="-285.32" y2="-95.89" xlinkHref="#b"/>
        <radialGradient id="e" cx="306.8" cy="287.56" r="17.76" xlinkHref="#a"/>
        <linearGradient id="f" x1="-241.79" y1="20.44" x2="-241.79" y2="-9.45" xlinkHref="#b"/>
        <linearGradient id="g" x1="314.03" y1="254.54" x2="314.03" y2="189.8" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="h" x1="275.72" y1="221.62" x2="275.72" y2="73.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="i" x1="307.89" y1="221.62" x2="307.89" y2="79.31" xlinkHref="#g"/>
        <linearGradient id="j" data-name="linear_gray1" x1="175.3" y1="240.46" x2="266.03" y2="240.46" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="k" x1="308.1" y1="226.11" x2="369.67" y2="226.11" xlinkHref="#j"/>
        <linearGradient id="l" data-name="linear_gray2" x1="308.81" y1="186.59" x2="308.81" y2="168.71" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#999"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="m" x1="308.89" y1="184.75" x2="308.89" y2="170.57" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c0"/>
          <stop offset="1" stopColor="#e2eed8"/>
        </linearGradient>
        <linearGradient id="n" x1="316.86" y1="-34.72" x2="316.86" y2="-42.78" gradientTransform="translate(-132.91 229.38) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#3c3"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="o" x1="308.81" y1="208.56" x2="308.81" y2="190.68" xlinkHref="#l"/>
        <linearGradient id="p" x1="308.89" y1="206.71" x2="308.89" y2="192.54" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#fcc"/>
        </linearGradient>
        <linearGradient id="q" x1="316.86" y1="-18.26" x2="316.86" y2="-26.32" gradientTransform="translate(-132.91 229.38) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#b10000"/>
        </linearGradient>
        <linearGradient id="r" x1="153.5" y1="156.26" x2="152.72" y2="147.44" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e6e6e6"/>
          <stop offset="1" stopColor="#f2f2f2"/>
        </linearGradient>
        <linearGradient id="s" data-name="linear_gray3" x1="213.1" y1="148.98" x2="213.1" y2="143.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.21" stopColor="#c3c3c3"/>
          <stop offset="0.54" stopColor="#ababab"/>
          <stop offset="0.97" stopColor="#838383"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="t" x1="93.11" y1="159.61" x2="93.11" y2="154.61" xlinkHref="#s"/>
        <linearGradient id="u" x1="287.96" y1="144.37" x2="287.18" y2="135.45" xlinkHref="#r"/>
        <linearGradient id="v" x1="227.51" y1="147.73" x2="227.51" y2="142.73" xlinkHref="#s"/>
        <linearGradient id="w" x1="347.14" y1="136.97" x2="347.14" y2="131.97" xlinkHref="#s"/>
        <linearGradient id="x" x1="411.12" y1="133.38" x2="410.34" y2="124.47" xlinkHref="#r"/>
        <linearGradient id="y" x1="361.66" y1="135.8" x2="361.66" y2="130.81" xlinkHref="#s"/>
        <linearGradient id="z" x1="459.74" y1="126.92" x2="459.74" y2="121.92" xlinkHref="#s"/>
        <linearGradient id="aa" x1="510.15" y1="116.79" x2="507.56" y2="107.09" xlinkHref="#r"/>
        <linearGradient id="ab" x1="473.74" y1="124.43" x2="473.74" y2="119.5" xlinkHref="#s"/>
        <linearGradient id="ac" x1="544.4" y1="104.05" x2="544.4" y2="99.25" xlinkHref="#s"/>
        <linearGradient id="ad" x1="516.04" y1="121.27" x2="516.04" y2="42.13" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="ae" data-name="Namnlös övertoning 19" x1="230.79" y1="25.16" x2="230.79" y2="124.9" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f4b5d0"/>
          <stop offset="0.72" stopColor="#ff66b3"/>
          <stop offset="1" stopColor="#fe6ab4"/>
        </linearGradient>
      </defs>
      <g>
        <g>
          <circle cx="424.37" cy="224.25" r="19.25" fill="#c5387a"/>
          <ellipse cx="426.7" cy="224.04" rx="13.64" ry="14.84" fill="url(#a)"/>
          <path d="M430.85,225.89s0.37,1,0,1.24a1,1,0,0,1-1.09,0l-24.95-14.56,18.66-3.15Z" fill="url(#b)"/>
        </g>
        <g>
          <circle cx="206.66" cy="233.44" r="20.87" fill="#c5387a"/>
          <ellipse cx="209.19" cy="233.22" rx="14.79" ry="16.09" fill="url(#c)"/>
          <path d="M213.68,235.22s0.4,1,0,1.35a1.08,1.08,0,0,1-1.18,0l-27.05-15.79,20.23-3.41Z" fill="url(#d)"/>
        </g>
        <g>
          <circle cx="303.89" cy="287.81" r="24" fill="#c5387a"/>
          <ellipse cx="306.8" cy="287.56" rx="17" ry="18.5" fill="url(#e)"/>
          <path d="M312,289.86s0.46,1.2,0,1.55a1.24,1.24,0,0,1-1.36,0l-31.11-18.16,23.26-3.92Z" fill="url(#f)"/>
        </g>
        <polygon points="247.96 203.4 338.6 199.06 415.83 189.8 452.76 201.03 369.67 210.81 308.1 230.28 338.39 243.88 266.03 254.54 175.31 202.3 235.88 197.96 247.96 203.4" fill="url(#g)"/>
        <polygon points="288.72 221.62 262.72 209.98 262.72 73.44 288.72 81.67 288.72 221.62" fill="url(#h)"/>
        <polygon points="327.04 216.08 288.72 221.62 288.72 81.67 327.06 79.31 327.04 216.08" fill="url(#i)"/>
        <polygon points="338.38 265.56 266.03 278.63 266.03 254.54 338.38 243.88 338.38 265.56" fill="#ccc"/>
        <polygon points="266.03 278.63 175.3 222.07 175.31 202.3 266.03 254.54 266.03 278.63" fill="url(#j)"/>
        <polygon points="452.76 217.73 369.67 229.91 369.67 210.81 452.76 201.03 452.76 217.73" fill="#ccc"/>
        <polygon points="369.67 229.91 332.89 241.41 308.1 230.28 369.67 210.81 369.67 229.91" fill="url(#k)"/>
        <g>
          <g>
            <path d="M317.67,177.09a9.61,9.61,0,0,1-8.86,9.48,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,317.67,177.09Z" fill="url(#l)"/>
            <path d="M315.91,177.21a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.91,177.21Z" fill="url(#m)"/>
            <path d="M314.22,177.32A5.78,5.78,0,0,1,308.9,183a5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,314.22,177.32Z" fill="url(#n)"/>
          </g>
          <path d="M309,173.62l3,2.8v1.44l-2.49-2.25v5.83l-1.1.12v-5.89l-2.64,2.64v-1.52Z" fill="#fff"/>
        </g>
        <g>
          <g>
            <path d="M317.67,199.05a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.48A8.24,8.24,0,0,1,317.67,199.05Z" fill="url(#o)"/>
            <path d="M315.91,199.18a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,315.91,199.18Z" fill="url(#p)"/>
            <path d="M314.22,199.28A5.78,5.78,0,0,1,308.9,205a5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,314.22,199.28Z" fill="url(#q)"/>
          </g>
          <path d="M305.81,200.88v-1.52l2.64,2.31v-5.89l1.1-.11v5.85l2.49-2.6v1.45l-3,3.27Z" fill="#fff"/>
        </g>
      </g>
      <g className="bedTop">
        <g>
          <polygon points="12.65 119.84 12.65 105.3 87.75 149.22 467.2 115.73 551.84 91.74 551.84 107.59 467.26 132.18 87.75 166.63 12.65 119.84" fill="#ccc"/>
          <g>
            <polygon points="218.44 141.69 87.78 153.21 87.78 162.01 218.44 150.5 218.44 141.69" fill="url(#r)"/>
            <ellipse cx="213.1" cy="146.48" rx="2.42" ry="2.5" fill="url(#s)"/>
            <ellipse cx="93.11" cy="157.11" rx="2.4" ry="2.5" fill="url(#t)"/>
          </g>
          <g>
            <polygon points="352.62 129.72 222.52 141.29 222.52 150.09 352.62 138.53 352.62 129.72" fill="url(#u)"/>
            <ellipse cx="227.51" cy="145.23" rx="2.43" ry="2.5" fill="url(#v)"/>
            <ellipse cx="347.14" cy="134.47" rx="2.38" ry="2.5" fill="url(#w)"/>
          </g>
          <g>
            <polygon points="464.99 119.69 356.47 129.35 356.47 138.16 464.99 128.49 464.99 119.69" fill="url(#x)"/>
            <ellipse cx="361.66" cy="133.3" rx="2.43" ry="2.5" fill="url(#y)"/>
            <ellipse cx="459.74" cy="124.42" rx="2.37" ry="2.5" fill="url(#z)"/>
          </g>
          <g>
            <polygon points="548.64 96.08 469.07 118.98 469.07 127.79 548.64 104.89 548.64 96.08" fill="url(#aa)"/>
            <ellipse cx="473.74" cy="121.97" rx="2.21" ry="2.47" fill="url(#ab)"/>
            <ellipse cx="544.4" cy="101.65" rx="2.18" ry="2.4" fill="url(#ac)"/>
          </g>
          <polygon points="12.65 105.3 12.65 83.84 402.14 58.58 456.26 47.35 551.83 70.1 551.84 91.74 467.2 115.73 87.75 149.22 12.65 105.3" fill="#ff66b3"/>
          <polygon points="12.65 105.3 12.65 83.84 87.75 124.89 467.33 92.36 551.84 70.1 551.84 91.74 467.2 115.73 87.75 149.22 12.65 105.3" fill="#d94c8d"/>
          <path d="M465.14,49.47l1.83-1.6a22.82,22.82,0,0,1,19.86-5.23c1.72,0.4,57.48,13.51,70.11,16.62,3.27,0.8,10.06,2.47,10,10-0.05,5.57-7.27,9.84-8.09,10.31l-73.35,41.34a2.49,2.49,0,0,1-1.23.33h0A2.5,2.5,0,0,1,483,116.6l73.35-41.34c2.23-1.28,5.55-4.09,5.57-6,0-2.49-.86-3.83-6.18-5.13C543,61,486.22,47.63,485.65,47.49l-0.1,0a17.76,17.76,0,0,0-14.37,3.44Z" fill="url(#ad)"/>
        </g>
        {props.children}
        <g>
          <path d="M446.29,94.17s9.41-21.07-7.34-32.57c-11.06-7.59-20-8.1-24.5-11-9-5.75-5.92-10-23.5-19.25-17.18-9-42.42-6.58-75.75-2.58-23.18,2.78-30.17,9.66-52.83,11-32,1.88-18.81.67-40.83,0.67-18.33,0-20.46.34-40.12,4-27.19,5.07-34.89,5.55-52.33,1.33-20.67-5-34.62-5.72-50.33-3C57.58,46.44,22.58,64.4,12.65,83.84l75.1,41.05Z" fill="url(#ae)"/>
          <path d="M446.29,94.17s9.41-21.07-7.34-32.57c-11.06-7.59-20-8.1-24.5-11-9-5.75-5.92-10-23.5-19.25-12.64-6.65-29.62-7.08-51-5.25,0,0,19.73,2.73,30.23,11.5,9.18,7.66,6.12,9.12,15.37,18.12,8.63,8.4,11.63,4.31,22,12,7.25,5.37,7.43,17.39,6,29.25Z" fill="#fff" opacity="0.5"/>
        </g>
      </g>
    </svg>

)}
