import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const ButtonImage = styled.button`
  position: absolute;
  bottom: 0;
  max-height: 80vh;
  overflow: visible;
  transition: transform .3s ease-in-out;
  background: none;
  display: flex;
  align-items: flex-end;
  border: none;

  img {
    height: 100%;
    position: relative;
  }

  &.highlighted {
    background: radial-gradient(closest-side, ${props => props.meopink}, transparent);
    transform: translateZ(20vw) !important;
  }

  &.person {
    height: 65vh;
    max-height:70%;
    min-height:50vh;
  }

  &#xray {left: 2%; height: 60%; transform: translateZ(19vw);}
  &#anamachine {right: 18%; transform: translateZ(2vw); z-index: 1; height: 80%;}
  &#anadoc {right: 22%; transform: translateZ(7vw); z-index: 2;}
  &#anatable {right: 1%; height: 80%; transform: translateZ(17vw); z-index: 7;}
  &#nurse {right:9%; transform: translateZ(12vw); z-index: 6;}
  &#surgeontable {left: 20%; height: 44vh; transform: translateZ(11vw);}
  &#surgeon {left: 14%; transform: translateZ(8vw); z-index: 9;}
  &#parent {height: 55vh; max-height: 60%; min-height: 50vh; left: 38%; bottom: 0%; transform: translateZ(17.1vw); z-index: 10;}
`

const Bubble = styled.div`
  position: absolute;
  z-index: 50;
  transform-style: preserve-3d;
  width: 100%;
  transform: translateZ(21vw);
`

export default function MeoButtonImage({
  id,
  type,
  label,
  image,
  onClick,
  meopink,
  activeItem,
  activeBubble,
  bubbleOnly = false,
}) {
  const intl = useIntl()
  const isActive = activeItem === id
  const bubble = activeBubble && activeBubble.id === id ? activeBubble.bubble : null
  const className = type === "person" ? "clickable person" : "clickable"
  const activeClassName = type === "person" ? "highlighted" : ""

  return (
    <>
      {!bubbleOnly &&
        <ButtonImage
          id={id}
          onClick={onClick}
          className={`${className} ${activeItem === id ? activeClassName : ""}`}
          meopink={meopink}
          aria-expanded={activeItem === id}
        >
          <img src={image} alt={intl.formatMessage({ id: "select" }) + " " + intl.formatMessage({ id: label })} />
        </ButtonImage>
      }
      {bubble && <Bubble>{bubble}</Bubble>}
    </>
  )
}
