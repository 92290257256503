import React, { useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"

import IconPerson from "../../images/meo/MEO_Personvalsknapp_opt.svg"
import ActionButton from "./../ActionButtonSvg"

const meopink = "rgb(207, 60, 134)"
const meoblue = "rgba(0, 153, 175)"

const Wrapper = styled.div`
  width: auto;
  position: fixed;
  left: 0.8rem;
  bottom: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5rem;
  z-index: 15;

  &.active {
    background: rgba(0,0,0,0.3);
    z-index: 1001;
    max-height: 5rem;

    @media (max-height: 550px), (orientation: portrait) {
      z-index: 30;
    }
  }

  @media (orientation: portrait) {
    flex-flow: column-reverse;

    &.active {
      max-height: calc(100% - 5rem);
    }
  }

  @media (orientation: portrait) and (max-height: 580px) {
    &.active {max-height: calc(100% - 4.5rem);}
  }
`

const ToggleTools = styled.button`
  position:relative;
  width: 5rem;
  height: 5rem;
  display: block;
  padding: 0;
  border: 0;
  background: transparent;
  z-index:5;

  img {width:100%}

  @media (max-width: 375px) {
    width: 4rem;
    height: 4rem;
  }

  @media (max-height: 580px) {
    width: 4rem;
    height: 4rem;
  }
`

const ToolsWrapper = styled.div`
  bottom: 0;
  display: none;
  opacity: 0;
  max-height: 1px;
  max-width: 1px;
  border-radius: 5rem;
  align-self: flex-end;
  align-items: flex-end;

  &.show {
    display: flex;
    opacity: 1;
    max-height: 85vh;
    max-width: calc(85vw - 6rem);
    /* overflow-x: scroll;
    overflow-y: hidden; */
    overflow: auto;

    @media (orientation: portrait) {
      overflow-x: hidden;
    }

    @media (orientation: landscape) {
      overflow-y: hidden;
    }
  }

  @media (orientation: portrait) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  :last-child::after {
    content: "";
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    flex-shrink: 0;
  }
`

const Group = styled.button`
  position: relative;
  width: 4rem;
  height: 4rem;
  overflow: visible;
  border-radius: 999px;
  border: none;
  background: rgba(0,0,0,0.5);
  padding: 0;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media (orientation: landscape) {
    min-width: 4rem;
  }

  @media (orientation: portrait){
    width: 4rem;
    height: 4rem;
  }

  @media (orientation: portrait) and (max-height: 580px) {
    width: 3rem;
    height: 3rem;
  }

  ${props => props.isActive && `
    & { background: ${meopink};}
  `}

  span.caption {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: ${props => props.theme.secondaryFont};
    font-weight: regular;
    text-shadow: 0 0 5px black;
    line-height: 1em;
    color: white;
    bottom: 0rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
    padding: 2px;
  }

  img {
    max-height: 110%;
    max-width: 110%
  }

  &:first-child {margin-left: 1rem;}
  &:last-child {margin-right: 0;}

  @media (orientation: portrait) {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    &:first-child {margin-bottom: 1rem; margin-left: 0;}
    &:last-child {margin-top: 0.5rem;}
  }
`

const Reset = styled(ActionButton)`
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  font-size: 0.8em;
  color: white;
  margin-top: 0.5rem;

  span.label {
    line-height: 1em;
    white-space: initial;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  svg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (orientation: landscape) {
    margin: auto 1rem auto auto;
  }
`

const btnColors = [
  ["rgb(245, 110, 178)", 0],
  ["rgb(207, 60, 134)", 0.5],
  ["rgb(148, 38, 93)", 1],
]

export default function MeoControlsPersons({active, activeItem, clickFn, setActiveTb, ...props}) {
  const intl = useIntl()
  const [store, updateStore] = useStore()

  const reset = () => {
    let r = {
      child: {id: 0, img: "", sex: ""},
      parent: {id: 0, img: "", sex: ""},
      surgeon: {id: 0, img: "", sex: ""},
      anadoc: {id: 0, img: "", sex: ""},
      nurse: {id: 0, img: "", sex: ""},
    }
    updateStore(store => {
      store.meo.persons = r
    })
  }

  const ariaLabel = item => {
    return intl.formatMessage({ id: "select" }) + " " + intl.formatMessage({ id: item }).replace(/(&.+;)/, "")
  }

  const createProps = item => {
    if( activeItem == item ) {
      return ({
        "isActive" : "active",
        // "aria-pressed" : true,
      })
    } else {
      return ({
        // "aria-pressed" : false,
      })
    }
  }

  return (
    <Wrapper className={active ? "active" : null} >
      <ToggleTools onClick={()=> !active ? setActiveTb("person") : setActiveTb(null) } aria-expanded={!!active}>
        <span className="structural">{intl.formatMessage({ id: "meo_toolPerson_alt" })}</span>
        <img src={IconPerson} alt="" />
      </ToggleTools>
      <ToolsWrapper className={active ? "show" : null} show={active}>
        <Group onClick={(e) => clickFn("child", e)} {...createProps("child")} aria-label={ariaLabel("meo_child")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_child" }) } } />
          <img src={require(`../../images/meo/person/MEO_Barn_${store.meo.persons.child.id}.svg`)} alt="" />
        </Group>
        <Group onClick={(e) => clickFn("parent", e)} {...createProps("parent")} aria-label={ariaLabel("meo_parent")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_parent" }) } } />
          <img src={require(`../../images/meo/person/MEO_Foralder_${store.meo.persons.parent.id}.svg`)} alt="" />
        </Group>
        <Group onClick={(e) => clickFn("surgeon", e)} {...createProps("surgeon")} aria-label={ariaLabel("meo_surgeon")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_surgeon" }) } } />
          <img src={require(`../../images/meo/person/MEO_Kirurg_${store.meo.persons.surgeon.id}.svg`)} alt="" />
        </Group>
        <Group onClick={(e) => clickFn("anadoc", e)} {...createProps("anadoc")} aria-label={ariaLabel("meo_anaesthesiaDoctor")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_anaesthesiaDoctor" }) } } />
          <img src={require(`../../images/meo/person/MEO_Narkoslakare_${store.meo.persons.anadoc.id}.svg`)} alt="" />
        </Group>
        <Group onClick={(e) => clickFn("nurse", e)} {...createProps("nurse")} aria-label={ariaLabel("meo_nurse")}>
          <span className="caption" dangerouslySetInnerHTML={ {__html: intl.formatMessage({ id: "meo_nurse" }) } } />
          <img src={require(`../../images/meo/person/MEO_Skoterska_${store.meo.persons.nurse.id}.svg`)} alt="" />
        </Group>
        <Reset basecolor={meopink} gradient={btnColors} onClick={reset} className="reset">
          <span className="label">{intl.formatMessage({ id: "reset" })}</span>
        </Reset>
      </ToolsWrapper>
    </Wrapper>
  )
}
