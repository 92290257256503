import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useIntl } from "gatsby-plugin-intl"

import AudioPlayer from "./../AudioPlayer"
import CloseIcon from "../../components/icons/Close"

const meopink = "rgb(207, 60, 134)"
const meoblue = "#33A7BF"

const Wrapper = styled.div`
  width: 80vw;
  max-width: 500px;
  max-height: 60vh;
  background: white;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
  padding: 1rem;
  padding-top: 1.4rem;
  z-index: 50;
  border-radius: 90% 4% 42% 3% / 6% 145% 6% 95%;
  border: 3px solid #000;
  outline: 0;

  /*
  :after {
      content: "";
      position: absolute;
      top: 30%;
      left: 0;
      transform: translateX(-100%);
      width: 0;
      height: 0;
      border: 50px solid white;
      border-top: 30px solid transparent;
      border-left: 0;
      border-bottom: 10px solid transparent;
    }
  */

  :after {
    content: '';
    border-radius: 100% 0 0;
    box-shadow:
      1px 3px 0 -1px #000 inset,
      3px 24px 0 -10px #fff inset,
      5px 28px 0 -12px #000 inset;
    left: 5px;
    position: absolute;
    top: 30% ;
    height: 3em;
    width: 5em;
    transform: translateX(-100%);
  }


  /* &:before {
      content: "[ > : Player]";
      float: left;
    } */


  &.inverted {
    :after {
      content: '';
      border-radius: 0% 100% 0 0;
      box-shadow:
        -1px 3px 0 -1px #000 inset,
        -3px 24px 0 -10px #fff inset,
        -5px 28px 0 -12px #000 inset;
      right: 10px;
      left: initial;
      position: absolute;
      top: 30% ;
      height: 3em;
      width: 5em;
      transform: translateX(100%);
    }

    /*
    :after {
      left: initial;
      right: 0;
      transform: translateX(100%);
      border: 50px solid white;
      border-top: 30px solid transparent;
      border-right: 0;
      border-bottom: 10px solid transparent;
    }
    */
  }
`

const Pagination = styled.nav`
  position: absolute;
  right: 1rem;
`

const PaginationButton = styled.button`
  background: white;
  border-width: 2px;
  border-radius: 5rem;
  padding: 0.4rem;
  &[disabled] {visibility: hidden;}
`

const BtnNext = styled(PaginationButton)`
`

const BtnPrev = styled(PaginationButton)`
`

const StyledAudioPlayer = styled(AudioPlayer)`
  position: absolute;
  background: #000;
  width: 100px;
  top: -1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
`
const ButtonClose = styled.button`
  position: absolute;
  top: -1rem;
  right: 1rem;
  background: white;
  padding: 0.6rem;
  /* border: 3px solid #000;*/
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;

  svg {width: 1rem; height: 1rem;}
`

const Text = styled.div`
  position: relative;
  max-height: calc(60vh - 2em);
  overflow-y: auto;

  /* add scroll-indicator? */

  /* display: flex;
  flex-direction: column;
  z-index: 1;
  flex-wrap: nowrap;
  justify-content: flex-start; */

  /*
  ::before,
  ::after {
    content: "";
    position: relative;
    z-index: -1;
    display: block;
    min-height: 2rem;
    width: 100%;
    flex: 1 0 auto;
    background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  } */
`


const MeoSpeechBubble = React.forwardRef(( { dialogue, closeFn, sex = "f", ...props }, ref) => {
    const intl = useIntl()

    const [page, setPage] = useState(0)

    const showPagination = dialogue !== null || typeof(dialogue.length) !== "undefined" ? true : false
    const maxPages = showPagination ? dialogue.length - 1 : 1
    const txt = typeof(dialogue[page][0]) !== "undefined" ? dialogue[page][0] : null
    const sound = typeof(dialogue[page][1][sex]) !== "undefined" ? dialogue[page][1][sex] : null

    useEffect(()=>{
      setPage(0)
    },[dialogue])

    const text = typeof(txt) === "string" ? <div dangerouslySetInnerHTML={{ __html: txt }} /> : txt

    const pagination = () => {
      if( (page > 0) && (page < maxPages) ) {
        return <Pagination className="prev-next">{PrevButton(true)}{NextButton(true)}</Pagination>
      } else if( (page === 0) && (page < maxPages) ) {
        return <Pagination className="next">{PrevButton(false)}{NextButton(true)}</Pagination>
      } else if( (page === maxPages) && (page > 0))
        return <Pagination className="prev">{PrevButton(true)}{NextButton(false)}</Pagination>
    }

    const PrevButton = (show) => <BtnPrev disabled={!show ? "disabled" : null} onClick={()=>setPage(page-1)}>&lt; {intl.formatMessage({ id: "previous" })}</BtnPrev>
    const NextButton = (show) => <BtnNext disabled={!show ? "disabled" : null} onClick={()=>setPage(page+1)}>{intl.formatMessage({ id: "nextNasta" })} &gt; </BtnNext>

    return(
        <Wrapper className={props.className} tabIndex="-1" ref={ref}>
          <ButtonClose onClick={closeFn}><CloseIcon fill="#000" /></ButtonClose>
          {sound ?
            <StyledAudioPlayer src={`${sound}`} key={page} progressBar={false} />
            : null
          }
          {props.children}
          <Text>{text}</Text>
          {showPagination ? pagination() : null}
        </Wrapper>
    )
})

export default MeoSpeechBubble
