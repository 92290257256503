import React, { useEffect} from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { useStore } from "../../hooks/store"
import useReducedMotion from "../../hooks/reducedMotion"

import roentgenThumb from "../../images/meo/roentgen_thumb.svg"

const Wrapper = styled.div`
  position:relative;
`

const SvgWrapper = styled.div`
  position: relative;
  max-width: 90vh;
  max-width: 63vh;
  margin: 0 auto;
`

const StyledSvg = styled.svg`
  position: relative;
  z-index: 2;

  g g {
    animation-iteration-count: infinite;
  }

  #buttonOnOff {cursor: pointer}

  .lamps.true {
    .lampsTopOn,
    .lampsBottomOn {opacity: 1}
    .lampsTopOff rect,
    .lampsBottomOff rect {fill: black}
  }

  .lamps.false {
    .lampsTopOn,
    .lampsBottomOn {opacity: 0}
    .lampsTopOff rect,
    .lampsBottomOff rect {}
  }

  @keyframes blink {
    30% {opacity: 1;}
    50% {opacity: 0;}
    70% {opacity: 1;}
  }

  @keyframes doubleblink {
    0% {opacity: 1;}
    40% {opacity: 0;}
    50% {opacity: 0.8;}
    60% {opacity: 0;}
    100% {opacity: 1;}
  }

  @keyframes switchOn {
    0% {
      transform: scale(1,0.8) translate3d(0,0,0);
      -webkit-filter: brightness(30);
      filter: brightness(30);
      opacity: 1;
    }
    10%{
      transform: scale(1,0.8) translate3d(0,150%,0);
    }

    20% {
      transform: scale(1,0.8) translate3d(0,-100%,0);
      opacity: 1;
    }

    50% {
      transform: scale(1.3,0.6) translate3d(0,100%,0);
      -webkit-filter: brightness(30);
      filter: brightness(30);
      opacity: 0;
    }

    100% {
      transform: scale(1,1) translate3d(0,0,0);
      -webkit-filter: contrast(0) brightness(0) ;
      filter: contrast(0) brightness(0);
      opacity: 0;
    }
  }

  @keyframes switchOff {
    0% {
      transform: scale(1,1.3) translate3d(0,0,0);
      -webkit-filter: brightness(1);
      filter: brightness(1);
      opacity: 1;
    }
    60% {
      transform: scale(1.3,0.001) translate3d(0,0,0);
      -webkit-filter: brightness(10);
      filter: brightness(10);
    }
    100% {
      transform: scale(0.000,0.0001) translate3d(0,0,0);
      -webkit-filter: brightness(50);
      filter: brightness(50);
    }
  }
`
const Slider = styled.input`
  position: absolute;
  bottom: 25%;
  right: 7%;
  width: 41%;
  z-index: 5;
  transform: rotate(-90deg);
  -webkit-appearance: none;
  background: none;

  &::-webkit-slider-runnable-track {
    background: none;
  }

  &::-moz-range-track {
    background: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 4vh;
    width: 4vh;
    border-radius: 999px;
    margin-top: -4px;
    transform: rotate(90deg);
    background-image: url(${roentgenThumb});
    background-position: center center;
    background-size: 4.5vh;
    box-shadow: 0px 3px 1vh #666;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 4vh;
    width: 4vh;
    border-radius: 999px;
    margin-top: -4px;
    transform: rotate(90deg);
    background-image: url(${roentgenThumb});
    background-position: center center;
    background-size: 4.5vh;
    box-shadow: 0px 3px 1vh #666;
  }
`

const Screen = styled.div`
  position: absolute;
  overflow: hidden;
  left: 10%;
  right: 22%;
  top: 22%;
  bottom: 45%;
  z-index: 0;
  background-color: black;
  background-image: none;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in, background-color 1s ease-in;

  &:before {
    content: '';
    display:block;
    position:absolute;
    width:100%;
    height:50%;
    animation-fill-mode:forwards;
    animation-name:switchOff;
    animation-duration:0.2s;
    transition: all 0.3s ease-in-out;
    background-color:white;
  }

  &.on:before {
    animation-name:switchOn;
    animation-duration: 1s;
  }

  &.on {
    background-color:#333;
    background-image: 'url()';
  }

  img {
    height: 100%;
    min-width: 100%;
    display: none;
  }

  &.on img { display: block; }
`

export default function MeoXray({ lang, closeFn, ...props }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const shouldReduceMotion = useReducedMotion()

  const animationDisabled = shouldReduceMotion || !store.animationsEnabled
  const slideposlabels = ["skeleton", "inside", "outside"]
  const slideposImages = [
    require("../../images/meo/xray-screens_3.svg"),
    require("../../images/meo/xray-screens_2.svg"),
    require("../../images/meo/xray-screens_1.svg"),
  ]

  const settings = store.meo.xray

  const slideposlabel = slideposlabels[settings.sliderPos - 1]
  const screenImage = slideposImages[settings.sliderPos - 1]

  const handleSlider = (event) => {
    let val = event.target.value
    updateStore(store => {
      store.meo.xray.sliderPos = val
    })
  }

  let blinkingLamps

  useEffect( () => {
    blinkingLamps = document.querySelectorAll('.lamps .blinking rect')
    // console.log("check me for recursion")
    if (settings.screenOn === true && !animationDisabled) {
      blink(blinkingLamps)
    }
  })

  const toggleOnOff = (e) => {
    e.preventDefault()

    if (!props.disabled) {
      // console.log(blinkingLamps)
      !settings.screenOn && !animationDisabled ? blink(blinkingLamps) : unblink(blinkingLamps)
      updateStore( store => {
        store.meo.xray.screenOn = !settings.screenOn
      })
    }
  }

  function blink(arr) {
    let l = arr;
    l.forEach( i => {
      let timing = Math.random() * 5 + 1;
      i.style.animationDuration = timing + 's';
      i.style.animationName = 'blink';
      i.style.animationIterationCount = 'infinite';
    });
  }

  function unblink(arr) {
    let l = arr;
    l.forEach( i => {
      i.style.animationDuration = '';
      i.style.animationName = '';
    })
  }

  const handleWrapperClick = (e) => {
    if(e.currentTarget === e.target) {
      closeFn()
    }
  }

  return(
    <Wrapper
      className={props.className}
      clickable={!props.disabled}
      onClick={handleWrapperClick}
      >
      {props.children}
      <SvgWrapper>
        <Slider
          type="range"
          min="1"
          max="3"
          value={settings.sliderPos}
          onChange={handleSlider}
          aria-valuemin="1"
          aria-valuemax="3"
          aria-valuenow={settings.sliderPos}
          aria-valuetext={slideposlabel}
          disabled={props.disabled}
        ></Slider>
        {/*
          aria-valuemin="1"
          aria-valuemax="7"
          aria-valuenow="2"
          aria-valuetext="Monday">

          OR
          <Slider type="range" list="xray-states" onChange={handleSlider}></Slider>
          <datalist id="xray-states">
            <option value="1" label="outside"></option>
            <option value="2" label="inside"></option>
            <option value="3" label="skeleton"></option>
          </datalist>
        */}
        <StyledSvg
          id="rontgen"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 390.76 560"
        >
        <defs>
          <style>
          {`
            .cls-1 {
              fill: none;
            }

            .cls-2 {
              fill: url(#linear-gradient);
            }

            .cls-3 {
              fill: #4d4d4d;
            }

            .cls-4 {
              fill: url(#linear-gradient-2);
            }

            .cls-5 {
              fill: url(#linear-gradient-3);
            }

            .cls-6 {
              fill: url(#linear-gradient-4);
            }

            .cls-7 {
              fill: url(#Ny_övertoningsruta_1);
            }

            .button-gray-outline {
              fill: gray;
            }

            .cls-9 {
              fill: url(#Ny_övertoningsruta_6);
            }

            .cls-10 {
              fill: #666;
            }

            .cls-11 {
              fill: #6cf;
            }

            .cls-12 {
              fill: url(#Ny_övertoningsruta_1-2);
            }

            .cls-13 {
              fill: #e6e6e6;
            }

            .cls-14 {
              fill: url(#linear-gradient-5);
            }

            .cls-15 {
              fill: url(#linear-gradient-6);
            }

            .cls-16 {
              fill: url(#Ny_övertoningsruta_2);
            }

            .cls-17 {
              fill: url(#Ny_övertoningsruta_5);
            }

            .cls-18 {
              fill: url(#linear-gradient-7);
            }

            .cls-19 {
              fill: url(#Ny_övertoningsruta_4);
            }

            .cls-20 {
              fill: url(#linear-gradient-8);
            }

            .cls-21 {
              fill: url(#linear-gradient-9);
            }

            .cls-28 {
              fill: url(#Ny_övertoningsruta_5-2);
            }

            .cls-29 {
              fill: url(#Ny_övertoningsruta_4-2);
            }

            .button-gray {
              fill: url(#button_gray_gradient);
            }

            .cls-32 {
              fill: url(#linear-gradient-10);
            }

            .cls-33 {
              clip-path: url(#clip-path);
            }

            .cls-34 {
              fill: url(#linear-gradient-11);
            }

            .cls-35 {
              ${settings.screenOn == true ? `
                fill: darkred;
                `:`
                fill: #333;
                `
              }
            }
          `}
          </style>
          <linearGradient id="linear-gradient" x1="73.91" y1="518.16" x2="86.96" y2="518.16" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="gray"/>
            <stop offset="1" stopColor="#4d4d4d"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="34.9" y1="533.77" x2="56.11" y2="533.77" xlinkHref="#linear-gradient"/>
          <linearGradient id="linear-gradient-3" x1="47.37" y1="533.77" x2="76.99" y2="533.77" gradientTransform="matrix(-1, 0, 0, 1, 361.24, 0)" xlinkHref="#linear-gradient"/>
          <linearGradient id="linear-gradient-4" x1="88.97" y1="518.13" x2="107.25" y2="518.13" gradientTransform="matrix(-1, 0, 0, 1, 361.24, 0)" xlinkHref="#linear-gradient"/>
          <linearGradient id="Ny_övertoningsruta_1" data-name="Ny övertoningsruta 1" x1="170" y1="518" x2="170" y2="45" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#b9b9b9"/>
            <stop offset="1" stopColor="#e6e6e6"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_6" data-name="Ny övertoningsruta 6" x1="13.93" y1="59.39" x2="326.18" y2="59.39" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e6e6e6"/>
            <stop offset="0.23" stopColor="#b9b9b9"/>
            <stop offset="1" stopColor="#e6e6e6"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_1-2" x1="331.31" y1="234.63" x2="370.64" y2="234.63" xlinkHref="#Ny_övertoningsruta_1"/>
          <linearGradient id="linear-gradient-5" x1="331.01" y1="231.53" x2="370.95" y2="191.59" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#fff" stopOpacity="0"/>
            <stop offset="0.37" stopColor="#fff" stopOpacity="0.01"/>
            <stop offset="0.51" stopColor="#fff" stopOpacity="0.03"/>
            <stop offset="0.6" stopColor="#fff" stopOpacity="0.08"/>
            <stop offset="0.68" stopColor="#fff" stopOpacity="0.15"/>
            <stop offset="0.75" stopColor="#fff" stopOpacity="0.23"/>
            <stop offset="0.8" stopColor="#fff" stopOpacity="0.34"/>
            <stop offset="0.86" stopColor="#fff" stopOpacity="0.47"/>
            <stop offset="0.91" stopColor="#fff" stopOpacity="0.61"/>
            <stop offset="0.95" stopColor="#fff" stopOpacity="0.78"/>
            <stop offset="0.99" stopColor="#fff" stopOpacity="0.96"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="linear-gradient-6" x1="170.06" y1="303.14" x2="170.06" y2="129.7" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#f2f2f2"/>
            <stop offset="1" stopColor="gray"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_2" data-name="Ny övertoningsruta 2" x1="281.45" y1="112.57" x2="281.45" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#f90"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_5" data-name="Ny övertoningsruta 5" x1="250.75" y1="112.57" x2="250.75" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#0c0"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="linear-gradient-7" x1="220.05" y1="112.57" x2="220.05" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#09f"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_4" data-name="Ny övertoningsruta 4" x1="189.35" y1="112.57" x2="189.35" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="red"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="Ny_övertoningsruta_5-2" x1="72.11" y1="482.64" x2="72.11" y2="466.26" xlinkHref="#Ny_övertoningsruta_5"/>
          <linearGradient id="Ny_övertoningsruta_4-2" x1="72.11" y1="452.73" x2="72.11" y2="436.35" xlinkHref="#Ny_övertoningsruta_4"/>
          <linearGradient id="linear-gradient-8" x1="158.65" y1="112.57" x2="158.65" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#fc0"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="linear-gradient-9" x1="127.95" y1="112.57" x2="127.95" y2="102.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#f6f"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
          <linearGradient id="button_gray_gradient" data-name="button gray gradient" y1="100%" y2="0%" x1="0%" x2="0%">
            <stop offset="0" stopColor="gray"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>

          <linearGradient id="linear-gradient-10" x1="54.28" y1="581.13" x2="56.41" y2="581.13" gradientTransform="matrix(0, -28.35, -28.35, 0, 16547.71, 1928.03)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e1e1e1"/>
            <stop offset="1" stopColor="#969696"/>
          </linearGradient>
          <clipPath id="clip-path">
            <path className="cls-1" d="M54.28,359a20.46,20.46,0,1,0,20.46-20.46A20.46,20.46,0,0,0,54.28,359"/>
          </clipPath>
          <linearGradient id="linear-gradient-11" x1="51.36" y1="582.62" x2="53.49" y2="582.62" gradientTransform="matrix(0, -19.21, -19.21, 0, 11268.85, 1366.35)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="gray"/>
            <stop offset="0.85" stopColor="#fff"/>
            <stop offset="1" stopColor="#fff"/>
          </linearGradient>
        </defs>
        <title>{intl.formatMessage({ id: "meo_xray" })}</title>
        { /* <g id="Screen">
          <rect x="49.32" y="134.15" width="241.49" height="164.53"/>
        </g>  */ }
        <g id="XrayBackground">
          <g>
            <ellipse className="cls-2" cx="80.43" cy="518.16" rx="6.53" ry="9.61"/>
            <path className="cls-3" d="M74.28,527.77c-3.61,0-6.53-4.3-6.53-9.61s2.92-9.61,6.53-9.61h6.16c-3.61,0-6.53,4.3-6.53,9.61s2.92,9.61,6.53,9.61H74.28Z"/>
          </g>
          <g>
            <ellipse className="cls-4" cx="45.5" cy="533.77" rx="10.61" ry="15.61"/>
            <path className="cls-3" d="M35.5,549.38c-5.86,0-10.61-7-10.61-15.61s4.75-15.61,10.61-15.61h10c-5.86,0-10.61,7-10.61,15.61s4.75,15.61,10.61,15.61h-10Z"/>
          </g>
          <g>
            <ellipse className="cls-5" cx="299.06" cy="533.77" rx="14.81" ry="15.61"/>
            <path className="cls-3" d="M302.47,549.38c8.18,0,14.81-7,14.81-15.61s-6.63-15.61-14.81-15.61h-3.41c8.18,0,14.81,7,14.81,15.61s-6.63,15.61-14.81,15.61h3.41Z"/>
          </g>
          <g>
            <ellipse className="cls-6" cx="263.13" cy="518.13" rx="9.14" ry="9.63"/>
            <path className="cls-3" d="M265.24,527.77a9.65,9.65,0,0,0,0-19.27h-2.11a9.65,9.65,0,0,1,0,19.27h2.11Z"/>
          </g>
          <path className="cls-7" d="M136.84,45C85.6,45,75.59,92,75.59,92H14V518H326V45H136.84ZM291,299H49V134H291V299Z"/>
          <g>
            <rect className="button-gray-outline" x="277.84" y="336.63" width="6" height="147.12"/>
            <rect className="button-gray-outline" x="271.69" y="477.44" width="18.31" height="6.31"/>
            <rect className="button-gray-outline" x="271.69" y="407.06" width="18.31" height="6.31"/>
            <rect className="button-gray-outline" x="271.69" y="336.63" width="18.31" height="6.31"/>
          </g>
          <path className="cls-9" d="M310.94,27H135C87.53,27,78.26,73.79,78.26,73.79H29.19l-15.26,18H75.59S85.59,45,136.83,45H326.18Z"/>
          <g>
            <path className="cls-10" d="M114.4,63h5.37l4.49,6.89L128.72,63H134l-7,10.59,7.53,11.2H129l-4.58-7.59-4.86,7.59h-5.43l7.62-10.89Z"/>
            <path className="cls-10" d="M141.76,69.21c2.76,0,4.07,1.06,4.07,3.51A3,3,0,0,1,143.48,76l2.91,4.87h-2.76l-2.57-4.58h-2.89v4.58h-2.45V69.21h6Zm-3.59,2.08v2.94h3c1.46,0,2.18-.29,2.18-1.48s-0.71-1.46-2.18-1.46h-3Z"/>
            <path className="cls-10" d="M153.52,69.21l5,11.66h-2.73l-1.07-2.78h-5.07l-1,2.78H146l4.94-11.66h2.6Zm-3.1,6.8h3.47l-1.77-4.48Z"/>
            <path className="cls-10" d="M159.43,69.21l2.87,4.45,2.92-4.45h2.84l-4.5,6.51v5.15h-2.45V75.73l-4.45-6.51h2.76Z"/>
            <path className="cls-10" d="M178.37,78.87c1.28,0,1.9-.42,1.9-1.43S179.64,76,178.37,76h-3.88V74h3.82c1.27,0,1.9-.41,1.9-1.4s-0.62-1.41-1.9-1.41h-4.74v-2h5.6c2.6,0,3.85,1,3.85,3.1A3.2,3.2,0,0,1,181.44,75,2.8,2.8,0,0,1,183,77.77c0,1.95-1.28,3.1-3.62,3.1h-5.84v-2h4.81Z"/>
            <path className="cls-10" d="M189.78,78.87c1.28,0,1.9-.42,1.9-1.43S191,76,189.78,76H185.9V74h3.82c1.27,0,1.9-.41,1.9-1.4s-0.62-1.41-1.9-1.41H185v-2h5.6c2.6,0,3.85,1,3.85,3.1A3.2,3.2,0,0,1,192.85,75a2.8,2.8,0,0,1,1.59,2.74c0,1.95-1.28,3.1-3.62,3.1H185v-2h4.81Z"/>
            <path className="cls-10" d="M196.55,75.06c0-4.08,1.87-6,5.83-6s5.81,1.92,5.81,6-1.85,6-5.81,6S196.55,79.13,196.55,75.06Zm9.06,0c0-2.74-1-4.06-3.25-4.06s-3.23,1.32-3.23,4.06,1,4,3.23,4S205.61,77.8,205.61,75.06Z"/>
            <path className="cls-10" d="M217.75,75.35c0,2.61.8,3.49,2.86,3.49h4v2h-4.14a5.19,5.19,0,0,1-3.64-1c-1.2-1-1.78-2.48-1.78-4.6,0-4.12,1.74-6,5.49-6h4.08v2h-4C218.54,71.21,217.75,72.46,217.75,75.35Z"/>
            <path className="cls-10" d="M229.31,73.94h4.5V69.21h2.45V80.87h-2.45V76h-4.5v4.87h-2.45V69.21h2.45v4.72Z"/>
            <path className="cls-10" d="M245.14,69.21l5,11.66h-2.73l-1.07-2.78h-5.07l-1,2.78h-2.6l4.94-11.66h2.6ZM242,76h3.48l-1.77-4.48Z"/>
            <path className="cls-10" d="M257.49,69.21c2.76,0,4.08,1.06,4.08,3.51A3,3,0,0,1,259.21,76l2.91,4.87h-2.76l-2.56-4.58H253.9v4.58h-2.45V69.21h6Zm-3.59,2.08v2.94h3c1.46,0,2.18-.29,2.18-1.48s-0.71-1.46-2.18-1.46h-3Z"/>
            <path className="cls-10" d="M264.88,79.65a4.52,4.52,0,0,1-1.06-3.51V69.21h2.45v6.85a3.25,3.25,0,0,0,.41,2.11,2.64,2.64,0,0,0,2.26.76h2.89v1.93h-3.1C267,80.87,265.92,80.76,264.88,79.65Z"/>
            <path className="cls-10" d="M275.4,74h6.79v2h-6.8c0,2.09.84,2.86,2.78,2.86h4v2H278a5.19,5.19,0,0,1-3.64-1c-1.2-1-1.79-2.48-1.79-4.6,0-4.12,1.74-6,5.49-6h4.08v2h-4C276.39,71.24,275.5,72.12,275.4,74Z"/>
            <path className="cls-10" d="M288.63,71.29c-1.28,0-1.88.46-1.88,1.53,0,0.84.52,1.27,1.54,1.27h3.25c2.6,0,3.72.86,3.72,3.3s-1.31,3.49-4.07,3.49h-6.53V78.79h6.14c1.3,0,1.9-.31,1.9-1.32s-0.55-1.35-1.74-1.35h-2.89c-2.66,0-3.88-1-3.88-3.34,0-2.53,1.31-3.57,4.09-3.57h6.41v2.08h-6.06Z"/>
          </g>
          <path className="button-gray-outline" d="M326.18,243.13h34.39c0.13,0,10.2.74,12.89-7.68a3,3,0,1,1,5.72,1.83c-3.5,10.95-13.31,11.88-17.63,11.88h0c-0.62,0-1.06,0-1.26,0h-34.1v-6Z"/>
          <path className="cls-11" d="M348.41,497.86a16.64,16.64,0,0,1-9.32-2.93c-13-8.81-9.23-38.29-7.77-47.12,8.74-52.75,9.23-88.23,8.67-113.24-0.42-19-12.76-19.26-13.28-19.27h-0.53v-4.8h0.55c0.19,0,17.55.38,18.07,24,0.56,25.22.07,61-8.74,114.14-2.85,17.22-2.19,37,5.73,42.36a11.21,11.21,0,0,0,10.91,1.2c3.9-1.75,6.43-5.63,7.14-10.94,2.06-15.46-.7-27.74-4.2-43.3-0.48-2.14-1-4.33-1.47-6.61-4.21-19.23-5.61-79.73-5.61-94.26,0-16.81,0-30.81,0-30.81v-4.47h4.8v4.46s0,14,0,30.82c0,14.42,1.37,74.38,5.5,93.24,0.5,2.27,1,4.46,1.47,6.59,3.6,16,6.45,28.68,4.27,45-0.92,6.91-4.54,12.27-9.93,14.68a15.24,15.24,0,0,1-6.25,1.33h0Z"/>
          <g>
          {lang !== "sv" &&
            <g data-name="labels EN">
              <path className="cls-3" d="M199.49,333a8,8,0,0,1,1.37,5,6.72,6.72,0,0,1-1.94,5.2,6.86,6.86,0,0,1-4.39,1.31c-2.48,0-4.47-.8-5.5-2.21a7.37,7.37,0,0,1-1.23-4.42c0-2.47.65-4.37,1.89-5.4a7.11,7.11,0,0,1,4.64-1.39C196.61,331.07,198.5,331.76,199.49,333Zm-7.94,1.59a5.93,5.93,0,0,0-.65,3.18c0,3.09,1.07,4.5,3.45,4.5s3.42-1.41,3.42-4.46-1.06-4.49-3.42-4.49A3.26,3.26,0,0,0,191.55,334.58Z"/>
              <path className="cls-3" d="M209.33,334.74H212v9.67h-5.23c-2.62.12-3.87-1.09-3.87-3.71v-6h2.66V341a1.21,1.21,0,0,0,1.27,1.39h2.51Z"/>
              <path className="cls-3" d="M215.31,331.16H218v3.58h2.47v2.06H218v3.52c0,1.61.22,2,1.41,2h1.06v2.07h-1.93c-2.28,0-3.28-1.09-3.2-3.64v-4h-1.69v-2.06h1.69Z"/>
              <path className="cls-3" d="M230.82,336.8H226.1c-1.2,0-1.47.16-1.47.86a.87.87,0,0,0,.43.79c.18.12.48.16,1.29.16h1.57c2.13,0,3.34,1,3.34,2.76a3.14,3.14,0,0,1-1.49,2.74,5.07,5.07,0,0,1-2.24.3H222v-2.07h4.72a6.44,6.44,0,0,0,1.43-.08.69.69,0,0,0,.48-.73.89.89,0,0,0-.46-.83,2.78,2.78,0,0,0-1.15-.12h-1.63A3.57,3.57,0,0,1,223,340a3,3,0,0,1-1.15-2.46,2.93,2.93,0,0,1,1.05-2.32,3.34,3.34,0,0,1,2.2-.5h5.68Z"/>
              <path className="cls-3" d="M233.35,331.16H236v2.59h-2.66Zm2.66,3.58v9.67h-2.66v-9.67Z"/>
              <path className="cls-3" d="M245.15,331.16h2.66v13.25h-4.59c-1.87,0-2.76-.28-3.71-1.13A4.89,4.89,0,0,1,238,339.5a4.69,4.69,0,0,1,1.67-3.87,4.44,4.44,0,0,1,2.93-.89h2.51Zm0,5.64h-1.93a2.39,2.39,0,0,0-2.48,2.7c0,1.77.95,2.84,2.48,2.84h1.93Z"/>
              <path className="cls-3" d="M258.38,340.6h-5.6c.08,1.25.79,1.78,2.34,1.74h3.26v2.07h-3.54a4.74,4.74,0,0,1-3.25-.91,5.38,5.38,0,0,1,.14-8.07,5,5,0,0,1,2.92-.69h3.73v2.06h-3.54c-1.46,0-2,.42-2.06,1.73h5.6Z"/>
              <path className="cls-3" d="M205.39,401.32h2.82v13.25h-2.82Z"/>
              <path className="cls-3" d="M216,404.9c2.62-.12,3.87,1.09,3.87,3.71v6h-2.66v-6.26c0-1-.39-1.37-1.37-1.35h-2.3v7.61h-2.66V404.9Z"/>
              <path className="cls-3" d="M230.82,407H226.1c-1.19,0-1.47.16-1.47.86a.87.87,0,0,0,.43.79c.18.12.48.16,1.29.16h1.57c2.13,0,3.34,1,3.34,2.76a3.14,3.14,0,0,1-1.49,2.74,5.07,5.07,0,0,1-2.24.3H222V412.5h4.72a6.38,6.38,0,0,0,1.43-.08.69.69,0,0,0,.48-.73.89.89,0,0,0-.46-.83,2.78,2.78,0,0,0-1.15-.12h-1.63a3.57,3.57,0,0,1-2.38-.56,3.16,3.16,0,0,1-.1-4.79,3.41,3.41,0,0,1,2.2-.49h5.68Z"/>
              <path className="cls-3" d="M233.35,401.32H236v2.59h-2.66ZM236,404.9v9.67h-2.66V404.9Z"/>
              <path className="cls-3" d="M245.15,401.32h2.66v13.25h-4.59c-1.87,0-2.76-.28-3.71-1.13a4.89,4.89,0,0,1-1.47-3.78,4.69,4.69,0,0,1,1.67-3.87,4.45,4.45,0,0,1,2.94-.89h2.5Zm0,5.64h-1.93a2.39,2.39,0,0,0-2.48,2.7c0,1.77.95,2.84,2.48,2.84h1.93Z"/>
              <path className="cls-3" d="M258.38,410.76h-5.6c.08,1.25.79,1.78,2.34,1.74h3.26v2.07h-3.54a4.74,4.74,0,0,1-3.25-.91,5.38,5.38,0,0,1,.14-8.07,5,5,0,0,1,2.92-.69h3.73V407h-3.54c-1.46,0-2,.42-2.06,1.73h5.6Z"/>
              <path className="cls-3" d="M179.67,482.28h6a3.78,3.78,0,0,0,1.93-.3,1.34,1.34,0,0,0,.69-1.23,1.51,1.51,0,0,0-.81-1.39,3.06,3.06,0,0,0-1.47-.26H183.6a5,5,0,0,1-3.24-.82,3.85,3.85,0,0,1-1.33-3,3.75,3.75,0,0,1,2.19-3.6,8.07,8.07,0,0,1,2.95-.32h6.56v2.37h-5.92a4.92,4.92,0,0,0-2,.2,1.37,1.37,0,0,0-.65,1.27,1.45,1.45,0,0,0,.59,1.21,3.37,3.37,0,0,0,1.83.3h2.13c1.9,0,2.92.27,3.69,1a4.28,4.28,0,0,1,1.07,3,4,4,0,0,1-1.59,3.29c-.67.46-1.51.6-3.55.6h-6.65Z"/>
              <path className="cls-3" d="M196.14,478.8,199.7,475h3l-3.77,3.83h.74c1.86.06,2.46.95,2.44,3.69v2.15h-2.66v-2.26c0-1.24-.26-1.49-1.47-1.53h-1.81v3.79h-2.66V471.39h2.66Z"/>
              <path className="cls-3" d="M212.15,480.83h-5.6c.08,1.25.79,1.78,2.34,1.74h3.26v2.07h-3.54a4.72,4.72,0,0,1-3.25-.91,5.38,5.38,0,0,1,.14-8.07,4.94,4.94,0,0,1,2.91-.69h3.74V477h-3.54c-1.47,0-2,.42-2.06,1.73h5.6Z"/>
              <path className="cls-3" d="M214.53,484.64V471.39h2.66v13.25Z"/>
              <path className="cls-3" d="M227.75,480.83h-5.6c.08,1.25.8,1.78,2.35,1.74h3.25v2.07h-3.53a4.77,4.77,0,0,1-3.26-.91,5.38,5.38,0,0,1,.14-8.07A5,5,0,0,1,224,475h3.73V477h-3.53c-1.47,0-2,.42-2.07,1.73h5.6Z"/>
              <path className="cls-3" d="M230.86,471.39h2.66V475H236V477h-2.46v3.52c0,1.61.22,2,1.41,2h1v2.07h-1.92c-2.29,0-3.28-1.09-3.2-3.64v-4h-1.69V475h1.69Z"/>
              <path className="cls-3" d="M246.18,476.38a5.28,5.28,0,0,1,1.17,3.53c0,3-2,4.87-5.16,4.87s-5.06-1.85-5.06-5c0-3,2-4.9,5.1-4.9A4.85,4.85,0,0,1,246.18,476.38Zm-6.33,3.45c0,1.91.81,2.9,2.42,2.9s2.36-1,2.36-2.94-.81-2.92-2.38-2.92S239.85,477.85,239.85,479.83Z"/>
              <path className="cls-3" d="M254.51,475c2.62-.12,3.87,1.09,3.87,3.71v6h-2.66v-6.26c0-1-.4-1.37-1.37-1.35h-2.3v7.61h-2.67V475Z"/>
            </g>
            }
            {lang === "sv" &&
            <g data-name="labels SV">
              <path className="cls-3" d="M201.15,339.58c0,2.34.67,3.31,2.92,3.31s2.9-1,2.9-3.31v-7.91h2.72v8c0,1.69-.18,2.85-0.76,3.55-1.06,1.29-2.61,1.82-4.86,1.82s-3.82-.53-4.88-1.82c-0.59-.72-0.74-1.88-0.74-3.55v-8h2.7v7.91Z"/>
              <path className="cls-3" d="M213.37,335.19v-3.51h2.5v3.51h2.65v1.93h-2.65V341c0,1.34.34,1.91,1.62,1.91h1v1.93h-2.29c-1.82,0-2.86-1-2.86-3v-4.73h-1.77v-1.93h1.77Z"/>
              <path className="cls-3" d="M225.67,342.86c0.9,0,1.35-.26,1.35-0.94s-0.45-1-1.35-1h-2.16c-2.31,0-3.28-.48-3.28-2.74,0-2.08,1.06-3,3.35-3H229v2h-4.43c-1.14,0-1.73.07-1.73,0.88,0,0.61.38,0.87,1.19,0.87h2.27c2.09,0,3.19,1,3.19,2.89s-1.1,3-2.59,3h-6.47v-2h5.28Z"/>
              <path className="cls-3" d="M231.81,334.08v-2.41h2.63v2.41h-2.63Zm0,10.8v-9.7h2.63v9.7h-2.63Z"/>
              <path className="cls-3" d="M241.28,344.88c-3,0-4.63-1.88-4.63-5a5.1,5.1,0,0,1,1-3.24c0.9-1.16,2.05-1.49,3.76-1.49h2.14v-3.51h2.5v13.21h-4.81Zm2.31-7.6h-1.51c-1.95,0-2.79.72-2.79,2.81s0.74,2.7,2.59,2.7h1.71v-5.52Z"/>
              <path className="cls-3" d="M254.15,335.19c2.59,0,3.91,1.23,3.91,3.62v6.07h-6.74c-1.89,0-2.94-1-2.94-2.8,0-2.24,1.14-3.09,3.78-3.09h3.3v-0.24c0-1.23-.68-1.56-2.27-1.56H249v-2h5.13Zm1.33,7.75v-2h-3c-1.06,0-1.55.22-1.55,1.09s0.45,0.92,1.37.92h3.13Z"/>
              <path className="cls-3" d="M203.41,415.14V401.93h2.85v13.21h-2.85Z"/>
              <path className="cls-3" d="M213.88,405.44c2.81,0,4,1,4,3.55v6.15h-2.5v-5.83c0-1.34-.23-1.73-1.66-1.73h-2v7.56h-2.51v-9.7h4.72Z"/>
              <path className="cls-3" d="M225.67,413.11c0.9,0,1.35-.26,1.35-0.94s-0.45-1-1.35-1h-2.16c-2.31,0-3.28-.48-3.28-2.74,0-2.08,1.06-3,3.35-3H229v2h-4.43c-1.14,0-1.73.07-1.73,0.88,0,0.61.38,0.87,1.19,0.87h2.27c2.09,0,3.19,1,3.19,2.89s-1.1,3-2.59,3h-6.47v-2h5.28Z"/>
              <path className="cls-3" d="M231.81,404.34v-2.41h2.63v2.41h-2.63Zm0,10.8v-9.7h2.63v9.7h-2.63Z"/>
              <path className="cls-3" d="M241.28,415.14c-3,0-4.63-1.88-4.63-5a5.1,5.1,0,0,1,1-3.24c0.9-1.16,2.05-1.49,3.76-1.49h2.14v-3.51h2.5v13.21h-4.81Zm2.31-7.6h-1.51c-1.95,0-2.79.72-2.79,2.81s0.74,2.7,2.59,2.7h1.71v-5.52Z"/>
              <path className="cls-3" d="M254.15,405.44c2.59,0,3.91,1.23,3.91,3.62v6.07h-6.74c-1.89,0-2.94-1-2.94-2.8,0-2.24,1.14-3.09,3.78-3.09h3.3V409c0-1.23-.68-1.56-2.27-1.56H249v-2h5.13Zm1.33,7.75v-2h-3c-1.06,0-1.55.22-1.55,1.09s0.45,0.92,1.37.92h3.13Z"/>
              <path className="cls-3" d="M197.77,474.54c-1.42,0-2.09.52-2.09,1.73,0,1,.58,1.44,1.71,1.44H201c2.88,0,4.13,1,4.13,3.73s-1.46,4-4.52,4h-7.24V483h6.81c1.44,0,2.11-.35,2.11-1.49s-0.61-1.53-1.93-1.53h-3.21c-3,0-4.31-1.14-4.31-3.79,0-2.87,1.46-4,4.54-4h7.12v2.35h-6.72Z"/>
              <path className="cls-3" d="M210,472.18v8.17l4-4.65h3.17l-4.4,4.75,4.4,4.95h-3.37L210,480.68v4.71h-2.5V472.18H210Z"/>
              <path className="cls-3" d="M217.84,480.56c0-3.16,1.62-4.86,4.77-4.86h3.66v2H223c-1.6,0-2.29.28-2.34,1.82h5.66v2h-5.66c0.05,1.6.7,1.8,2.32,1.8h3.33v2h-3.66C219.46,485.39,217.84,483.74,217.84,480.56Z"/>
              <path className="cls-3" d="M228.73,485.39V472.18h2.61v13.21h-2.61Z"/>
              <path className="cls-3" d="M233.59,480.56c0-3.16,1.62-4.86,4.77-4.86H242v2h-3.31c-1.6,0-2.29.28-2.34,1.82H242v2h-5.66c0.05,1.6.7,1.8,2.32,1.8H242v2h-3.66C235.21,485.39,233.59,483.74,233.59,480.56Z"/>
              <path className="cls-3" d="M245.29,475.7v-3.51h2.5v3.51h2.65v1.93h-2.65v3.92c0,1.34.34,1.91,1.62,1.91h1v1.93h-2.29c-1.82,0-2.86-1-2.86-3v-4.73h-1.77V475.7h1.77Z"/>
              <path className="cls-3" d="M253.63,475.7v-3.51h2.5v3.51h2.65v1.93h-2.65v3.92c0,1.34.34,1.91,1.62,1.91h1v1.93h-2.29c-1.82,0-2.86-1-2.86-3v-4.73h-1.77V475.7h1.77Z"/>
            </g>
            }
          </g>
          <g>
            <g>
              <path className="cls-12" d="M343.42,289.16l-2.5-46a9.61,9.61,0,0,1-9.61-9.61V189.7a9.61,9.61,0,0,1,9.61-9.61H361a9.61,9.61,0,0,1,9.61,9.61v43.84a9.61,9.61,0,0,1-9.61,9.61l-2.55,46H343.42Z"/>
              <polygon className="cls-13" points="358.48 289.16 353.91 301.77 347.88 301.77 343.42 289.16 358.48 289.16"/>
            </g>
            <rect className="cls-14" x="335.22" y="184.01" width="31.52" height="55.1" rx="5.76" ry="5.76"/>
          </g>
          <path className="cls-15" d="M44.86,129.7V303.14h250.4V129.7H44.86Zm246,169H49.32V134.15H290.81V298.68Z"/>
        </g>
        <g className={`lamps ${settings.screenOn}`}>
          <g className="lampsTopOff">
            <g>
              <rect className="button-gray" x="269.51" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M291.07,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H271.91a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4h19.16m0-1.8H271.91a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19h19.16a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="238.89" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M260.45,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H241.29a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4h19.16m0-1.8H241.29a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19h19.16a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="208.27" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M229.83,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H210.67a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4h19.16m0-1.8H210.67a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19h19.16a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="177.66" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M199.22,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H180.05a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4h19.16m0-1.8H180.05a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19h19.16a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="147.04" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M168.6,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H149.43a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4H168.6m0-1.8H149.43a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19H168.6a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="116.42" y="102.97" width="23.96" height="9.58" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M138,103a2.4,2.4,0,0,1,2.4,2.4v4.79a2.4,2.4,0,0,1-2.4,2.4H118.82a2.39,2.39,0,0,1-2.4-2.4v-4.79a2.39,2.39,0,0,1,2.4-2.4H138m0-1.8H118.82a4.2,4.2,0,0,0-4.19,4.19v4.79a4.2,4.2,0,0,0,4.19,4.19H138a4.2,4.2,0,0,0,4.19-4.19v-4.79a4.2,4.2,0,0,0-4.19-4.19h0Z"/>
            </g>
          </g>
          <g className="lampsTopOn blinking">
            <g>
              <rect className="cls-16" x="269.44" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M291.06,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H271.84a2.4,2.4,0,0,1-2.4-2.4v-4.8a2.4,2.4,0,0,1,2.4-2.4h19.22m0-1.8H271.84a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2h19.22a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
            <g>
              <rect className="cls-17" x="238.74" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M260.36,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H241.14a2.4,2.4,0,0,1-2.4-2.4v-4.8a2.4,2.4,0,0,1,2.4-2.4h19.22m0-1.8H241.14a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2h19.22a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
            <g>
              <rect className="cls-18" x="208.04" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M229.66,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H210.44a2.4,2.4,0,0,1-2.4-2.4v-4.8a2.4,2.4,0,0,1,2.4-2.4h19.22m0-1.8H210.44a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2h19.22a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
            <g>
              <rect className="cls-19" x="177.34" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M199,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H179.74a2.4,2.4,0,0,1-2.4-2.4v-4.8a2.4,2.4,0,0,1,2.4-2.4H199m0-1.8H179.74a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2H199a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
            <g>
              <rect className="cls-20" x="146.64" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M168.26,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H149a2.4,2.4,0,0,1-2.4-2.4v-4.8A2.4,2.4,0,0,1,149,103h19.22m0-1.8H149a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2h19.22a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
            <g>
              <rect className="cls-21" x="115.94" y="102.96" width="24.02" height="9.61" rx="2.4" ry="2.4"/>
              <path className="button-gray-outline" d="M137.56,103a2.4,2.4,0,0,1,2.4,2.4v4.8a2.4,2.4,0,0,1-2.4,2.4H118.35a2.4,2.4,0,0,1-2.4-2.4v-4.8a2.4,2.4,0,0,1,2.4-2.4h19.22m0-1.8H118.35a4.21,4.21,0,0,0-4.2,4.2v4.8a4.21,4.21,0,0,0,4.2,4.2h19.22a4.21,4.21,0,0,0,4.2-4.2v-4.8a4.21,4.21,0,0,0-4.2-4.2h0Z"/>
            </g>
          </g>
          <g className="lampsBottomOff">
            <g>
              <rect className="button-gray" x="51.6" y="466.23" width="41.01" height="16.41" rx="4.1" ry="4.1"/>
              <path className="button-gray-outline" d="M88.51,466.23a4.1,4.1,0,0,1,4.1,4.1v8.2a4.1,4.1,0,0,1-4.1,4.1H55.7a4.1,4.1,0,0,1-4.1-4.1v-8.2a4.1,4.1,0,0,1,4.1-4.1H88.51m0-2.41H55.7a6.51,6.51,0,0,0-6.51,6.51v8.2A6.51,6.51,0,0,0,55.7,485H88.51A6.51,6.51,0,0,0,95,478.53v-8.2a6.51,6.51,0,0,0-6.51-6.51h0Z"/>
            </g>
            <g>
              <rect className="button-gray" x="51.6" y="436.27" width="41.01" height="16.41" rx="4.1" ry="4.1"/>
              <path className="button-gray-outline" d="M88.51,436.27a4.1,4.1,0,0,1,4.1,4.1v8.2a4.1,4.1,0,0,1-4.1,4.1H55.7a4.1,4.1,0,0,1-4.1-4.1v-8.2a4.1,4.1,0,0,1,4.1-4.1H88.51m0-2.41H55.7a6.51,6.51,0,0,0-6.51,6.51v8.2a6.51,6.51,0,0,0,6.51,6.51H88.51A6.51,6.51,0,0,0,95,448.57v-8.2a6.51,6.51,0,0,0-6.51-6.51h0Z"/>
            </g>
          </g>
          <g className="lampsBottomOn">
            <g>
              <path className="cls-28" d="M55.73,466.26H88.49a4.09,4.09,0,0,1,4.09,4.09v8.19a4.1,4.1,0,0,1-4.1,4.1H55.73a4.09,4.09,0,0,1-4.09-4.09v-8.19A4.1,4.1,0,0,1,55.73,466.26Z"/>
              <path className="button-gray-outline" d="M88.49,466.26a4.1,4.1,0,0,1,4.09,4.1v8.19a4.1,4.1,0,0,1-4.09,4.1H55.73a4.09,4.09,0,0,1-4.09-4.1v-8.19a4.09,4.09,0,0,1,4.09-4.1H88.49m0-2.4H55.73a6.5,6.5,0,0,0-6.5,6.5v8.19a6.5,6.5,0,0,0,6.5,6.5H88.49a6.5,6.5,0,0,0,6.5-6.5v-8.19a6.5,6.5,0,0,0-6.5-6.5h0Z"/>
            </g>
            <g>
              <path className="cls-29" d="M55.73,436.35H88.49a4.09,4.09,0,0,1,4.09,4.09v8.19a4.1,4.1,0,0,1-4.1,4.1H55.73a4.09,4.09,0,0,1-4.09-4.09v-8.19A4.1,4.1,0,0,1,55.73,436.35Z"/>
              <path className="button-gray-outline" d="M88.49,436.35a4.1,4.1,0,0,1,4.09,4.1v8.19a4.1,4.1,0,0,1-4.09,4.1H55.73a4.09,4.09,0,0,1-4.09-4.1v-8.19a4.09,4.09,0,0,1,4.09-4.1H88.49m0-2.4H55.73a6.5,6.5,0,0,0-6.5,6.5v8.19a6.5,6.5,0,0,0,6.5,6.5H88.49a6.5,6.5,0,0,0,6.5-6.5v-8.19a6.5,6.5,0,0,0-6.5-6.5h0Z"/>
            </g>
          </g>
        </g>
        <a id="buttonOnOff" xlinkHref="#buttonOnOff" className="OnOff" onClick={toggleOnOff} aria-pressed={settings.screenOn}>
          <title>{settings.screenOn ? intl.formatMessage({ id: "switchOff" }) + " " + intl.formatMessage({ id: "meo_xray" }) : intl.formatMessage({ id: "switchOn" }) + " " + intl.formatMessage({ id: "meo_xray" })}</title>
          <g>
            <polygon className="cls-1" points="74.74 328.82 74.74 328.82 74.75 328.82 74.74 328.82"/>
            <path className="cls-32" d="M74.75,329h0a30.12,30.12,0,1,0,30.19,30.12A30.11,30.11,0,0,0,74.74,329Z"/>
          </g>
          <g className="cls-33">
            <rect className="cls-34" x="54.28" y="338.54" width="40.93" height="40.92"/>
          </g>
          <path className="cls-35" d="M74.74,356.53A1.77,1.77,0,0,1,73,354.77V346a1.77,1.77,0,1,1,3.53,0v8.77a1.77,1.77,0,0,1-1.77,1.77"/>
          <path className="cls-35" d="M74.74,371.77a12.77,12.77,0,0,1-11.14-19,12.86,12.86,0,0,1,4.29-4.53,1.77,1.77,0,1,1,1.9,3,9.23,9.23,0,1,0,9.9,0,1.77,1.77,0,1,1,1.9-3A12.74,12.74,0,0,1,87.5,359a12.76,12.76,0,0,1-12.76,12.76"/>
        </a>
      </StyledSvg>
      <Screen
        className={settings.screenOn && "on"}
        animationDisabled={animationDisabled}
        >
        <img
          src={screenImage}
          alt={`${settings.screenOn
            ? intl.formatMessage({ id: "meo_xrayScreen_alt" }) + " " + intl.formatMessage({ id: slideposlabel })
            : intl.formatMessage({ id: "meo_xrayScreenOff_alt" })
          }`}
        />
      </Screen>
    </SvgWrapper>
  </Wrapper>

  )
}
