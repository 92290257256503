import React, { useState, useEffect } from "react"
import styled from "styled-components"

const meopink = "rgb(207, 60, 134)"

const Clock = styled.div`
  width: 100%;
  height: 100%;
  border: 0.3rem solid #666;
  border-radius: 50%;
  background-color: #eee;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.4);
`

const Hand = styled.div`
  position:absolute;
  width: 5%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 40%;
  background-color: #666;
  top: calc(50% - 40%);
  left: 50%;
  transform-origin: bottom;
  transform-box: fill-box;
`

const HourHand = styled(Hand)`
  transform: translateX(-50%) rotate(${props => props.deg}deg);
  height: 30%;
  top: calc(50% - 30%);
  z-index: 11;
`
const MinuteHand = styled(Hand)`
  transform: translateX(-50%) rotate(${props => props.deg}deg);
  z-index: 12;
`
const SecondHand = styled(Hand)`
  transform: translateX(-50%) rotate(${props => props.deg}deg);
  width: 2%;
  background-color: ${meopink};
  z-index: 13;
`

const SeparatorHour = styled.div`
  position: absolute;
  width: 2%;
  height: 99%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(${props => props.rotation}deg);
  background: #666;
  z-index: 1;
`

const SeparatorSet = styled.div`
  &:before {
    position: absolute;
    content: "";
    z-index: 5;
    width: 80%;
    height: 80%;
    background: #eee;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`

export default function AnalogClock() {
  let timerInterval
  const [time, setTime] = React.useState(null)
  const [degrees, setDegrees] = React.useState({ h: 0, m: 0, s: 0 })

  useEffect(() => {
    if (!timerInterval) {
      initTimer()
    }
    return () => {
      clearInterval(timerInterval)
    }
  }, [])

  const initTime = () => {
    let date = new Date()
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()

    setTime({ h, m, s })

    setDegrees({
      h: (360 * h / 12) + ((60 / 12 ) * m / 10),
      m: 360 * m / 60,
      s: 360 * s / 60,
    })
  }

  const initTimer = () => {
    timerInterval = setInterval(() => initTime(), 1000)
  }

  const hourSeperators = (() => {
    const sep = []
    const deg = 360 / 12

    for (let i = 0; i <= 5; i++) {
      sep.push(<SeparatorHour rotation={i * deg} key={i}></SeparatorHour>)
    }

    return sep
  })()


  return(
    <Clock>
      <HourHand deg={degrees.h} />
      <MinuteHand deg={degrees.m} />
      <SecondHand deg={degrees.s} />
      <SeparatorSet>
        {hourSeperators}
      </SeparatorSet>
    </Clock>
  )
}
