import React from "react"

export default function MeoOperationsbord5(props) {
  return(
    <svg id="bed_shoe" viewBox="0 0 566.93 383.49">
      <defs>
        <radialGradient id="a" cx="235.35" cy="301.68" r="20.11" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray"/>
          <stop offset="1" stopColor="#b3b3b3"/>
        </radialGradient>
        <linearGradient id="b" data-name="linear_mirror_whitegray" x1="151.69" y1="313.58" x2="151.69" y2="273.98" gradientTransform="translate(83.71 7.88) rotate(0.01)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="0.25" stopColor="#fff"/>
          <stop offset="0.5" stopColor="#ccc"/>
          <stop offset="0.75" stopColor="#fff"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="c" x1="-1190.98" y1="-1455.62" x2="-1190.98" y2="-1491.29" gradientTransform="matrix(0, -1, 1.02, 0, 1731.43, -890.09)" xlinkHref="#b"/>
        <linearGradient id="d" x1="-853.52" y1="-236.24" x2="-853.52" y2="-274.13" gradientTransform="matrix(0.69, -0.73, 0.73, 0.69, 1007.77, -142.27)" xlinkHref="#b"/>
        <linearGradient id="e" x1="1349.34" y1="-49.5" x2="1349.34" y2="-88.02" gradientTransform="matrix(0.71, 0.7, -0.7, 0.71, -772.84, -597.77)" xlinkHref="#b"/>
        <radialGradient id="f" cx="450.59" cy="300.21" r="18.05" xlinkHref="#a"/>
        <linearGradient id="g" x1="-215.53" y1="176.46" x2="-215.53" y2="140.9" gradientTransform="translate(666.13 141.62) rotate(0.01)" xlinkHref="#b"/>
        <linearGradient id="h" x1="-1045.1" y1="-1815.42" x2="-1045.1" y2="-1847.44" gradientTransform="matrix(0, -1, 1.02, 0, 2309.61, -747.23)" xlinkHref="#b"/>
        <linearGradient id="i" x1="-1027.55" y1="-588.25" x2="-1027.55" y2="-622.26" gradientTransform="matrix(0.37, -0.93, 0.93, 0.37, 1396.41, -426.83)" xlinkHref="#b"/>
        <linearGradient id="j" x1="1002.66" y1="129.99" x2="1002.66" y2="95.41" gradientTransform="matrix(0.92, 0.39, -0.39, 0.92, -427.52, -196.52)" xlinkHref="#b"/>
        <radialGradient id="k" cx="330.85" cy="352.12" r="22.98" xlinkHref="#a"/>
        <linearGradient id="l" x1="244.31" y1="371.97" x2="244.31" y2="326.72" gradientTransform="translate(86.55 2.78)" xlinkHref="#b"/>
        <linearGradient id="m" x1="-1249.14" y1="-1363.49" x2="-1249.14" y2="-1404.25" gradientTransform="matrix(0, -1, 1.02, 0, 1735.7, -897.98)" xlinkHref="#b"/>
        <linearGradient id="n" x1="-824.45" y1="-129.71" x2="-824.45" y2="-173.01" gradientTransform="matrix(0.69, -0.73, 0.73, 0.69, 1007.77, -142.27)" xlinkHref="#b"/>
        <linearGradient id="o" x1="1452.74" y1="-78.11" x2="1452.74" y2="-122.12" gradientTransform="matrix(0.71, 0.7, -0.7, 0.71, -772.84, -597.77)" xlinkHref="#b"/>
        <linearGradient id="p" x1="337.74" y1="325.86" x2="337.74" y2="261.13" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="q" x1="299.42" y1="292.95" x2="299.42" y2="144.76" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="gray"/>
        </linearGradient>
        <linearGradient id="r" x1="331.61" y1="292.95" x2="331.61" y2="150.64" xlinkHref="#p"/>
        <linearGradient id="s" data-name="linear_gray1" x1="198.99" y1="311.79" x2="289.73" y2="311.79" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3"/>
          <stop offset="1" stopColor="#e6e6e6"/>
        </linearGradient>
        <linearGradient id="t" x1="331.82" y1="297.44" x2="393.34" y2="297.44" xlinkHref="#s"/>
        <linearGradient id="u" data-name="linear_gray2" x1="332.54" y1="257.92" x2="332.54" y2="240.04" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#999"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="v" x1="332.62" y1="256.07" x2="332.62" y2="241.9" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c0"/>
          <stop offset="1" stopColor="#e2eed8"/>
        </linearGradient>
        <linearGradient id="w" x1="340.48" y1="-88.57" x2="340.48" y2="-96.63" gradientTransform="translate(-142.11 372.59) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#3c3"/>
          <stop offset="1" stopColor="#006837"/>
        </linearGradient>
        <linearGradient id="x" x1="332.54" y1="279.88" x2="332.54" y2="262.01" xlinkHref="#u"/>
        <linearGradient id="y" x1="332.62" y1="278.04" x2="332.62" y2="263.86" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#fcc"/>
        </linearGradient>
        <linearGradient id="z" x1="340.48" y1="-72.11" x2="340.48" y2="-80.17" gradientTransform="translate(-142.11 372.59) scale(1.39 1.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="1" stopColor="#b10000"/>
        </linearGradient>
        <linearGradient id="aa" data-name="linear_cream" x1="505.22" y1="106.59" x2="503.16" y2="44.53" gradientTransform="translate(-33.99 -10.45) rotate(1.91)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#dbceb7"/>
          <stop offset="1" stopColor="#f4e7d2"/>
        </linearGradient>
        <linearGradient id="ab" data-name="Namnlös övertoning 66" x1="215.58" y1="29.9" x2="261.21" y2="201.12" gradientTransform="translate(20.58 4.89) rotate(-1.08)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#9dcbe2"/>
          <stop offset="0.05" stopColor="#98c6dc"/>
          <stop offset="0.2" stopColor="#8dbbd1"/>
          <stop offset="0.41" stopColor="#87b5ca"/>
          <stop offset="1" stopColor="#85b3c8"/>
        </linearGradient>
        <linearGradient id="ac" data-name="Namnlös övertoning 127" x1="66.04" y1="188.64" x2="147.04" y2="131.93" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#dcdcdc"/>
          <stop offset="0.29" stopColor="#ececec"/>
          <stop offset="0.62" stopColor="#f7f7f7"/>
          <stop offset="1" stopColor="#fafafa"/>
        </linearGradient>
        <linearGradient id="ad" data-name="Namnlös övertoning 106" x1="38" y1="180.7" x2="566.1" y2="180.7" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#dcdcdc"/>
          <stop offset="0.6" stopColor="#e4e4e4"/>
          <stop offset="1" stopColor="#dcdcdc"/>
        </linearGradient>
        <linearGradient id="ae" data-name="Namnlös övertoning 31" x1="241.18" y1="131.8" x2="255.46" y2="146.08" gradientTransform="translate(-33.99 -10.45) rotate(1.91)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <linearGradient id="af" x1="275.58" y1="116.6" x2="289.87" y2="130.89" xlinkHref="#ae"/>
        <linearGradient id="ag" x1="310.24" y1="101.39" x2="324.53" y2="115.68" xlinkHref="#ae"/>
        <linearGradient id="ah" x1="342.79" y1="82.49" x2="357.08" y2="96.78" xlinkHref="#ae"/>
        <linearGradient id="ai" x1="373.94" y1="61.94" x2="388.23" y2="76.22" xlinkHref="#ae"/>
        <linearGradient id="aj" data-name="linear_transparengray" x1="309.29" y1="-52.36" x2="384.66" y2="-54.61" gradientTransform="matrix(1, 0.03, -0.04, 1.11, -36.98, 108.37)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e1e1e1"/>
          <stop offset="0.41" stopColor="#eaeaea"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="ak" x1="213.5" y1="-6.78" x2="287.31" y2="-8.98" xlinkHref="#aj"/>
        <linearGradient id="al" x1="246.29" y1="-19.07" x2="321.37" y2="-21.31" xlinkHref="#aj"/>
        <linearGradient id="am" x1="279.99" y1="-34.22" x2="354.76" y2="-36.43" gradientTransform="matrix(0.99, 0.03, -0.04, 1.11, -34.94, 107.83)" xlinkHref="#aj"/>
        <linearGradient id="an" data-name="linear_transparentwhite" x1="219.84" y1="-22.73" x2="253.26" y2="-12.93" gradientTransform="matrix(1, 0.03, -0.04, 1.11, -36.98, 108.37)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#f2f2f2"/>
          <stop offset="0.24" stopColor="#f6f6f6"/>
          <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="ao" x1="253.86" y1="-35.32" x2="287.4" y2="-25.49" xlinkHref="#an"/>
        <linearGradient id="ap" x1="284.31" y1="-49.99" x2="320.99" y2="-39.23" xlinkHref="#an"/>
        <linearGradient id="aq" x1="311.96" y1="-68.06" x2="351.8" y2="-56.38" xlinkHref="#an"/>
        <linearGradient id="ar" x1="184.07" y1="-1.12" x2="253.13" y2="-3.18" xlinkHref="#an"/>
      </defs>
      <g>
        <g>
          <g>
            <circle cx="232.05" cy="301.97" r="27.17"/>
            <ellipse cx="235.35" cy="301.68" rx="19.25" ry="20.94" fill="url(#a)"/>
          </g>
          <rect x="233.98" y="281.89" width="2.7" height="39.6" transform="translate(-16.12 13.3) rotate(-3.13)" fill="url(#b)"/>
          <rect x="217.23" y="300.38" width="36.23" height="2.63" transform="translate(-24 20.36) rotate(-4.71)" fill="url(#c)"/>
          <polygon points="250.4 312.73 248.81 314.85 220.08 290.45 221.66 288.32 250.4 312.73" fill="url(#d)"/>
          <polygon points="223.53 316.98 221.65 315.13 247.15 286.41 249.02 288.25 223.53 316.98" fill="url(#e)"/>
          <path d="M235.34,283.37c9.16,0,16.62,8.22,16.62,18.31S244.51,320,235.34,320s-16.62-8.22-16.62-18.32,7.46-18.31,16.62-18.31m0-2.63c-10.63,0-19.25,9.38-19.25,20.94s8.62,20.94,19.25,20.94,19.25-9.37,19.25-20.94-8.62-20.94-19.25-20.94h0Z" fill="#e6e6e6"/>
          <circle cx="235.36" cy="301.69" r="4.3"/>
        </g>
        <g>
          <g>
            <circle cx="447.63" cy="300.47" r="24.39"/>
            <ellipse cx="450.59" cy="300.21" rx="17.28" ry="18.8" fill="url(#f)"/>
          </g>
          <rect x="449.36" y="282.47" width="2.43" height="35.55" transform="translate(-84.19 215.43) rotate(-24.67)" fill="url(#g)"/>
          <rect x="434.32" y="299.07" width="32.52" height="2.36" transform="matrix(0.9, -0.44, 0.44, 0.9, -86.27, 229.83)" fill="url(#h)"/>
          <polygon points="466.79 304.5 466.16 306.79 434.13 295.88 434.76 293.58 466.79 304.5" fill="url(#i)"/>
          <polygon points="445.75 316.9 443.58 315.98 455.4 283.59 457.57 284.51 445.75 316.9" fill="url(#j)"/>
          <path d="M450.58,283.78c8.23,0,14.92,7.38,14.92,16.44s-6.69,16.44-14.92,16.44-14.92-7.38-14.92-16.44,6.69-16.44,14.92-16.44m0-2.36c-9.54,0-17.28,8.42-17.28,18.8S441,319,450.58,319s17.28-8.41,17.28-18.8-7.73-18.8-17.28-18.8h0Z" fill="#e6e6e6"/>
          <circle cx="450.59" cy="300.24" r="3.86" transform="translate(-78.75 186.33) rotate(-21.53)"/>
        </g>
        <g>
          <g>
            <path d="M296,352.45a31.06,31.06,0,1,1,31.08,31A31.08,31.08,0,0,1,296,352.45Z"/>
            <ellipse cx="330.85" cy="352.12" rx="21.99" ry="23.93" fill="url(#k)"/>
          </g>
          <rect x="329.29" y="329.5" width="3.09" height="45.25" transform="translate(-18.67 18.53) rotate(-3.12)" fill="url(#l)"/>
          <rect x="310.15" y="350.64" width="41.4" height="3" transform="translate(-27.77 28.32) rotate(-4.7)" fill="url(#m)"/>
          <polygon points="348.05 364.74 346.24 367.17 313.4 339.28 315.22 336.85 348.05 364.74" fill="url(#n)"/>
          <polygon points="317.34 369.6 315.2 367.49 344.34 334.67 346.47 336.78 317.34 369.6" fill="url(#o)"/>
          <path d="M330.85,331.2c10.47,0,19,9.39,19,20.93s-8.52,20.93-19,20.93-19-9.39-19-20.93,8.52-20.93,19-20.93m0-3c-12.15,0-22,10.71-22,23.93s9.85,23.93,22,23.93,22-10.71,22-23.93-9.84-23.93-22-23.93h0Z" fill="#e6e6e6"/>
          <circle cx="330.86" cy="352.13" r="4.92"/>
        </g>
        <polygon points="271.65 274.72 362.32 270.39 439.55 261.13 476.48 272.34 393.34 282.14 331.82 301.61 362.12 315.21 289.73 325.87 199 273.63 259.58 269.29 271.65 274.72" fill="url(#p)"/>
        <polygon points="312.44 292.95 286.41 281.31 286.41 144.76 312.44 153 312.44 292.95" fill="url(#q)"/>
        <polygon points="350.77 287.41 312.44 292.95 312.44 153 350.79 150.64 350.77 287.41" fill="url(#r)"/>
        <polygon points="362.11 336.89 289.72 349.95 289.73 325.87 362.1 315.21 362.11 336.89" fill="#ccc"/>
        <polygon points="289.72 349.95 198.99 293.4 199 273.63 289.73 325.87 289.72 349.95" fill="url(#s)"/>
        <polygon points="476.49 289.06 393.34 301.25 393.34 282.14 476.48 272.34 476.49 289.06" fill="#ccc"/>
        <polygon points="393.34 301.25 356.62 312.74 331.82 301.61 393.34 282.14 393.34 301.25" fill="url(#t)"/>
        <g>
          <g>
            <path d="M341.39,248.41a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,341.39,248.41Z" fill="url(#u)"/>
            <path d="M339.63,248.54a7.61,7.61,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,339.63,248.54Z" fill="url(#v)"/>
            <path d="M337.94,248.65a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,337.94,248.65Z" fill="url(#w)"/>
          </g>
          <path d="M332.74,244.95l3,2.8v1.44l-2.49-2.25v5.83l-1.1.12V247l-2.64,2.64v-1.52Z" fill="#fff"/>
        </g>
        <g>
          <g>
            <path d="M341.39,270.38a9.61,9.61,0,0,1-8.86,9.49,8.24,8.24,0,0,1-8.86-8.35,9.61,9.61,0,0,1,8.86-9.49A8.24,8.24,0,0,1,341.39,270.38Z" fill="url(#x)"/>
            <path d="M339.63,270.5a7.62,7.62,0,0,1-7,7.52,6.53,6.53,0,0,1-7-6.62,7.62,7.62,0,0,1,7-7.52A6.53,6.53,0,0,1,339.63,270.5Z" fill="url(#y)"/>
            <path d="M337.94,270.61a5.78,5.78,0,0,1-5.32,5.71,5,5,0,0,1-5.33-5,5.78,5.78,0,0,1,5.33-5.71A5,5,0,0,1,337.94,270.61Z" fill="url(#z)"/>
          </g>
          <path d="M329.53,272.21v-1.52l2.64,2.31V267.1l1.1-.11v5.85l2.49-2.6v1.45l-3,3.27Z" fill="#fff"/>
        </g>
      </g>
      <g className="bedTop">
        <path d="M557.64,67.48S552,50.78,517,50.78c-26.27,0-38.67,8.91-58.42,12.36-31.7,5.54-41.66,5.17-81.44-1.24,0,0,52.38,51.94,105.25,50.95s63-16.62,74.82-25.82Z" fill="url(#aa)"/>
        {props.children}
        <g>
          <path d="M110.44,108c98.3-6.33,219.44-79.87,211.11-86.94-15.21-12.9-24.94-1.94-24.94-1.94-54.6,61.12-198.84,81-198.84,81Z" fill="#9dcbe2"/>
          <path d="M179.67,190.57c-34.45-47.25-76.83-91.9-76.83-91.9a122,122,0,0,0,21.62,1c16.63-1,48.37-2.17,94.44-20.29,29.6-11.64,55.65-27,70.83-38.57S318.5,5.3,341.28.9c32.15-6.21,59.64,20.18,47,62.19l1.56,108.08Z" fill="url(#ab)"/>
          <path d="M324.73,21c3.82-9.94,15.22-16,26.37-13,12.42,3.34,19.18,16.21,13.91,28-5.11,11.46-18.88,15.81-29.79,10.56A20.41,20.41,0,0,1,324.73,21Zm16.55,0.8L330.3,18l6.17,9.63L329,36.56l11.48-2.67,6.89,10.84,0.8-12.61L361.87,29l-13.22-4.57,0.85-12.48Z" fill="#e6e6e6"/>
          <path d="M174.64,190.46c-9.31-45.23-50.42-92.78-71.8-91.79-28.93,1.33-52.77,23.15-56.58,29.4-2.36,8.73,2.26,35.56,2.26,35.56l52.32,25.49Z" fill="url(#ac)"/>
          <path d="M174.61,196.56S159,135.08,198.28,118.29c45.86-19.62,73.33-20.76,134-65.65,0,0,27.71-19.48,57.64-2.46,12.33,7,40.59,45.93,79,53.25,0,0,35.19,5.4,67-10.18,0,0,19.13-11.24,21.68-25.77,0,0,19.55,35.62,1.93,90.16Z" fill="#9dcbe2"/>
          <path d="M565.94,129c-5,14.67-29.28,20.08-59.45,20C440,148.67,405,150,359,159.52c-93.81,19.5-133.63,23.6-183.23,23.6-143.33,0-129.51-55-129.51-55s-13.5,18-6,53.74c0,0,10.58,51.51,130.46,51.51,0,0,87.06,1.7,189.87-24.58,58.06-14.84,112.67-11.29,137.07-11.29,42.84,0,57.62-11.64,62.71-21.12C567.55,163,565.94,129,565.94,129Z" fill="url(#ad)"/>
          <path d="M172.2,211.6c-52.15,0-88.75-8.07-112.46-24.86C42,174.21,38.12,158.75,38,158.18c0,0,0-1.81.2-3.67,0.19-2.27.44-4.14,0.44-4.14s5.38,19.12,23,31c16,10.83,41.17,24.85,113.89,24.2C238.21,205,289,197,355.45,182.11,400.7,172,433.18,170.29,502,170.56h0.76c30.72,0,55-7.94,62.95-20.53,0,0-.18,2.65-0.3,4s-0.35,3.52-.35,3.52c-8.09,10.86-29.55,18.87-62.58,18.77-68.37-.27-101.1,1.12-145.84,11.12-66.83,14.94-117.91,23.67-181.06,24.11H172.2Z" fill="#f2f2f2"/>
          <path d="M176.48,186.17c-51,0-103.23-5.36-125.91-33.61-7.23-9-7.41-18.59-7.31-19,0,0,.37-0.89,1.29-2.66a20.56,20.56,0,0,1,1.71-2.81c-0.07.31,0.75,12.73,8.82,21.33,11.68,12.46,39.26,30.87,120.69,30.87,47,0,86.4-3.42,182.67-23.44,45.61-9.48,81-10.91,148.06-10.65,32.64,0.16,51.06-6.19,59.44-17.21,0,0,.05,1.37.09,2.56s0,2.75,0,2.75C563,139.67,551.79,152,507.46,152h-1c-69.77-.26-102.44,1-146.92,10.23C262.82,182.33,223.89,186.17,176.48,186.17Z" fill="#1a1a1a"/>
          <g>
            <g>
              <circle cx="209.57" cy="136.66" r="8.66" transform="translate(66.01 341.57) rotate(-88.09)" fill="#7ca7ba"/>
              <path d="M209.57,146.77h-0.34A10.1,10.1,0,1,1,209.57,146.77Zm0-17.32a7.21,7.21,0,0,0-.24,14.42,7.21,7.21,0,0,0,7.45-7,7.22,7.22,0,0,0-7-7.45h-0.24Z" fill="url(#ae)"/>
            </g>
            <g>
              <circle cx="244.46" cy="122.62" r="8.66" transform="translate(113.78 362.87) rotate(-88.09)" fill="#7ca7ba"/>
              <path d="M244.47,132.73h-0.35a10.11,10.11,0,0,1-9.77-10.44h0A10.11,10.11,0,1,1,244.47,132.73Zm0-17.32a7.21,7.21,0,0,0-7.2,7h0a7.21,7.21,0,1,0,7.45-7h-0.25Z" fill="url(#af)"/>
            </g>
            <g>
              <circle cx="279.61" cy="108.57" r="8.66" transform="matrix(0.03, -1, 1, 0.03, 161.8, 384.42)" fill="#7ca7ba"/>
              <path d="M279.61,118.68h-0.34a10.12,10.12,0,0,1-9.77-10.44h0A10.11,10.11,0,1,1,279.61,118.68Zm-7.21-10.35a7.21,7.21,0,1,0,2.28-5,7.22,7.22,0,0,0-2.28,5h0Z" fill="url(#ag)"/>
            </g>
            <g>
              <circle cx="312.77" cy="90.77" r="8.66" transform="translate(211.65 400.34) rotate(-88.09)" fill="#7ca7ba"/>
              <path d="M312.78,100.87h-0.35A10.09,10.09,0,1,1,312.78,100.87Zm0-17.32a7.21,7.21,0,0,0-7.2,7h0a7.21,7.21,0,1,0,7.45-7h-0.25Z" fill="url(#ah)"/>
            </g>
            <g>
              <circle cx="344.58" cy="71.25" r="8.66" transform="matrix(0.03, -1, 1, 0.03, 261.91, 413.27)" fill="#7ca7ba"/>
              <path d="M344.59,81.36h-0.34A10.1,10.1,0,1,1,344.59,81.36Zm0-17.32a7.21,7.21,0,0,0-.23,14.42A7.21,7.21,0,1,0,344.82,64h-0.25Z" fill="url(#ai)"/>
            </g>
            <g>
              <path d="M346.38,72.09a13.88,13.88,0,0,1-5.91,5.09s-27.29-20-67.26-25.08c0,0,6.55-4.1,12.12-8.06,0,0,24.18-4.23,63,21A14.47,14.47,0,0,1,346.38,72.09Z" fill="url(#aj)"/>
              <path d="M246.17,124c-2.69,2.6-7.12,3.36-7.12,3.36S216,105.15,176.12,92.83c0,0,8.23-1.9,16.54-4.38,0,0,29.9,3.82,56.93,29.11A13.17,13.17,0,0,1,246.17,124Z" fill="url(#ak)"/>
              <path d="M281.25,109.64c-2.16,2.83-6.48,4.16-6.48,4.16S259.8,95.68,209.23,83c0,0,7.37-2.63,15.8-6.12,0,0,34.78,3.65,59,26A16.54,16.54,0,0,1,281.25,109.64Z" fill="url(#al)"/>
              <path d="M314.37,91.91c-2,2.69-6.21,4.41-6.21,4.41s-28-23-65-27.6c0,0,6.69-3.28,13.17-6.78,0,0,22.63-4.38,60.7,23A14.83,14.83,0,0,1,314.37,91.91Z" fill="url(#am)"/>
              <path d="M206.22,115c-3.19,1.29-6.92,2.85-6.92,2.85s-7.46-26.2-13.17-38.17c0,0,4-7.12,13.81-4.55,0,0,5.52,7.87,12.95,37.24C212.89,112.37,209.07,113.86,206.22,115Z" fill="url(#an)"/>
              <path d="M240.27,102.41c-2.94,1.07-6.89,2.46-6.89,2.46S228.44,80.55,220.3,67.5c0,0,3-7.57,13-5.58,0,0,8.47,8.35,14,37.89C247.3,99.82,243.67,101.18,240.27,102.41Z" fill="url(#ao)"/>
              <path d="M275.07,88.1c-2.82,1.35-7,3.23-7,3.23s-7.81-24.75-17.25-38c0,0,2.18-8.26,12.58-7.24,0,0,8.49,7.74,18.06,38.84C281.52,84.88,277.84,86.77,275.07,88.1Z" fill="url(#ap)"/>
              <path d="M306.95,70.19c-2.77,1.77-6.62,4.12-6.62,4.12s-8.13-23.46-21.54-38.81c0,0,.86-8.6,11.48-9.81,0,0,13.19,13.1,22.77,40.52C313,66.21,309.54,68.53,306.95,70.19Z" fill="url(#aq)"/>
              <path d="M210.92,138.24a19.42,19.42,0,0,1-7.33,2.46s-25.92-36.83-57-50.17c0,0,5.42-6.67,16.67-4.21,0,0,28.66,9.81,51.95,45.92A11.71,11.71,0,0,1,210.92,138.24Z" fill="url(#ar)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

)}
