import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"
import trackEvent from "../../utils/track-event"

import AnaMachine from "./MeoAnaMachineSvg"
import AnaTable from "./MeoAnaTableSvg"
import SurgeonTable from "./MeoSurgeonTableSvg"

import MeoStyledAudioPlayer from "./MeoStyledAudioPlayer"

const meopink = "rgb(207, 60, 134)"

const Wrapper = styled.div`
  flex-flow: column nowrap;

  h2, h3 {
    font-family: ${props => props.theme.headingFont};
    text-align: center;
    color: white;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
    flex-basis: 3rem;
    padding: 0 1em .4em 1em;
    line-height: 100%;
    font-size: 1.8em;
    margin-bottom: 1rem;

    @media (max-width: 550px) {
      font-size: 1.4em;
    }
  }

  h2 + svg {
    max-height: 85vh;
  }

  svg {
    display: block;
    max-width: 95vw;
    max-height: 90vh;
    margin: auto;

    overflow: visible;
    transition: 0.2s margin-bottom ease-in-out,
                0.3s max-height ease-in-out;
    }
    svg.clicked {
      margin-bottom: 30vh;
      max-height: 60vh;
    }

    svg > g[id] {
      transition: 0.3s all ease-in-out, 1s stroke-dashoffset ease-in-out, 1s stroke-width ease-out;
      filter:none;
      stroke:none;
      stroke-width:2px;
      stroke-dasharray: 100%;
      stroke-dashoffset: -100%;
    }

    svg > g[id]:not(#bg) {
      cursor:pointer;
    }

    svg > g[id].active,
    svg > g[id]:hover:not(#table) {
      filter: url(#highlight);
      /* transform: scale(1) translateY(-20px); */
      /* transform: scale(1.5) translate(0, 15%) */
      transform-origin:center bottom;
    }

    svg > g[id].tonedown {
      opacity: 0.5;
      fill: black;
    }

    svg > g[id].active {
      stroke-dashoffset: 100%;
      /* stroke:rgba(125,125,125,0.6); */
      stroke: white;
      stroke-width:0px;
    }

    #itemname {
      display:block;
      opacity: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.8);
      padding: 1em;
      color: white;
      transform: translateY(100%);
      transition: 0.3s opacity ease-in-out, 0.3s transform ease-in-out;
      text-align: left;
      z-index: 99;

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      div {
          max-width: 600px;
          margin: auto;
      }

      h2 {
        margin-bottom: 0.4em;
        text-align: center;
      }

    }

    svg.clicked ~ #itemname {
      display:block;
      opacity:1;
      transform: translateY(0%);
    }
`

const ButtonRound = styled.button`
  display: block;
  text-align: center;
  margin: 1rem auto;
  background: none;
  color: ${meopink};
  border: 1px solid ${meopink};
  border-radius: 1rem;
  padding: 0.4rem 1rem;
  opacity: 1;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.3s ease-out,
    color .3s ease-in-out,
    background-color .3s ease-in-out;

  :hover, :focus {
    color: white;
    background-color: ${meopink};
  }
`

const MeoAudioPlayer = styled(MeoStyledAudioPlayer) `
  border-radius: 10px;
`

export default function MeoItemContainer({ lang, itemset, data, ...props }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()

  const activeItemRef = useRef(null)
  const [activeItem, setActiveItem] = useState(false)
  const [explanation, setExplanation] = useState(null)
  const [audio, setAudio] = useState(null)

  useEffect(() => {
    if (activeItem) {
      const audioPlayer = document.querySelector(".audio-player")

      if (audioPlayer) {
        audioPlayer.focus()
      } else if (activeItemRef.current) {
        activeItemRef.current.focus()
      }
    }
  }, [activeItem])

  const comps = {
    anamachine: AnaMachine,
    anatable: AnaTable,
    surgeontable: SurgeonTable,
  }

  const Comp = comps[itemset]

  const audioDependencies = {
    anamachine: "nurse",
    anatable: "anadoc",
    surgeontable: "surgeon",
  }

  const isThisActive = (itm) => {
    if(activeItem) {
      return activeItem == itm ? "active" : "tonedown"
    } else {
      return null
    }
  }

  const getItemData = (itm) => data.find(({ _id }) => _id === `item_${itm}`) || null

  const handleClick = (e) => {
    if (e === null) {
      setActiveItem(false)
      return
    }

    e.preventDefault()

    const trgt = e.target.closest("g[id]")

    setActiveItem(trgt.id)
    const dat = getItemData(trgt.id)

    if (dat) {
      const block = dat.dialogue[0]

      const head = dat.headline || ""
      const body = block.textblock.html
      const txt = "<h2>" + head + "</h2>" + body
      setExplanation(<div dangerouslySetInnerHTML={{ __html: txt }} />)
      trackEvent({ category: "MEO Item", action: "select", name: head })

      if (block) {
        const dep = audioDependencies[itemset]
        const sex = store.meo.persons[dep].sex
        const sfile = `sound_${sex}`
        const audiofile = block[sfile] ?  block[sfile] : block.sound_f
        setAudio(audiofile)
      }

    } else {
      setExplanation(false)
      setAudio(false)
    }
  }

  const handleGoBack = () => {
    document.querySelector(`a[*|href="#${activeItem}"]`).focus()
    setActiveItem(false)
  }

  return(
    <Wrapper>
      {props.children}

      <Comp
        lang={lang}
        data={data}
        activeItem={activeItem}
        handleClick={handleClick}
        isThisActive={isThisActive}
      />

      {activeItem &&
        <div id="itemname" ref={activeItemRef} tabIndex="-1">
          {audio && <MeoAudioPlayer src={audio} progressBar={false} keyid={activeItem} />}
          {explanation}
          <ButtonRound onClick={handleGoBack}>
            &larr; {intl.formatMessage({ id: "goBack" })}
          </ButtonRound>
        </div>
      }
    </Wrapper>
  )
}
