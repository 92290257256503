import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"
import trackEvent from "../../utils/track-event"

const meopink = "rgb(207, 60, 134)"
const meopinkdarker = "rgb(197, 56, 122)"

const Wrapper = styled.div`

  .header {
    font-family: ${props => props.theme.headingFont};
    text-align: center;
    color: white;
    font-size: 1.8em;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    overflow: scroll;
    width: 100%;
    align-items: center;
    flex-flow: row nowrap;

    &:before,
    &:after {
      content:"";
      width: 2rem;
      height: 1px;
      flex-shrink: 0;
    }
  }

  li {
    min-width: 250px;
    background: white;
    margin: 1%;
    cursor: pointer;
    flex-basis: 23%;
    border-radius: 3px;
    position: relative;

    div.selected {
      position: relative;
      text-align: center;
      width: calc(100% + .8em);
      max-height: 0;
      left: -.4em;
      z-index: 2;
      top: -50px;
      opacity: 0;
      transition: all 0.5s ease-in;
      overflow: hidden;

      :before, :after {
        content: "";
        position: absolute;
        display: block;
        bottom: -.25em;
        border: 1em solid ${meopinkdarker};
        z-index: 1;
      }

      :before {
        right: calc(100% - .25em);
        border-right-width: 1em;
        border-left-color: transparent;
      }

      :after {
        left: calc(100% - .25em);
        border-left-width: 1em;
        border-right-color: transparent;
      }
      .selected-label {
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.2em;
        position: relative;
        z-index: 2;
        color: white;
        background: ${meopink};

        :before, :after {
          content: "";
          position: absolute;
          display: block;
          border-style: solid;
          border-color: #804f7c transparent transparent transparent;
          top: 100%;
        }

        :before {
          left: 0;
          border-width: .25em 0 0 .25em;
        }

        :after {
          right: 0;
          border-width: .25em .25em 0 0;
        }
      }
    }

    button.wrapper {
      padding: 1rem;
      background: none;
      display: block;
      border: none;
      width: 100%;
    }

    span.default {
      background: #ccc;
      height: 200px;
    }

    h3 {text-align: center; margin: 0; margin-bottom: 0.4rem}


    &.active {
      box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
      border: 2px solid ${meopink};

      div.selected {
        top: 0.4rem;
        opacity: 1;
        max-height: 3rem;
        overflow: visible;
      }
    }
  }

  @media (max-width: 600px) {
    li {
      min-width: 200px;
    }

  }
`

const ItemWrapper = styled.div`
  position: relative;
  padding: 1.4rem 1rem 0.8rem;
  background: none;
  border: none;
  overflow-y: auto;
  outline: 0;

  span.default,
  img {
    display: inline-block;
    max-width: 100%;
    height: 30vh;
    object-fit: scale-down;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  img {
    height: 25vh;
    max-width: 50%;
    /* object-fit: scale-down; */
    display: block;
    margin: 1.2em auto;
  }

  h3 {
    text-align: center;
    margin: 0;
    margin-bottom: 0.4rem;
  }

  .bio {
    display: block;
    overflow: hidden;
    perspective: 500px;
    font-size: 1rem;
    max-height: 0;
    opacity: 0;
    transform: translateY(100%);
    transform-origin: top;
    transition: all .5s ease-in-out;

    p {margin: 0 auto 0.6rem;}

  }

  li.active & {
    .bio {
      opacity: 1;
      transform: translateY(0);
      max-height: 100vh;
      overflow: initial;
      margin-bottom: 2rem;
    }
  }
`

const Button = styled.button`
  display: flex;
  position: sticky;
  margin: 0.4rem auto 0;
  bottom: 0;
  transform: translateY(0);
  max-height: 5rem;

  font-size: 1rem;
  background: none;
  border-radius: 3rem;
  padding: 0.4rem 1rem;
  border: 1px solid;
  opacity: 1;
  z-index: 2;

  :hover, :focus {
    color: white;
    background-color: ${meopink};
  }
`

const ButtonSelect = styled(Button)`
  border-color: ${meopink};

  li.active & {
    display: none;
  }
`

const ButtonClose = styled(Button)`
  background: white;
  border-color: #333;
  display: none;

  :hover {
    background-color: #333;
  }

  li.active & {
    display: flex;
  }
`

export default function MeoSelectFurnishing({list, closeFn, ...props}) {
  const [store, updateStore] = useStore()
  const intl = useIntl()

  const activateItem = (itm, idx, e) => {
    if (e) {
      e.stopPropagation()
    }
    updateStore( store => {
      store.meo.furniture[list] = itm
    })
    trackEvent({ category: "MEO Config", action: "Select Furnishing", name: `${list} ${idx}` })
  }

  const imgs = ((l) => {
    switch(l) {
      case "lamp":
        return ([
          [require(`../../images/meo/furniture/MEO_Lampa_1.svg`), "Lampa_1.svg"],
          [require(`../../images/meo/furniture/MEO_Lampa_2.svg`), "Lampa_2.svg"],
          [require(`../../images/meo/furniture/MEO_Lampa_3.svg`), "Lampa_3.svg"],
          [require(`../../images/meo/furniture/MEO_Lampa_4.svg`), "Lampa_4.svg"],
          [require(`../../images/meo/furniture/MEO_Lampa_5.svg`), "Lampa_5.svg"],
          [require(`../../images/meo/furniture/MEO_Lampa_6.png`), "Lampa_6.png"],
        ])
        break
      case "optable":
        return ([
          [require(`../../images/meo/furniture/MEO_Operationsbord_1.svg`), 1],
          [require(`../../images/meo/furniture/MEO_Operationsbord_2.svg`), 2],
          [require(`../../images/meo/furniture/MEO_Operationsbord_3.svg`), 3],
          [require(`../../images/meo/furniture/MEO_Operationsbord_4.svg`), 4],
          [require(`../../images/meo/furniture/MEO_Operationsbord_5.svg`), 5],
        ])
        break
      case "chair":
        return ([
          [require(`../../images/meo/furniture/MEO_Stol_1.svg`), "Stol_1.svg"],
          [require(`../../images/meo/furniture/MEO_Stol_2.svg`), "Stol_2.svg"],
          [require(`../../images/meo/furniture/MEO_Stol_3.svg`), "Stol_3.svg"],
          [require(`../../images/meo/furniture/MEO_Stol_4.svg`), "Stol_4.svg"],
          [require(`../../images/meo/furniture/MEO_Stol_5.svg`), "Stol_5.svg"],
        ])
        break;
      case "wall":
        return ([
          [null, null],
          [require(`../../images/meo/walls/MEO_Tapet_1.svg`), "Tapet_1.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_2.svg`), "Tapet_2.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_3.svg`), "Tapet_3.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_4.svg`), "Tapet_4.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_5.svg`), "Tapet_5.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_6.svg`), "Tapet_6.svg"],
          [require(`../../images/meo/walls/MEO_Tapet_7.png`), "Tapet_7.jpg"],
          [require(`../../images/meo/walls/MEO_Tapet_8.png`), "Tapet_8.jpg"],
        ])
        break;
      case "floor":
        return ([
          [null, null],
          [require(`../../images/meo/walls/MEO_Golv_1.svg`), "Golv_1.svg"],
          [require(`../../images/meo/walls/MEO_Golv_2.svg`), "Golv_2.svg"],
          [require(`../../images/meo/walls/MEO_Golv_3.svg`), "Golv_3.svg"],
          [require(`../../images/meo/walls/MEO_Golv_4.svg`), "Golv_4.svg"],
          [require(`../../images/meo/walls/MEO_Golv_5.svg`), "Golv_5.svg"],
          [require(`../../images/meo/walls/MEO_Golv_6.png`), "Golv_6.jpg"],
          [require(`../../images/meo/walls/MEO_Golv_7.png`), "Golv_7.jpg"],
        ])
        break;
      default:
        break;
    }
  })(list)

  const getAlt = (item) => {
    switch (item) {
      case "lamp" :
        return intl.formatMessage({ id: "meo_lamp" })
        break
      case "optable" :
        return intl.formatMessage({ id: "meo_operatingTable" })
        break
      case "chair" :
        return intl.formatMessage({ id: "meo_chair" })
        break
      case "wall":
        return intl.formatMessage({ id: "meo_wallpaper" })
        break
      case "floor" :
        return intl.formatMessage({ id: "meo_floor" })
        break
    }
  }

  const createItems = () => {
    let lis = new Array()
    imgs.forEach((i,idx) => {
      // let ext = (i.substr(i.lastIndexOf(".") + 1))
      let img = i[0]
      let a = <li
        className={store.meo.furniture[list] == i[1] ? "active" : null}
        onClick={() => activateItem(i[1], idx)}
        key={idx}>
          <div className="selected"><div className="selected-label">{intl.formatMessage({ id: "meo_selected" })}</div></div>
          {/*<button className="wrapper" dangerouslySetInnerHTML={buttonTemplate(i[0])} />*/}
          <ItemWrapper className="wrapper">
            {img ?
                <img
                  src={img}
                  alt={
                      intl.formatMessage({ id: "select" }) + " " +
                      getAlt(list) + " " +
                      idx
                      }/>
              : <span className="default" />
            }
            <ButtonSelect
              onClick={(e) => activateItem(i[1], idx, e)}>
              {intl.formatMessage({ id: "select" })}
            </ButtonSelect>
            <ButtonClose
              onClick={closeFn}>{intl.formatMessage({ id: "finish" })}
            </ButtonClose>
          </ItemWrapper>
        </li>
      lis.push(a)
    })
    return lis
  }

  return(
      <Wrapper>
        <div className="header">{props.children}</div>
        <ul>
          {createItems()}
        </ul>
      </Wrapper>
  )
}
