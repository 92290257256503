import React, {useState, useRef} from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../../hooks/store"

import MeoOperationsbord1 from "./operationsbord/MeoOperationsbord1"
import MeoOperationsbord2 from "./operationsbord/MeoOperationsbord2"
import MeoOperationsbord3 from "./operationsbord/MeoOperationsbord3"
import MeoOperationsbord4 from "./operationsbord/MeoOperationsbord4"
import MeoOperationsbord5 from "./operationsbord/MeoOperationsbord5"

import MeoBarnLiggande0 from "../../images/meo/person/MEO_Barn_Liggande_0.svg"
import MeoBarnLiggande1 from "../../images/meo/person/MEO_Barn_Liggande_1.svg"
import MeoBarnLiggande2 from "../../images/meo/person/MEO_Barn_Liggande_2.svg"
import MeoBarnLiggande3 from "../../images/meo/person/MEO_Barn_Liggande_3.svg"
import MeoBarnLiggande4 from "../../images/meo/person/MEO_Barn_Liggande_4.svg"
import MeoBarnLiggande5 from "../../images/meo/person/MEO_Barn_Liggande_5.svg"

const Wrapper = styled.div`
  position: absolute;
  left: 55%;
  transform: translateZ(16vw) translateX(-50%);
  overflow: visible;
  bottom: 0;
  width: 40%;
  min-width: 90vh;
  max-width: 90vh;
  height: auto;
  max-height: 60vh;
  z-index: 8;
  overflow: visible;

  svg {
    /* max-height: 60vh; */
    position: relative;
    overflow: visible;
    width: 100%;
  }

  svg g.bedTop {
    transition: transform 0.2s ease-in;
    transform: translateY(${props => props.move}%)
  }

  svg#bed_shoe image {
    transform: translate(0%,6%)
  }
`

const Btns = styled.div`
  position: absolute;
  left: 52%;
  bottom: 33%;
  z-index: 1;

  &#optable_buttons_5 {
    left: 56%;
    bottom: 26%;
  }


  button {
    display: block;
    background: transparent;
    border: none;
    width: 40px;
    height: 30px;
    margin-top: 0.2rem;
  }
`

export default function MeoOperationsbord({ personClickFn, activeItem }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const [bedlevel, setBedlevel] = useState(0)

  const optable = useRef(null)
  // const Tag = `Comp${this.state.activeComponent}`;
  // const TableTable = `MeoOperationsbord${store.meo.furniture.optable}`
  const Tables = {
      1: MeoOperationsbord1,
      2: MeoOperationsbord2,
      3: MeoOperationsbord3,
      4: MeoOperationsbord4,
      5: MeoOperationsbord5,
  }
  // const Child = `MeoBarnLiggande${store.meo.persons.child}`

  const Children = {
    0: MeoBarnLiggande0,
    1: MeoBarnLiggande1,
    2: MeoBarnLiggande2,
    3: MeoBarnLiggande3,
    4: MeoBarnLiggande4,
    5: MeoBarnLiggande5,
  }

  const getCombination = () => {
    const Operationsbord = Tables[store.meo.furniture.optable]
    const Child = Children[store.meo.persons.child.id]
    const e = {target : optable.current}
    const handleKeyDown = (evt) => {
      if (evt.keyCode === 32 || evt.keyCode === 13) {
        evt.preventDefault()
        personClickFn("child", e)
      }
    }
    const handleClick = (evt) => {
      evt.preventDefault()
      personClickFn("child", e)
    }
    return (
      <Operationsbord>
        <title>{intl.formatMessage({ id: "meo_operatingTable" }).replace(/(&.+;)/, "")}</title>
        <a xlinkHref="#child-on-table" onClick={handleClick} onKeyDown={handleKeyDown} aria-expanded={activeItem === "child"}>
          <image id="child-on-table" alt={intl.formatMessage({ id: "select" }) + " " + intl.formatMessage({ id: "meo_child" })} href={Child} xlinkHref={Child} x="370" y="0" width="150" height="90" />
        </a>
      </Operationsbord>
    )
  }

  const adjustBedHeight = (amnt) => {
    let newBedlevel = bedlevel + amnt
    if(newBedlevel <= 6 && newBedlevel >= -16) {
      setBedlevel(bedlevel + amnt)
    }
  }

  return(
    <Wrapper ref={optable} id={"optable_" + store.meo.furniture.optable} move={bedlevel}>
      <Btns id={"optable_buttons_" + store.meo.furniture.optable}>
        <button onClick={ () => adjustBedHeight(-1)} aria-label={intl.formatMessage({ id: "meo_operatingTableMoveUp_alt" })} />
        <button onClick={ () => adjustBedHeight(1)} aria-label={intl.formatMessage({ id: "meo_operatingTableMoveDown_alt" })} />
      </Btns>
      {getCombination()}
    </Wrapper>
  )
}
